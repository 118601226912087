import React, {useContext, useEffect, useState} from 'react';
import indexContext from "../../../../contexts/indexContext";
import {Menu} from "primereact/menu";
import TrackingIcon from "../../../vehicleList/trackingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIconForPlace} from "../../places/catOptions";

const ClusterList = ({ clickedLngLat, map, clusterLeaves, winWidth, handleVehicle, clickMap}) => {
    const [items, setItems] = useState([]);


    const Label = ({l}) => {
        return (
            <div>
                <div>
                    <TrackingIcon d={l} drivingStatus={l?.properties?.drivingStatus} latestVehicle={l}/>
                    &nbsp;

                    <span style={{fontSize: '13px!important', fontWeight:900}}>{l?.properties?.registration}</span>
                </div>
            </div>
        )
    }

    const ParentLabel = ({p}) => {

        return (
            <div>
                <FontAwesomeIcon icon={getIconForPlace(p.properties?.poi?.cat?.toLowerCase()?.split(' ')?.[0])}/>
                &nbsp;{p.properties?.poi?.name}
            </div>
        )
    }


    useEffect(() => {
        if (clusterLeaves){
            const itemsPre = [];

            clusterLeaves?.forEach(l => {
                if ((l?.properties?.poi && l?.properties?.drivingStatus !== 'driving')){

                    const parent = itemsPre.filter(i => i.key === l?.properties?.poi?.name)

                    if (parent?.length < 1)
                        itemsPre.push({
                            label: <ParentLabel p={l} />,
                            key: l?.properties?.poi?.name,
                            items: [{  label: <Label l={l}/> ,
                                command: () => {
                                    handleVehicle(l);
                                    clickMap();
                                }}]
                        });

                    else {
                        parent?.[0]?.items?.push({  label: <Label l={l}/> ,
                            command: () => {
                                handleVehicle(l);
                                clickMap();
                            }})
                    }



                } else {
                    itemsPre.push({  label: <Label l={l}/> ,
                        command: () => {
                            handleVehicle(l);
                            clickMap();
                        }})
                }

            })
            setItems(itemsPre)
        }

    }, [clusterLeaves]);

    return (
        <Menu model={items} breakpoint="767px" style={{padding:'0px!important'}}/>

    );
};
export default ClusterList