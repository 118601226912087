
const month = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

export const formatDate = (time) => {
    const date = new Date(time);

    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
        + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' +
        ("0" + date.getSeconds()).slice(-2);
}

export const formatDateForVehicles = (time) => {
    const date = new Date(time);
    date.setMinutes(date.getMinutes() - 1)
    var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = ("0" + Math.floor(offset / 60)).slice(-2);
    var minutes = ("0" + (offset % 60)).slice(-2);


    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
        + 'T' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' +
        ("0" + date.getSeconds()).slice(-2) + "." + ("0" + date.getMilliseconds()).slice(-2) + sign + hours + ":" + minutes;
}

export const formatDateNotificationsTable = (time) => {
    const date = new Date(time);

    return month[date.getMonth()] + ' ' + date.getDate() + ', ' + ("0" + date.getHours()).slice(-2) + ':' +
        ("0" + date.getMinutes()).slice(-2);
}


export const formatDateMediaGrid = (time) => {
    const date = new Date(time);

    return month[date.getMonth()] + ' ' + date.getDate() + ' ' +
        date.getFullYear() + ', ' +  ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':'
        + ("0" + date.getSeconds()).slice(-2);
}

export const formatDateOnly = (time) => {
    const date = new Date(time);
    return ("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
}

export const formatTimeOnly = (time) => {
    const date = new Date(time);
    return ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2);
}


export const formatDateModalHeader = (time) => {
    const date = new Date(time);

    return month[date.getMonth()] + ' ' + date.getDate() + ', ' + ("0" + date.getHours()).slice(-2) + ':' +
        ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2)
}


export const formatDateTimesheetReport = (time) => {
    const date = new Date(time);

    return days[date.getDay()] + ' ' +  month[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

export const getTimeDifference = (time, time2) => {
    const diffMs = new Date(time2) - new Date(time);
    let str = "";
    str += Math.floor(diffMs / (1000*60*60*24*7)) > 0 ? Math.floor(diffMs / (1000*60*60*24*7)) + " weeks, " : "";
    str += Math.floor((diffMs / (1000*60*60*24)) % 7) > 0 ? Math.floor((diffMs / (1000*60*60*24)) % 7) + " days, " : "";
    str += Math.floor((diffMs % 86400000) / 3600000 % 24) > 0 ? Math.floor((diffMs % 86400000) / 3600000 % 24) + " hours, " : "";
    str += Math.round(((diffMs % 86400000) % 3600000) / 60000) > 0 ? Math.round(((diffMs % 86400000) % 3600000) / 60000) + " minutes" : "";
    return str;
}

export const getDaysDifference = (time1, time2) => {
    const diffMs = new Date(time2) - new Date(time1);
    return Math.ceil(diffMs/ 86400000) === 0 ? 1 : Math.ceil(diffMs/ 86400000);
}

export const DurationFromSeconds = (time) => {
    let str = "";
    // str += Math.floor(time / (60*60*24*7)) > 0 ? Math.floor(time / (60*60*24*7)) + "w, " : "";
    // str += Math.floor((time / (60*60*24)) % 7) > 0 ? Math.floor((time / (60*60*24)) % 7) + "d, " : "";
    // str += Math.floor((time % 86400) / 3600 % 24) > 0 ? Math.floor((time % 86400) / 3600 % 24) + "h, " : "";
    // str += Math.round(((time % 86400) % 3600000) / 60) > 0 ? Math.round(((time % 86400) % 3600) / 60) + "m " : "";

    if (time < 1) return '0m'

    str += Math.floor((time / 3600)) > 0 ? Math.floor((time / 3600)) + "h " : "";
    str += Math.round(((time) % 3600) / 60) > 0 ? Math.round(((time % 86400) % 3600) / 60) + "m " : "";
    return str.trim();
}

export const timeFromSeconds = (time) => {
    let str = "";
    str += Math.floor((time % 86400) / 3600 % 24) > 0 ? ("0" + Math.floor((time % 86400) / 3600 % 24).toString()).slice(-2)
        + ":" : "00";
    str += Math.round(((time % 86400) % 3600000) / 60) > 0 ? ("0" + Math.round(((time % 86400) % 3600) / 60).toString()).slice(-2)
        + "" : ":00";
    return str;
}

export const splitStrTime = (str) => {
    const year = str.substr(0, 4);
    const month = str.substr(4, 2);
    const day = str.substr(6, 2);
    const hour = str.substr(8, 2);
    const min = str.substr(10, 2);
    const sec = str.substr(12, 2);
    return day+"-"+month+"-"+year+" "+hour+":"+min+":"+sec
}

export const getSecondsDifference = (time1, time2) => {
    const msDiff  = new Date(time2).getTime() - new Date(time1).getTime();
    return  msDiff / 1000;
}


export const dateString = (date) => {
    return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
    ].join('-');
}


export const fullDurationFromSeconds = (time) => {
    if (time < 60) return "0m"

    let str = "";
    // str += Math.floor(time / (60*60*24*7)) > 0 ? Math.floor(time / (60*60*24*7)) + "w, " : "";
    // str += Math.floor((time / (60*60*24)) % 7) > 0 ? Math.floor((time / (60*60*24)) % 7) + "d, " : "";
    str += Math.floor(time  / 3600) > 0 ? Math.floor(time  / 3600) + "h, " : "";
    str += Math.round((time % 3600) / 60) > 0 ? Math.round((time % 3600) / 60) + "m " : "";
    // str += Math.round(((time % 86400) % 3600000) % 60) > 0 ? Math.round(((time % 86400) % 3600) % 60) + "s " : "";
    return str.trim();
}
