export const formatReportAddress = (data, type) => {
    let add;


    if (type === 'start'){
        add = data?.startAddress;
    } else {
        add = data?.endAddress;
    }

    let str = '';

    if (add?.poi){
        str = add?.poi?.name;
        str += ' ('+add?.poi?.cat+')';
    } else {
        add = add?.addressData;
        str += add?.road ? add?.road : '';

        if (add?.village){
            str += str?.length > 0 ? ', ' : '';
            str += add?.village;

        } else {
            if (add?.town) {
                str += str?.length > 0 ? ', ' : '';
                str += add?.town
            } else {
                str += str?.length > 0 ? ', ' : '';
                str += add?.city;
            }
        }

        if (add?.postcode){
            str += str?.length > 0 ? ', ' : '';
            str += add?.postcode
        }
    }

    return str;
}