    import React, {useContext, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  EventClassificationIcon  from '../../panelContent/grid/cardComponents/eventClassificationIcon'
import  EventSeverityIcon  from '../../panelContent/grid/cardComponents/eventSeverityIcon'
import EventTabs from "./eventModalComponents/eventTabs";
import {
    faBuildings,
    faMapMarkerAlt,
    faStreetView,
    faUser,
    faCar,
    faCircleInfo, faPen, faSave
} from "@fortawesome/pro-regular-svg-icons";
import EventSpeedLimitIcon from "../../panelContent/grid/cardComponents/eventSpeedLimitIcon";
import fetchDeviceDetails from "../../../../api/device/fetchDeviceDetails";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";
import mainContext from "../../../contexts/mainContext";
import fetchVideoBudget from "../../../../api/device/fetchVideoBudget";
import indexContext from "../../../contexts/indexContext";
import googleMapsLogo from '../../../../svgs/google-maps-old.svg'
import {Fieldset} from "primereact/fieldset";
import StackedSeverityClassificationIcon from "../../panelContent/grid/cardComponents/stackedSeverity&ClassificationIcon";
import {Button} from "primereact/button";
import {toTitleCase} from "../../../../functions/formatting/titleCase";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {safeParse} from "../../../../functions/formatting/safeParse";
import {maxSpeed} from "../../../../functions/formatting/maxspeed";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import AddPlaceButton from "../../panelContent/grid/cardComponents/addPlaceButton";
import UpdateRoadSpeed from "./eventModalComponents/updateRoadSpeed";
import {copyToClipboard} from "../../../../functions/copyToClipboard";
import {Divider} from "primereact/divider";
import {Chip} from "primereact/chip";
import {findDataByUniqueKey, getStoreData} from "../../../../functions/db";
import {InputText} from "primereact/inputtext";
import postEditDriverName from "../../../../api/drivers/postEditDriverName";
import EventTagChips from "./eventModalComponents/eventTagChips";


const EventModal = ( {handleEventModalWidth} ) => {

    const {event, toast} = useContext(mainContext);
    const {fleets} = useContext(indexContext);

    const [locStr, setLocStr] = useState('');
    const [budget, setBudget] = useState();
    const [videos, setVideos] = useState([]);
    const [eventDeviceDetails, setEventDeviceDetails] = useState();
    const [videosUploaded, setVideosUploaded] = useState([])
    const [videosUploading, setVideosUploading] = useState([]);
    const [speed, setSpeed] = useState();
    const [speedLimit, setSpeedLimit] = useState();
    const [showRawTags, setShowRawTags] = useState(false)




    const scoreColourArr = ['green', '#ffc100', '#ff9a00', '#ff7000', '#ff4d00', '#ff0000']




    const scoreTag = {
        display: 'inline-block',
        textAlign: 'center',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        color:'white',
        background: scoreColourArr?.[Math.floor((event?.riskScore / 20)) > -1 ? Math.floor((event?.riskScore / 20)) > 5 ? 5 : Math.floor((event?.riskScore / 20)) : 0]
    }

    const tStyle = (type) => {
       const style = {
           padding: '2px 2px',
           margin: '3px',
           background: 'var(--surface-300)',
           fontSize: '11px',
           color: 'var(--text-color)'
       }

       if (type === 'tag'){
           style.background = 'var(--gray-700)'
           style.color = 'white'
       }

       return style
    }


    const handleVideosUploading = (video) => {
        setVideosUploading([video])
    }


    const addVideo = (val) => {
        if (!videos.includes(val.ch)){
            const newVids = videos.slice();
            newVids.push(val.ch.toString())
            setVideos(newVids);
        }
        if (!videosUploaded.includes(val)){
            const newVids = videosUploaded.slice();
            newVids.push(val)
            setVideosUploaded(newVids);
        }
    }

    const handleSpeed = (speed) => {
        if (typeof parseInt(speed) === 'number' && speed !== 'NaN'){
            setSpeed(speed)
        }
    }

    const handleSpeedLimit = (speedRaw) => {


        let speed = null
        if (typeof speedRaw === 'object'){
            speed = speedRaw?.[0]
        }
        if (typeof parseInt(speed) === 'number' && speed !== 'NaN' && speed !== null){
            setSpeedLimit(speed)
        }
    }

    useEffect(async () => {
        if (event){
            setVideosUploading([])
            handleSpeed((event?.speed / 100 * 0.621371).toFixed())

            let time = event?.startTime?.replace(" ", "T");
            let endTime = event?.endTime?.replace(" ", "T");



            event?.eventData.forEach(data => {
               if ((data?.acquisitionTime === time || data?.acquisitionTime === endTime) && data?.maxspeed){
                   setSpeedLimit(maxSpeed(data?.maxspeed))
                   //failover to fetching via overpass
               }
            });

            const details = await fetchDeviceDetails({properties: {dn: event?.dn}});

            await setEventDeviceDetails(details[0]);

            const budgetNew = await fetchVideoBudget({dn: event?.dn});
            setBudget(budgetNew);

            const vids = [];

            if (event?.videos?.length > 0){
                for (const video of event?.videos) {
                    vids?.push(video?.ch?.toString());
                }
            }



            setVideos(vids);
            let str = '';
            str += event?.location?.features?.[0]?.properties?.address?.road ? event?.location?.features?.[0]?.properties?.address?.road : '';

            if (event?.location?.features?.[0]?.properties?.address?.village){
                str += str?.length > 0 ? ', ' : '';
                str += event?.location?.features?.[0]?.properties?.address?.village;

            } else {
                if (event?.location?.features?.[0]?.properties?.address?.town) {
                    str += str?.length > 0 ? ', ' : '';
                    str += event?.location?.features?.[0]?.properties?.address?.town
                } else if (event?.location?.features?.[0]?.properties?.address?.city) {
                    str += str?.length > 0 ? ', ' : '';
                    str += event?.location?.features?.[0]?.properties?.address?.city;
                }
            }

            if (event?.location?.features?.[0]?.properties?.address?.postcode){
                str += str?.length > 0 ? ', ' : '';
                str += event?.location?.features?.[0]?.properties?.address?.postcode
            }

            setLocStr(str);
        }
    }, [event])

    const showTemplate = (event) => {
        confirmPopup({
            target: event.currentTarget,
            group: 'templating',
            header: 'Confirmation',
            message: (
                <EditDriverName />
            ),
            footer: (<div></div>)
        });
    };


    const EditDriverName = () => {
        const [name, setName] = useState('')

        return (
            <React.Fragment>
                <div style={{width: '200px', display: 'flex', flexDirection: 'column'}}>
                    <div>Editing driver name</div>

                    <InputText type="text" placeholder="New driver name" value={name} onInput={(e) => {setName(e?.target?.value)}}/>
                    <div style={{justifyContent: 'center'}}>
                        <Button className="p-button p-button-sm" onClick={async () => {
                            await postEditDriverName({
                                eventId: event._id,
                                driverName: name,
                                fleetId: event.fleetId
                            });
                            accept(name);
                        }} label={<FontAwesomeIcon icon={faSave}/>}/>
                    </div>

                </div>
            </React.Fragment>
        )
    }

    const accept = (name) => {
        toast.current.show({ severity: 'info', summary: 'Name changed', detail: `Changed driver name to '${name}'`, life: 3000 });
    };






    return (
        <div style={{height: '100%', overflow: 'hidden', paddingTop: '20px'}} key={event?._id}>


            <div style={{
                display: 'flex', position: 'relative', border: '1px solid var(--surface-border)', padding: '5px',
                alignItems: 'center', gap: '10px', borderRadius: '5px'
            }}>


                <div style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '20px',
                    zIndex: '10000',
                    background: 'var(--surface-a)',
                    paddingLeft: '5px', paddingRight: '5px', fontWeight: 700,
                }}>
                    Tags
                    {fetchUserLevel() === 'superuser' &&
                        <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: '5px', padding: '0px!important'}} onClick={() => setShowRawTags(!showRawTags)}/>
                    }
                </div>
                <div style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '4px',
                    zIndex: '10000',
                    background: 'var(--surface-a)',
                    paddingLeft: '1px', paddingRight: '1px', fontWeight: 700
                }}>Limit
                </div>
                <div style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '42px',
                    zIndex: '10000',
                    background: 'var(--surface-a)',
                    paddingLeft: '1px', paddingRight: '1px', fontWeight: 700
                }}>Speed
                </div>
                <div style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '92px',
                    zIndex: '10000',
                    background: 'var(--surface-a)',
                    paddingLeft: '10px',  fontWeight: 700
                }}>Score
                </div>


                <div style={{flex: 1, display: 'flex', flexWrap: 'wrap', paddingLeft: '15px'}}>
                    {fetchUserLevel() === 'superuser' ?
                        <React.Fragment>
                            {event?.driverPresent &&
                                <Chip  label={'Driver Present'} className="activeChips"/>}
                            {event?.cameraHealthWarning &&
                                <Chip label={'Camera Health Warning - ' + event?.cameraHealthWarning}
                                        className="activeChips"/>}


                            <EventTagChips />




                            { showRawTags && JSON.stringify(event?.eventTags)}

                        </React.Fragment>
                    :
                        <EventTagChips />

                    }
                </div>
                <div style={scoreTag} className="p-mb-2">
                    {Math.floor((event?.riskScore / 10)) > -1 ? Math.floor(((event?.riskScore > 100 ? 100 : event?.riskScore) / 10)) : 0}
                </div>

                <div style={{fontSize: '25px', fontWeight: 'bold', textAlign: 'center', alignItems: 'center', paddingLeft: '6px', paddingRight: '6px'}}>
                    {speed ? speed : 0}
                    <div style={{fontSize: '8px', marginTop: '-4px'}}>
                        mph
                    </div>
                </div>
                <EventSpeedLimitIcon event={event} speedLimit={speedLimit}/>

            </div>


            <Fieldset legend="Details" style={{marginBottom: '20px'}}>


                <div style={{display: 'flex', alignItems: 'top', justifyContent: 'space-between', marginBottom: '5px', marginTop: '-20px'}}>

                    <div>
                        {event?.driver &&
                            <div>
                                <FontAwesomeIcon icon={faUser} style={{marginRight: '8px'}}/>
                                <span onClick={() => copyToClipboard(event?.driver)}>{event?.driver}</span>

                                <span onClick={showTemplate} style={{marginLeft: '10px'}}>
                                    <FontAwesomeIcon icon={faPen} />
                                </span>

                            </div>
                        }


                        {eventDeviceDetails &&
                            <div>
                                <FontAwesomeIcon icon={faCar} style={{marginRight: '7px'}}/>
                                {eventDeviceDetails?.vehicleDvlaData &&
                                    <span style={{maxWidth: '100%',fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}}>
                                        {eventDeviceDetails?.vehicleDvlaData?.DvlaVehicleMake && <React.Fragment>{eventDeviceDetails?.vehicleDvlaData?.DvlaVehicleMake}</React.Fragment>}
                                        {eventDeviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Make && <React.Fragment>{eventDeviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Make}</React.Fragment>}
                                        &nbsp;-&nbsp;
                                        {eventDeviceDetails?.vehicleDvlaData?.DvlaVehicleModel && <React.Fragment>{eventDeviceDetails?.vehicleDvlaData?.DvlaVehicleModel}</React.Fragment>}
                                        {eventDeviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Model && <React.Fragment>{eventDeviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Model}</React.Fragment>}
                                    </span>
                                }
                            </div>
                        }


                        {eventDeviceDetails &&
                            <div>
                                <FontAwesomeIcon icon={faBuildings} style={{marginRight: '7px'}}/>
                                {friendlyFleetName(eventDeviceDetails.fleetId, fleets)}
                            </div>
                        }
                        {event?.location &&
                            <span>
                                <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight: '10px'}}/>
                                {locStr}
                                <a target="_blank" style={{marginLeft: '10px'}}
                                   href={`http://maps.google.com/maps?q=&layer=c&cbll=${event?.lat},${event?.lng}&cbp=11,0,0,0,0`}>
                                    <img src={googleMapsLogo} height="14px" width="14px"/>
                                </a>

                                {/*<AddPlaceButton fleetId={eventDeviceDetails?.fleetId} lat={event?.lat} lng={event?.lng} />*/}
                        </span>
                        }
                    </div>
                </div>

                {fetchUserLevel() === 'superuser' &&
                    <React.Fragment>
                        {event?.driverDetails &&
                            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <div>Driver:</div>
                                <Button style={tStyle('tag')} label={event?.driverDetails?.[0]?.driverName}
                                        className="p-mb-2"/>
                            </div>
                        }
                    </React.Fragment>
                }

                {event?.eventSource === 'speedReport' &&
                    <UpdateRoadSpeed speedLimit={speedLimit}/>
                }

            </Fieldset>
            <EventTabs
                eventDeviceDetails={eventDeviceDetails} budget={budget} videos={videos} addVideo={addVideo}
                videosUploaded={videosUploaded} handleEventModalWidth={handleEventModalWidth}
                handleVideosUploading={handleVideosUploading} videosUploading={videosUploading}
                handleSpeed={handleSpeed}
                handleSpeedLimit={handleSpeedLimit} speedLimit={speedLimit}/>

        </div>
    );
}


export default EventModal;
