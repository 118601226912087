import React, {useContext, useEffect, useState} from 'react';
import PlaceModalMap from "./placeModalComponents/placeModalMap";
import {InputText} from "primereact/inputtext";
import mainContext from "../../../contexts/mainContext";
import {Dropdown} from "primereact/dropdown";
import {faBuildings, faTrashAlt, faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";
import {applySpacing} from "../../../../functions/formatting/applySpacing";
import fetchReverseGeocode from "../../../../api/fetchReverseGeocode";
import postDeletePoi from "../../../../api/places/postDeletePoi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {catOptions} from "../../panelContent/places/catOptions";
import {Slider} from "primereact/slider";
import indexContext from "../../../contexts/indexContext";
import {Button} from "primereact/button";
import {Chip} from "primereact/chip";
import {Tooltip} from "primereact/tooltip";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";
import {getStoreData} from "../../../../functions/db";
import {Message} from "primereact/message";


const PlaceModal = ({placeSelected, saveChanges, fleetId, handleShowPlaceModal, handleRefreshPois}) => {


    const tStyle = {
        padding: '2px 2px',
        margin: '3px',
        background: 'var(--surface-300)',
        fontSize: '13px',
        color: 'var(--text-color)',
        border: "none"
    }


    const {fleets} = useContext(indexContext);
    const [newCoords, setNewCoords] = useState([placeSelected?.location?.coordinates[0], placeSelected?.location?.coordinates[1]]);
    const [placeName, setPlaceName] = useState(placeSelected?.name);
    const [placeCategory, setPlaceCategory] = useState();
    const [location, setLocation] = useState();
    const [radius, setRadius] = useState(placeSelected? placeSelected?.radius : 200);
    const [currentNames, setCurrentNames] = useState([])
    const [theFleets, setTheFleets] = useState()
    const [mainFleet, setMainFleet] = useState()


    //
    //             fleet id if exists
    //             if not exists look at if place selected has Id
    //             if not look if fleets length > 1
    //             else supply empty



    const initTheFleets = () => {
        let f;
        if (fleetId){
            f = [fleetId]
        } else if (placeSelected?._id){
            f = placeSelected?.fleetIds
        } else if (fleets?.length > 1){
            f = [];
        } else {
            f = [fleets?.[0]?.fleetId]
        }
        setTheFleets(f)
    }




    const resetTheFleet = (val) => setTheFleets((prevItems) => {
        // Create a new array with the first item replaced
        return [val, ...prevItems.slice(1)];
    })


    const handleNewCoords = (coords) => setNewCoords(coords);
    const handleLocation = (loc) => setLocation(loc);

    const addFleet = val => {
        if (theFleets?.length > 0 && !theFleets?.includes(val)){
            setTheFleets([...theFleets, val])
        } else if (theFleets?.length < 1 || !theFleets){
            setTheFleets([val])
        }
    }
    const removeFleet = val => setTheFleets(prevItems => prevItems.filter(item => item !== val));

    const savePlaceChanges = async () => {


        const data = {
            _id: placeSelected?._id,
            name: placeName,
            fleetIds: theFleets,
            lat: newCoords?.[1],
            lng: newCoords?.[0],
            cat: placeCategory?.display,
            radius: radius
        }
        await saveChanges(data, placeSelected)
    }

    useEffect(async () => {

        let pois = await getStoreData('places');
        pois = pois.reduce((acc, curVal) => acc.concat(curVal?.name?.toLowerCase()), []);
        setCurrentNames(pois.filter(item => item !== placeSelected?.name?.toLowerCase()))

        if (placeSelected) {
            setPlaceCategory(await catOptions.filter(cat => cat.display === placeSelected?.cat)?.[0])
        }
    }, [placeSelected])


    useEffect(() => {
        if (theFleets){
            setMainFleet(fleets?.filter(f => f?.fleetId === theFleets?.[0])?.[0])
        }
    }, [theFleets])


    useEffect(() => {
        initTheFleets();
    }, []);

    return (
        <React.Fragment>
            <div style={{display: 'flex', width: '100%', height: '45vh'}}>
                <PlaceModalMap placeSelected={placeSelected} handleNewCoords={handleNewCoords} handleLocation={handleLocation}
                location={location} radius={radius} newCoords={newCoords}/>
            </div>

            <div style={{textAlign:'center', marginTop: '5px'}}>
                Click a place on the map to position the POI
            </div>


            <div style={{marginTop: '10px'}}>

                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                    <div style={{width: '100px'}}>Radius:</div>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                        <div style={{flex: 1, paddingRight: '10px'}}>
                            <Slider disabled={!newCoords?.[0]} value={radius} onChange={(e) => setRadius(e.value)} min={50} step={10} max={1000}/>
                        </div>
                        <InputText id="sliderValue" value={radius+'m'} readOnly className={"w-4rem"}/>

                    </div>
                </div>

                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                    <div style={{width: '100px'}}>Place name:</div>
                    <InputText id="name" value={placeName} placeholder="50 characters maximum" style={{flex:1}} maxLength={50}
                               onChange={(e) => setPlaceName(e.target.value)}
                    />

                </div>


                {currentNames?.includes(placeName?.toLowerCase()) &&
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Message severity={"warn"} text={"A place of interest of this name already exists for this fleet"}/>
                    </div>
                }



                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>

                    <div style={{width: '100px'}}>Category:</div>

                    <div style={{display: 'flex', flex:1, justifyContent: 'flex-start'}}>
                        {catOptions?.map(cat => (
                            <React.Fragment>
                                <div className={"poiBadge_"+cat?.display.replace(" ", "")} style={{borderRadius: '50%',
                                    background: placeCategory === cat ? 'var(--v-list-button-selected)' : "", width: '28px',
                                    height: '28px', position: 'relative', cursor: 'pointer', color: placeCategory === cat ? 'var(--v-list-button-text-selected)' : ""}}
                                     onClick={() => setPlaceCategory(cat)} data-pr-position="bottom">
                                    <FontAwesomeIcon icon={cat.icon} style={{fontSize: "18px", position: 'absolute', top: "50%", left: "50%", transform: 'translate(-50%,-50%)'}}/>
                                </div>

                                <Tooltip target={".poiBadge_"+cat?.display.replace(" ", "")}>{cat?.display}</Tooltip>
                            </React.Fragment>
                        ))}

                    </div>


                    {/*<Dropdown id="category" options={catOptions} value={placeCategory}*/}
                    {/*          onChange={(e) => setPlaceCategory(e.target.value)}*/}
                    {/*          valueTemplate={(e) => optionTemplate(e, 'desktop')}*/}
                    {/*          itemTemplate={(e) => optionTemplate(e, 'desktop')}*/}
                    {/*          placeholder="Category" optionLabel="display"/>*/}

                </div>

                {fleets?.length > 1 &&
                    <React.Fragment>
                        <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>

                            <div style={{width: '100px'}}>Fleet:</div>
                            <Dropdown options={fleets} optionLabel={'fleetName'} value={mainFleet}
                                      onChange={e => resetTheFleet(e.target.value.fleetId)}
                                      placeholder={"Select"} filter style={{flex:1}}/>
                        </div>


                        <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>

                            <div style={{width: '100px'}}>Additional fleets:</div>
                            <Dropdown options={fleets} optionLabel={'fleetName'} style={{flex:1}}
                                      onChange={e => addFleet(e.target.value.fleetId)}
                                      placeholder={"Select"} filter disabled={theFleets?.length < 1}/>
                        </div>
                        <div>
                            {theFleets?.map((f, i) => (
                                <React.Fragment>
                                    {i > 0 &&
                                        <button key={f} className="p-button p-button-sm" style={tStyle}
                                                onClick={() => removeFleet(f)}>
                                            <div className="p-button-label"
                                                 style={{display: 'flex', alignItems: 'center'}}>
                                                {friendlyFleetName(f, fleets)}
                                                &nbsp;
                                                <FontAwesomeIcon icon={faCircleXmark}/>
                                            </div>
                                        </button>
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    </React.Fragment>
                }


            </div>
            <div style={{textAlign: 'right'}}>
                <button className="p-button p-button-sm p-button-secondary" onClick={() => handleShowPlaceModal(false)}
                        style={{marginRight: '10px'}}>
                    <span className="p-button-label">Cancel</span>
                </button>


                <button className={(currentNames?.includes(placeName?.toLowerCase()) || placeName?.length < 1 || !placeName || !placeCategory  || theFleets?.length < 1) ?
                    "p-button p-button-sm p-disabled" : "p-button p-button-sm"}
                        onClick={savePlaceChanges} disabled={currentNames?.includes(placeName?.toLowerCase()) || theFleets?.length < 1}>
                    <span className="p-button-label">Save</span>
                </button>

            </div>
        </React.Fragment>
    )
}
export default PlaceModal
