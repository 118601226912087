import {
    DurationFromSeconds,
    formatDateOnly,
    formatTimeOnly,
    getDaysDifference
} from "../../../../../functions/formatting/formatDate";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import fetchJourneys from "../../../../../api/journeys/fetchJourneys";
import {gToKg} from "../../../../../functions/formatting/gToKg";
import {formatReportAddress} from "../formatReportAddress";




export const fetchCo2ReportData = async (dateRange, vehicle, vehicleDetails) => {

    if (dateRange){
        let loopIterations = getDaysDifference(dateRange[0], dateRange[1]);

        if (loopIterations === 1) loopIterations = 0

        let activity = [];

        for (let i = 0; i <= loopIterations; i++) {

            const  startDate = new Date(dateRange[0]);
            let theDay =  new Date(new Date(dateRange[0]).setDate(startDate.getDate() + i));

            const dateString = [
                theDay.getFullYear(),
                ('0' + (theDay.getMonth() + 1)).slice(-2),
                ('0' + theDay.getDate()).slice(-2)
            ].join('-');

            if (vehicle){
                // if v selected only show single v data
                const journeysRaw = await fetchJourneys(vehicle?.properties?.dn, dateString);
                const journeys = journeysRaw.filter(j => j.endJpeg)
                let totalDur = 0;
                let totalDistance = 0;
                let shiftDur = 0;
                let totalStopped = 0;
                let totalIdleTime = 0;
                let totalTrueIdleTime = 0;

                if(journeys?.length > 0){

                    // add prev j endtime to j object here, so stopped time can be calculated
                    for (let i = 0; i < journeys?.length; i++) {

                        const j = journeys?.[i];

                        if (j?.duration){
                            const arr = j?.duration?.split(':');
                            const totalSeconds = (parseInt(arr[0])* 3600) + (parseInt(arr[1]) * 60) + (parseInt(arr[2]))
                            totalDur += parseInt(totalSeconds)
                        }
                        if(j?.distance){
                            totalDistance += j?.distance
                        }
                        totalTrueIdleTime += j?.trueIdling ? j?.trueIdling : 0;
                        totalIdleTime += j?.idling ? j.idling : 0;
                        totalStopped += j?.stopTime ? j?.stopTime : 0;


                        j.stopTimeFull = j.stopTime > 60 ? DurationFromSeconds(j?.stopTime) : '0m';
                        j.startPlace = formatReportAddress(j,'start');
                        j.endPlace = formatReportAddress(j,'end');
                        j.date = formatDateOnly(j.startTime)
                        j.startTime = formatTimeOnly(j?.startTime)
                        j.endTime = formatTimeOnly(j?.endTime);
                        j.distanceMiles = j.distance.toFixed(0);
                        j.journeyNo = i+1;
                    };


                    const st = new Date(journeys?.[0]?.start?.at).getTime() ;
                    const et = new Date(journeys?.[journeys?.length - 1]?.finish.at).getTime();
                    shiftDur = (et - st) / 1000
                }

                activity.push({
                    date: formatDateOnly(journeys?.[0]?.start.at),
                    start: journeys?.[0]?.start,
                    finished: journeys?.[journeys?.length - 1]?.finished,
                    journeys: journeys?.length,
                    startTime: formatTimeOnly(journeys?.[0]?.start?.at ? journeys?.[0]?.start?.at :
                        journeys?.[0]?.startTime),
                    endTime: formatTimeOnly(journeys?.[journeys?.length - 1]?.finished?.at ? journeys?.[journeys?.length - 1]?.finished?.at : journeys?.[journeys?.length - 1]?.finish?.at),
                    totalDistance: totalDistance.toFixed(0) + ' miles',
                    distance:totalDistance,
                    startPlace: formatReportAddress(journeys?.[0], 'start'),
                    endPlace: formatReportAddress(journeys?.[journeys?.length - 1], 'end'),
                    co2: (totalDistance * 1.60934) * vehicleDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions,
                    co2Emissions: gToKg(((totalDistance * 1.60934) * vehicleDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions).toFixed(0))
                });
            }
        }
        return activity
    }

}