import {
    DurationFromSeconds,
    formatDateOnly,
    formatTimeOnly, fullDurationFromSeconds,
    getDaysDifference,
    getSecondsDifference
} from "../../../../../functions/formatting/formatDate";
import fetchJourneys from "../../../../../api/journeys/fetchJourneys";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import fetchJourney from "../../../../../api/journeys/fetchJourney";
import {formatReportAddress} from "../formatReportAddress";





export const fetchActivityData = async (dateRange, vehicle, theFleet, vehicles) => {

    if (dateRange){
        let loopIterations = getDaysDifference(dateRange[0], dateRange[1]);
        if (loopIterations === 1) loopIterations = 0
        let activity = [];

        for (let i = 0; i <= loopIterations; i++) {

            const  startDate = new Date(dateRange[0]);
            let theDay =  new Date(new Date(dateRange[0]).setDate(startDate.getDate() + i));

            const dateString = [
                theDay.getFullYear(),
                ('0' + (theDay.getMonth() + 1)).slice(-2),
                ('0' + theDay.getDate()).slice(-2)
            ].join('-');

            if (vehicle){
                // if v selected only show single v data
                const journeysRaw = await fetchJourneys(vehicle?.properties?.dn, dateString);
                const journeys = journeysRaw.filter(j => j.endJpeg)
                let totalDur = 0;
                let totalDistance = 0;
                let shiftDur = 0;
                let totalStopped = 0;
                let totalIdleTime = 0;
                let totalTrueIdleTime = 0;

                if(journeys?.length > 0){

                    // add prev j endtime to j object here, so stopped time can be calculated
                    for (let i = 0; i < journeys?.length; i++) {

                        const j = journeys?.[i];

                        if (j?.duration){
                            const arr = j?.duration?.split(':');
                            j.duration = (parseInt(arr[0])* 3600) + (parseInt(arr[1]) * 60) + (parseInt(arr[2]));
                            j.durationFull = fullDurationFromSeconds(j.duration);

                        }


                        totalTrueIdleTime += j?.trueIdling ? j?.trueIdling : 0;
                        totalIdleTime += j?.idling ? j.idling : 0;
                        totalStopped += j?.stopTime ? j?.stopTime : 0;
                        j.stopTimeFull = j?.stops?.length > 0 ? DurationFromSeconds(j?.stops.reduce((total, item) => {
                            return total + (item.stop.segmentDuration || 0);
                        }, 0)) : '0m';
                        j.startPlace = formatReportAddress(j,'start');
                        j.endPlace = formatReportAddress(j,'end');
                        j.date = formatDateOnly(j.startTime)
                        j.startTime = formatTimeOnly(j?.startTime)
                        j.endTime = formatTimeOnly(j?.endTime);
                        j.distanceMiles = j.distance.toFixed(0) + ' mi';
                        j.journeyNo = i+1;
                    };

                    const st = new Date(journeys?.[0]?.start?.at).getTime() ;
                    const et = new Date(journeys?.[journeys?.length - 1]?.endTime).getTime();
                    shiftDur = (et - st) / 1000
                }
               activity = activity?.concat(journeys)
            }
        }
        return activity
    }
}
