import React, {useContext, useEffect, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import indexContext from "../../../../contexts/indexContext";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetPoi from "../../../../../api/postFleetPoi";
import LoadingSpinner from "../../../loadingSpinner";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import PoiReportRow from './poiReportRow';
import {
    DurationFromSeconds, formatDateOnly,
    formatDateTimesheetReport,
    fullDurationFromSeconds
} from "../../../../../functions/formatting/formatDate";
import {Button} from "primereact/button";
import {fetchPoiDataForDate} from "./fetchPoiDataForDate";
import {poiReportPdf} from "./poiReportPdf";


function FleetPoiReportTableRow(props) {
    return null;
}

FleetPoiReportTableRow.propTypes = {};
const PoiReport = ({}) => {

    const {theVehicle, theFleet, dateRange, reportLoading, handleReportLoading,
        generateReport} = useContext(reportsContext);

    const [activityData, setActivityData]= useState();
    const [expandedRows, setExpandedRows] = useState(null);



    const generateReportData = async () => {


        const data = {
            fleetId: theFleet?.fleetId,
            startTime: [
                dateRange[0].getFullYear(),
                ('0' + (dateRange[0].getMonth() + 1)).slice(-2),
                ('0' + dateRange[0].getDate()).slice(-2)
            ].join('-') + 'T00:00:00+00:00',
            endTime: [
                dateRange[1].getFullYear(),
                ('0' + (dateRange[1].getMonth() + 1)).slice(-2),
                ('0' + dateRange[1].getDate()).slice(-2)
            ].join('-')  + 'T23:59:00+00:00'
        }

        const r2 = await postFleetPoi(data);

        const dataObj = [];

        r2?.journeys?.forEach(j => {
            j.poi.forEach(p => {

                if (p.name){
                    const index = dataObj.findIndex(item => item.name === p.name);

                    if (index > -1){
                        dataObj[index].count += p?.count
                    } else {
                        dataObj.push(p)
                    }
                }


            });
        });

        r2?.stops?.forEach(s => {
            s.poi.forEach(p => {
                const index = dataObj.findIndex(item => item.name === p.name);

                if (p.name){
                    if (index > -1){
                        dataObj[index].count += p?.count
                    } else {
                        dataObj.push(p)
                    }
                }
            });
        });

        setActivityData(dataObj);

        const cache = {
            actData: dataObj,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }


        localStorage.setItem('speedingReportOverview', JSON.stringify(cache));
        handleReportLoading(false);

    }



    useEffect(async () => {
        if (localStorage.getItem('fleetPoiReport')){

            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('fleetPoiReport'));



            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){
                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    //setActTotals(cache.totals);
                    setActivityData(cache.actData);
                    console.log(cache)
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    //setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }

            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }



    }, [dateRange, theVehicle, theFleet, generateReport]);






    const RowExpansionTemplateCaller = (data) => {
        return <PoiReportRow data={data} />
    };



    const fetchAllDataForExport = async () => {
        for (let i = 0; i < activityData?.length; i++) {
            activityData[i].vehicles = await fetchPoiDataForDate(activityData[i], theFleet, dateRange)
        }
        return activityData
    }



    const exportCSV = async () => {
        const data = await fetchAllDataForExport();

        let  toExport = [];

        for await (const place of data){
            for await (const vehicle of place?.vehicles){
                for await (const poi of vehicle?.poi){
                    toExport.push({
                        placeName: place?.name,
                        placeCategory: place?.cat,
                        placePostcode: place?.postcode,
                        dn: vehicle?.dn,
                        timeIn: poi?.startTime,
                        timeOut: poi?.endTime,
                        acc: poi?.acc

                    })
                }
            }
        }

        const csvRows = [];
        const headers = Object.keys(toExport[0]);
        csvRows.push(headers.join(","));

        toExport.forEach((row) => {
            const values = headers.map((header) => {
                const val = row[header] !== null && row[header] !== undefined ? row[header] : "";
                return `"${val}"`;
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const range = formatDateOnly(dateRange?.[0])+'-'+formatDateOnly(dateRange?.[1]);
        link.download = `Fleet poi Report ${theFleet?.fleetName} ${range}.csv`;

        link.click();
        window.URL.revokeObjectURL(url);

    }

    const exportPdf = async () => {
        const data = await fetchAllDataForExport()
        poiReportPdf(data, theFleet, dateRange);
    }




    const header = (
        <div style={{
            color: 'var(--text-color)',
            fontSize: '1.17em',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{paddingLeft:'5px'}}>
                Poi report for {theFleet?.fleetName}<br/>
                {formatDateTimesheetReport(dateRange?.[0])} - {formatDateTimesheetReport(dateRange?.[1])}
            </div>

            <div style={{display:'flex', gap:'5px'}}>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                {/*<Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />*/}

                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>

        </div>
    );


    const statusTemplate = (rowData) => {
        return fullDurationFromSeconds(rowData.totalSeconds)
    };





    return (
        <React.Fragment>
            {generateReport &&
                <React.Fragment>

                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>


                            <DataTable value={activityData} scrollable scrollHeight='calc(100vh - 220px)'
                                       expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                        rowExpansionTemplate={RowExpansionTemplateCaller} header={header}
                                       dataKey="name">
                                <Column expander />
                                <Column field="name" header="Name"></Column>
                                <Column field="postcode" header="Postcode"></Column>
                                <Column field="count" header="Total visits" align={"right"}></Column>
                                <Column body={statusTemplate} header="Total time" align={"right"} style={{paddingRight:'20px'}}></Column>
                            </DataTable>
                        </React.Fragment>
                    }
                </React.Fragment>

            }
        </React.Fragment>
    )
}

export default PoiReport