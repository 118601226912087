import React, {useContext, useEffect, useState} from 'react'
import LoadingSpinner from "../../../loadingSpinner";
import reportsContext from "../../../../contexts/reportsContext";
import ReportsParameterSelection from "../reportsParameterSelection";
import fetchJourney from "../../../../../api/journeys/fetchJourney";
import ActivityReportTable from "../activityReport/legacy/activityReportTable";
import MovementReportTableHeader from "./movementReportTableHeader";
import {formatTimeOnly} from "../../../../../functions/formatting/formatDate";
import MovementReportSummary from "./movementReportSummary";



const MovementReport = ({}) => {

    const {dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi, theVehicle, theJourney} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }


    const generateReportData = async () => {

        const j = await fetchJourney(theJourney?._id)

        console.log(j)
        setActivityData(j)
        handleReportLoading(false)
    }

    useEffect(async () => {

        if (localStorage.getItem('movementReport')){
            handleReportLoading(false);

            const cache = JSON.parse(localStorage.getItem('movementReport'));

            if (cache.dateRange === JSON.stringify(dateRange) && cache.dn === theVehicle?.properties?.dn){
                setActivityData(cache.actData);
                setActTotals(cache.totals);
            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && theVehicle && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }


    }, [dateRange, theVehicle, generateReport]);





    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                    :
                        <div style={{
                            height: '100%',
                            width: '100%',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '20px'
                        }}>


                            <MovementReportSummary journey={theJourney} />

                            <MovementReportTableHeader />
                            <div style={{overflowY: 'scroll', height: '58vh', overflowX: 'hidden'}}
                                 className="scroller">
                                {activityData?.features?.map((item, index) =>
                                    <div style={{
                                        display: 'grid', gridTemplateColumns: '10% 10% 10% 10% 15% 15% 30% ',
                                        borderBottom: '1px solid var(--surface-border)', height: '73px',
                                        pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
                                        textAlign: 'center'
                                    }} className="reportTable" key={index}>

                                        <div style={{lineHeight: '73px'}}>
                                            {index + 1}
                                        </div>
                                        <div style={{lineHeight: '73px'}}>
                                            {formatTimeOnly(item?.properties?.time)}
                                        </div>
                                        <div style={{lineHeight: '73px'}}>
                                            {item?.properties?.speed} mph
                                        </div>

                                        <div style={{lineHeight: '73px'}}>
                                            {index === 0 && 'Journey start'}
                                            {item?.properties?.speed > 0 && index > 0 && index < activityData?.features?.length-1 && 'Driving'}
                                            {item?.properties?.speed < 1 && index > 0 && index < activityData?.features?.length-1 && 'Idling'}
                                            {index === activityData?.features?.length-1 && 'Journey end' }
                                        </div>
                                        <div style={{lineHeight: '73px'}}>
                                            {(item?.properties?.distance)?.toFixed(2)} miles
                                        </div>
                                        <div style={{lineHeight: '73px'}}>
                                            {(item?.properties?.distanceCumulative)?.toFixed(2)} miles
                                        </div>
                                        <div style={{lineHeight: '73px'}}>
                                            {JSON.stringify(item?.geometry?.coordinates)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </React.Fragment>
                :
                <div style={{padding: '40px 10px 10px 10px', width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{
                        fontWeight: 'bold',fontSize: '18px',position: 'absolute',textAlign: 'center',
                        top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
                        Please select a vehicle, journey and a  timespan


                        <br />


                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>



                    </div>
                </div>

            }
        </React.Fragment>
    )
}
export default MovementReport

