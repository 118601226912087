import React, {useContext, useEffect, useState} from 'react';
import LoadingSpinner from "../../../loadingSpinner";
import ReportsParameterSelection from "../reportsParameterSelection";
import {
    formatDateOnly,
    formatDateTimesheetReport,
    fullDurationFromSeconds
} from "../../../../../functions/formatting/formatDate";
import postFleetIdling from "../../../../../api/postFleetIdling";
import mainContext from "../../../../contexts/mainContext";
import indexContext from "../../../../contexts/indexContext";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetSpeeding from "../../../../../api/postFleetSpeeding";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faExclamationCircle, faSignsPost} from "@fortawesome/pro-regular-svg-icons";
import {faRuler, faTimer, faPercent} from "@fortawesome/pro-light-svg-icons";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {Colours} from "../../../../../colours";
import VehicleIdlingReportSummary from "../idlingReport/legacy/vehicle/vehicleIdlingReportSummary";
import FleetIdlingReportSummary from "../idlingReport/legacy/fleet/fleetIdleReportSummary";
import {MflLogo} from "../../../../../svgs/mflLogo";
import SpeedingOverviewReportSummary from "./legacy/speedingOverviewReportSummary";
import SpeedingReportOverviewRow from "./legacy/speedingReportOverviewRow";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {fleetSpeedingReportPdf} from "./fleetSpeedingReportPdf";

const FleetSpeedingReport = ({}) => {

    const {vehiclesRef} = useContext(mainContext);
    const {fleets} = useContext(indexContext)
    const {theVehicle, theFleet, generateReport, handleGenerateReport,dateRange, reportLoading,
        handleReportLoading, reportToPrint, showKpi} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);


    const generateReportData = async () => {

        const data = {
            fleetId: theFleet?.fleetId,
            startDate: [
                dateRange?.[0].getFullYear(),
                ('0' + (dateRange?.[0].getMonth() + 1)).slice(-2),
                ('0' + dateRange?.[0].getDate()).slice(-2)
            ].join('-') + 'T00:00:00',
            endDate: [
                dateRange?.[1].getFullYear(),
                ('0' + (dateRange?.[1].getMonth() + 1)).slice(-2),
                ('0' + dateRange?.[1].getDate()).slice(-2)
            ].join('-') + 'T23:59:00'
        }

        const r = await postFleetSpeeding(data);

        let distance = 0;
        let journeys = 0;
        let duration = 0;
        let speedDur = 0;
        let speedEvents = 0;
        let reportData = {};

        for await (const vehicle of r){
            console.log(vehicle)
            vehicle.avgIdling = vehicle?.trueIdling / vehicle?.journeyCount
            const details = await vehiclesRef.current.features.filter(v => v?.properties?.dn == vehicle._id)
            vehicle.registration = details?.[0]?.properties?.registration;
            vehicle.speedingPerc = (vehicle?.speedingRatio * 100).toFixed(2) + '%';
            vehicle.sumSpeedingDurationFull = fullDurationFromSeconds(vehicle?.sumSpeedingDuration)
            vehicle.durationFull = fullDurationFromSeconds(vehicle.durationSeconds)
            vehicle.distanceFull = vehicle.distance.toFixed(0) + ' mi';

            distance += vehicle.distance;
            journeys += vehicle.journeyCount;
            duration += vehicle.durationSeconds;
            speedEvents += vehicle.sumSpeedCount
            speedDur += vehicle?.sumSpeedingDuration
        }




        setActivityData(r);


        const total = {
            journeys,
            distance,
            speedDur,
            duration,
            speedEvents
        }

        const cache = {
            actData: reportData,
            totals: total,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }

        setActTotals(total);

        localStorage.setItem('speedingReportOverview', JSON.stringify(cache));
        handleReportLoading(false);

    }

    const exportPdf = () => {
        fleetSpeedingReportPdf(activityData, theFleet, actTotals, dateRange)
    }

    const exportCSV = () => {
        const exportData = activityData.map((row) => ({
            registration: row?.registration,
            journeys: row?.journeyCount,
            distance: row.distance,
            durationSeconds: row?.durationSeconds,
            sumSpeedingDuration: row?.sumSpeedingDuration,
            speedingPerc: row?.speedingPerc,
            sumSpeedCount: row?.sumSpeedCount
        }));

        // Convert JSON to CSV string
        const csvRows = [];
        const headers = Object.keys(exportData[0]);
        csvRows.push(headers.join(","));

        exportData.forEach((row) => {
            const values = headers.map((header) => {
                const val = row[header] !== null && row[header] !== undefined ? row[header] : "";
                return `"${val}"`;
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const range = formatDateOnly(dateRange?.[0])+'-'+formatDateOnly(dateRange?.[1]);
        link.download = `Fleet speeding Report ${theFleet?.fleetName} ${range}.csv`;
        link.click();
        window.URL.revokeObjectURL(url);
    }



    useEffect(async () => {

        if (localStorage.getItem('speedingReportOverview')){
            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('speedingReportOverview'));

            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){


                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }
            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }




    }, [dateRange, theVehicle, theFleet, generateReport]);



    const header = (
        <div style={{
            color: 'var(--text-color)',
            fontSize: '1.17em',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{paddingLeft:'5px'}}>
                Fleet speeding report for {theFleet?.fleetName}<br/>
                {formatDateTimesheetReport(dateRange?.[0])} - {formatDateTimesheetReport(dateRange?.[1])}
            </div>

            <div>
                <div><b>Total speeding time:</b> {fullDurationFromSeconds(actTotals?.speedDur)}</div>
                <div><b>Speeding:</b> {((actTotals?.speedDur / actTotals?.duration) * 100).toFixed(2)}%</div>
            </div>
            <div>
                <div><b>Speeding events:</b> {actTotals?.speedEvents}</div>
                <div><b>Journeys:</b> {actTotals?.journeys}</div>
            </div>

            <div>
                <div><b>Distance:</b> {actTotals?.distance?.toFixed(0)} mi</div>
                <div><b>Driving time:</b> {fullDurationFromSeconds(actTotals?.duration)}</div>
            </div>
            <div style={{display: 'flex', gap: '5px'}}>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV"/>
                {/*<Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />*/}

                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>

        </div>
    );


    return (
        <React.Fragment>
            {generateReport &&
                <React.Fragment>
                {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                    :
                        <DataTable value={activityData} scrollable scrollHeight='calc(100vh - 220px)'
                                   stripedRows header={header}>
                            <Column field="registration" header="Vehicle" style={{paddingLeft:'18px'}} align={'left'}></Column>
                            <Column field="journeyCount" header="Journeys" align={"right"}></Column>
                            <Column field="distanceFull" header="Distance" align={"right"}></Column>
                            <Column field="durationFull" header="Duration" align={"right"}></Column>
                            <Column field="sumSpeedingDurationFull" header="Speeding time" align={"right"}></Column>
                            <Column field="speedingPerc" header="Speeding (%)" align={"right"}></Column>
                            <Column field="sumSpeedCount" header="Speeding events" align={"right"} style={{paddingRight:'20px'}}></Column>
                        </DataTable>

                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default FleetSpeedingReport