const fetchReverseGeocode = (lat, lng) => {

    const requestOptions = {
        method: 'GET',
        headers: {},
    };
    //     https://geo.myfleetlive.co.uk/api/interpreter?data=%5bout:json%5d;way(around:13,51.903493,%200.923911);out%20tags;
    if (!lat || !lng) {
        return
    } else {
        return fetch(`https://georeverse.myfleetlive.co.uk/reverse?format=geojson&lat=${lat}7&lon=${lng}`,  requestOptions)
            .then((response) => {
                // console.log(response)
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((data) => {
                return Promise.resolve(data);

            }).catch(err=>{
                // console.log(err)
            });
    }
}


export default fetchReverseGeocode;
