import React, {useContext, useState} from 'react';
import {Tooltip} from "primereact/tooltip";
import ImageMfl from "../../viewMedia/ImageMfl";
import mainContext from "../../../contexts/mainContext";
import FormatAddress from "../../../../functions/formatting/formatAddress";
import indexContext from "../../../contexts/indexContext";
import {formatTimeOnly} from "../../../../functions/formatting/formatDate";
import {fetchBlockedEndpoints} from "../../../../functions/fetchBlockedEndpoints";

const  JourneySausages = ({viewportX, lastJourney, handleJHover}) => {


    const {journey, journeys, handleJourney} = useContext(mainContext);



    const journeyMouseOver = (j,index,e)=> {
        handleJHover({j:j,lastJourney: lastJourney, index: index, e:e});
    }

    const journeyMouseOut = ()=> {
        handleJHover(null);
    }

    const drawTimeLine = (lastHourAdd) => {
        const firstTime = journeys?.[0]?.startTime ? new Date(journeys[0].startTime) : new Date();
        firstTime.setMinutes(0);
        firstTime.setSeconds(0);
        const lastTime = journeys?.[journeys?.length -1]?.endTime ? new Date(journeys?.[journeys?.length -1]?.endTime) : new Date();
        lastTime.setHours(lastTime.getHours() + lastHourAdd);
        lastTime.setMinutes(0);
        lastTime.setSeconds(0);
        const secondsPixelRatio = ((lastTime - firstTime) /viewportX /1000);    // /milliseconds 1000 length bar

        if (journeys){
            journeys.forEach(j => {

                const st = new Date(j?.startTime);
                const et = new Date(j?.endTime);
                j.startPixel = (st - firstTime) / 1000  / secondsPixelRatio;
                const durationSeconds = ((et - st) / 1000);
                j.endPixel = durationSeconds / secondsPixelRatio

                if(j.endPixel < 10) {
                    j.endPixel = 10;
                }
            })
        }




        const tt = new Date(firstTime);
        let thisHour = 0;
        while (tt < lastTime) {
            const pixelHour = (tt - firstTime) / 1000  / secondsPixelRatio;
            const hour = new Date(tt);

            if(hour.getHours() > 12) {
                thisHour = (hour.getHours() - 12) + 'pm'
            } else {
                thisHour = hour.getHours() + 'am'
            }

            hourArray.push({
                pixelHour: pixelHour,
                thisHour: thisHour
            })
            tt.setHours(tt.getHours() + 1);
        }


        if (journeys){
            journeys.forEach(d => {
                if(typeof d.startTime === 'undefined'  ) {
                    d.startTime = 'xxxxxxxxxxxx';
                }

            });
        }
    }


    const hourArray = [];
    let prevPixel = 0;

    const ChangeColour = (e) => {
        e.iconColour = '#000000';

    }




    const FormatAddress = ({data}) => {
        return (
            <React.Fragment>
                <div>
                    {data?.road}
                    {data?.road &&
                        <span>,&nbsp;</span>
                    }
                    {data?.town}
                </div>
                <div>
                    {data?.county}
                    {data?.county &&
                        <span>,&nbsp;</span>
                    }
                    {data?.postcode}
                </div>
            </React.Fragment>
        )
    }

    drawTimeLine(2);



    return (
        <svg width="100%" height="50" id="jCaterpillars">
            <rect x="0" y="0" width={viewportX} height="50" fill="var(--surface-ground)" />
            <rect x="0" y="20" width={viewportX} height="20" fill="var(--surface-ground)" />

            {hourArray.length > 0 && hourArray.map((d, index) => (
                <g key={index}>
                    <line x1={d?.pixelHour} y1="7" x2={d?.pixelHour} y2="48" stroke="var(--surface-border)"/>
                    {(index % 2 === 0) &&
                        <text x={d?.pixelHour + 5} y="12" fontSize="13px" fill="var(--text-color)">{d?.thisHour}</text>
                    }
                </g>
            ))}



            {journeys !== null && journeys.length > 0 && journeys.map((d, index) => (
                <React.Fragment key={index} >

                    <g className={"journeyBlock j" + d._id} onClick={() => handleJourney(d._id)}
                       onMouseEnter={(e) => journeyMouseOver(d, index, e)}   onMouseLeave={() => journeyMouseOut()}
                       cursor='pointer' data-pr-position={"bottom"} id={"j"+d._id} >

                        {!d?.inProgress ?
                            <rect x={d?.startPixel} y="22" width={d?.endPixel} height="20" rx="8" ry="8"
                                  fill={d?._id === journey?.details?._id ? 'var(--main-accent-colour)' : "var(--v-list-button)"}/>

                        :
                            <g>
                                <rect x={d.startPixel} y="22" width={d?.endPixel} height="20" rx="8" ry="8"
                                      fill={d?._id === journey?.details._id ? 'var(--main-accent-colour)' : "var(--v-list-button)"}/>


                                <circle cx={(d?.endPixel + d?.startPixel) - 10} cy="30" r="15" fill="white"
                                        stroke="grey" strokeWidth="2px"/>
                                <svg xmlns="http://www.w3.org/2000/svg" x={(d?.endPixel + d?.startPixel) - 19} y="6" width="20" height="50" rx="6" ry="6" fill="green" textAnchor="middle"
                                     viewBox="0 0 475 512" >
                                    <path d="M80.1 35.8 457.8 190.9C471.2 196.4 480 209.5 480 224s-8.8 27.6-22.2 33.1L80.1 412.2c-23 9.4-48.1-7.4-48.1-32.3 0-7.8 2.6-15.3 7.3-21.4L144 224 39.3 89.4C34.6 83.3 32 75.8 32 68c0-24.8 25.2-41.7 48.1-32.3z"/>
                                </svg>

                                {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" x={(d?.endPixel + d?.startPixel) - 21} y="6" width="20" height="50" rx="6" ry="6"*/}
                                {/*     fill="green" textAnchor="middle">*/}
                                {/*    <path d="M470.43 239.942c11.403-5.966 18.064-18.261 16.942-31.081s-9.817-23.771-22.082-27.667l-362.209-115.951c-14.606-4.685-30.376 1.664-37.796 15.019s-4.251 30.113 7.47 39.947L207.578 233.339 94.447 368.163c-9.835 11.72-9.982 28.698-.42 40.638s26.26 15.377 39.83 8.227l336.572-177.086z"/></svg>*/}

                            </g>
                        }

                    </g>


                    {/*<Tooltip target={".j"+d._id}>*/}
                    {/*    <div style={{display: 'flex', width: '660px', textAlign:'left', paddingLeft: '19px'}}>*/}
                    {/*        <div  style={{display: 'flex', width: '500px', flexDirection: 'column', justifyContent: 'space-between'}}>*/}
                    {/*            <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>*/}
                    {/*                Departed:&nbsp;{formatTimeOnly(new Date(j?.startTime))}*/}
                    {/*            </div>*/}

                    {/*            {j?.startAddress?.addressData &&*/}
                    {/*                <FormatAddress data={j?.startAddress?.addressData} />*/}
                    {/*            }*/}

                    {/*            {index > 0 && j?.completed !== null && j?.completed !== undefined && !fetchBlockedEndpoints().includes('view-snapshot') &&*/}
                    {/*                <div style={{width: '225px', paddingTop: '15px'}}>*/}
                    {/*                    <ImageMfl file={journeys[(index - 1)]?.endJpeg}  size="sm"/>*/}
                    {/*                </div>*/}
                    {/*            }*/}

                    {/*            {index === 0 && lastJourney?.endJpeg?.length > 1 && journeys?.length > 1 && !fetchBlockedEndpoints().includes('view-snapshot') &&*/}
                    {/*                <div style={{width: '225px', paddingTop: '15px'}}>*/}
                    {/*                    <ImageMfl file={lastJourney?.endJpeg}  size="sm"/>*/}
                    {/*                </div>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*        <div style={{display: 'flex', justifyContent: 'center',*/}
                    {/*            flexDirection: 'column', minWidth: '150px'}}>*/}

                    {/*            <div>*/}
                    {/*                <div className="arrow-long-right"/>*/}
                    {/*            </div>*/}

                    {/*            {j?.driver && j?.completed !== null && j?.completed !== undefined &&*/}
                    {/*                <div>Driver:&nbsp;{j?.driver}</div>*/}
                    {/*            }*/}

                    {/*            {j?.duration &&*/}
                    {/*                <div style={{width:'100%'}}>Duration:&nbsp;{j.duration}</div>*/}
                    {/*            }*/}

                    {/*            {j?.distance &&*/}
                    {/*                <div>Distance:&nbsp;{(j.distance).toFixed(2)}&nbsp;miles</div>*/}
                    {/*            }*/}


                    {/*        </div>*/}
                    {/*        <div  style={{display: 'flex', width: '500px', flexDirection: 'column', justifyContent: 'space-between'}}>*/}
                    {/*            {j?.completed !== null && j?.completed !== undefined ?*/}
                    {/*                <React.Fragment>*/}
                    {/*                    <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>*/}
                    {/*                        Arrived:&nbsp;{formatTimeOnly(new Date(j?.endTime))}*/}
                    {/*                    </div>*/}

                    {/*                    {j?.endAddress?.addressData &&*/}
                    {/*                        <FormatAddress data={j?.endAddress?.addressData} />*/}
                    {/*                    }*/}

                    {/*                    {index > 0 && j?.endJpeg && !fetchBlockedEndpoints().includes('view-snapshot') &&*/}
                    {/*                        <div style={{width: '225px',  paddingTop: '15px'}}>*/}
                    {/*                            <ImageMfl file={j?.endJpeg}  size="sm"/>*/}
                    {/*                        </div>*/}
                    {/*                    }*/}


                    {/*                    {index === 0 && lastJourney?.endJpeg?.length > 1 && j?.endJpeg && !fetchBlockedEndpoints().includes('view-snapshot') &&*/}
                    {/*                        <div style={{width: '225px',  paddingTop: '15px'}}>*/}
                    {/*                            <ImageMfl file={j?.endJpeg}  size="sm"/>*/}
                    {/*                        </div>*/}
                    {/*                    }*/}
                    {/*                </React.Fragment>*/}
                    {/*                :*/}
                    {/*                <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>*/}
                    {/*                    In Progress: {formatTimeOnly(new Date())}&nbsp;*/}
                    {/*                </div>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Tooltip>*/}

                </React.Fragment>


            ))}
        </svg>
    )
}

export default JourneySausages
