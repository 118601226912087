import {faBuildings, faHouse, faBoxesStacked, faBan, faGasPump, faChargingStation, faUser, faShop} from "@fortawesome/pro-regular-svg-icons";

export const catOptions = [
    {
        icon: faChargingStation,
        display: "Charging station"
    },
    {
        icon: faUser,
        display: "Customer"
    },
    {
        icon: faBoxesStacked,
        display: "Depot"
    },
    {
        icon: faGasPump,
        display: "Fuel station"
    },
    {
        icon: faBuildings,
        display: "Office"
    },

    {
        icon: faHouse,
        display: "Residence"
    },
    {
        icon: faBan,
        display: "Restricted"
    },
    {
        icon: faShop,
        display: "Supplier"
    }
]


export const getIconForPlace = (place) => {
    const lib = {
        restricted: faBan,
        supplier: faShop,
        residence: faHouse,
        office: faBuildings,
        fuel: faGasPump,
        depot: faBoxesStacked,
        charging: faChargingStation,
        customer: faUser
    }

    return lib[place];
}