import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Chart} from "primereact/chart";
import gridContext from "../../../../contexts/gridContext";
import mainContext from "../../../../contexts/mainContext";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import LoadingSpinner from "../../../loadingSpinner";
import {uCaseFirst} from "../../../../../functions/formatting/uCaseFirst";
import indexContext from "../../../../contexts/indexContext";
import {findByIndexProp, findDataByUniqueKey} from "../../../../../functions/db";

const VehicleComparator = ({handleShowComparator}) => {

    const {comparatorData, eventCounts, eventParams, tagLib} = useContext(gridContext);
    const {vehicles, disableDateChange, handleVehicle, vehicle} = useContext(mainContext);
    const {darkMode} = useContext(indexContext)

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading] = useState(true)


    useEffect(async () => {
        setLoading(true)
        await new Promise(r => setTimeout(r, 1000));
        setLoading(false)

    }, [disableDateChange, vehicle, comparatorData])



    useEffect(async () => {

        setLoading(true)



        if (comparatorData && (!vehicle || eventParams?.length > 0)) {


            const rawData = comparatorData?.[0]?.ids?.slice(0,30)

            let labelsArr = [];


            for (let i = 0; i < rawData?.length; i++) {
                let v = await findDataByUniqueKey('vehicles', rawData[i].dn);
                labelsArr.push(v?.properties?.registration)
            }

            const theChartData = {
                labels: labelsArr,
                datasets: [
                    {
                        label: 'Event counts',
                        data: rawData?.reduce((acc, curVal) => acc.concat(curVal.count), []),
                        backgroundColor: () => rawData?.map(function (v, i) {
                            return '#395a85';
                        }),
                    }
                ]
            };

            const options = {
                onClick: async (evt, element)  => {
                    if (element.length > 0) {
                        const i = element?.[0]?.index
                        const reg = theChartData?.labels[i]
                        const v2 = await findByIndexProp('vehicles', 'registration', reg)
                        handleVehicle(v2?.[0])
                    }
                },
                onHover: (evt, element) => {
                    if (element?.length === 1){
                        evt.native.target.style.cursor = 'pointer';
                    } else {
                        evt.native.target.style.cursor = 'default';
                    }
                },
                animation: {
                    duration: 0
                },
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: function(context) {

                                let label = uCaseFirst(tagLib?.[eventParams?.[0]]?.displayName) + " events: "


                                if (context.parsed.y !== null) {
                                    label += context.parsed.y
                                }
                                return label;
                            }
                        }
                    },
                    legend: {
                        labels: {
                            color: getComputedStyle(document.body).getPropertyValue('--text-color')
                        },
                        display: false
                    }
                },
                scales: {
                    x: {
                        grid: {
                            color: getComputedStyle(document.body).getPropertyValue('--surface-border')
                        },
                        ticks: {
                            color: getComputedStyle(document.body).getPropertyValue('--gray-700')
                            // color: () => filtered.map(function (v, i) {
                            //     return chartFilters?.includes(v?._id) ? getComputedStyle(document.body).getPropertyValue('--main-accent-colour') : '#395a85';
                            // }),
                            // font: () => filtered.map(function (v, i) {
                            //     return chartFilters?.includes(v?._id) ? {weight: 'bolder'} : {weight: 'normal'};
                            // })

                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: getComputedStyle(document.body).getPropertyValue('--gray-700')
                        },
                        grid: {
                            color: getComputedStyle(document.body).getPropertyValue('--surface-border')
                        }
                    }
                }
            };

            await setChartData(theChartData);
            await setChartOptions(options);
            setLoading(false);
        }


        // if (vehicle || eventParams?.length < 1){
        //     const filtered = eventCounts?.filter(tag => tag?.tagDetails?.[0]?.displayInApp && !eventParams?.includes(tag?._id));
        //
        //
        //     const theChartData = {
        //         labels: filtered.reduce((acc, curVal) => acc.concat(curVal?.tagDetails?.[0]?.displayName ? curVal?.tagDetails?.[0]?.displayName : curVal?._id    ), []),
        //         datasets: [
        //             {
        //                 label: 'Event counts',
        //                 data: filtered?.reduce((acc, curVal) => acc.concat(curVal?.tags), []),
        //                 backgroundColor: () => comparatorData?.[0]?.ids?.map(function (v, i) {
        //                     return '#395a85';
        //                 }),
        //                 maxBarThickness: 50,
        //             }
        //         ]
        //     };
        //     const options = {
        //         animation: {
        //             duration: 0
        //         },
        //         maintainAspectRatio: false,
        //         aspectRatio: 0.8,
        //         plugins: {
        //             tooltips: {
        //                 mode: 'index',
        //                 intersect: false
        //             },
        //             legend: {
        //                 labels: {
        //                     color: textColor
        //                 },
        //                 display: false
        //             }
        //         },
        //         scales: {
        //             x: {
        //                 grid: {
        //                     color: surfaceBorder
        //                 },
        //                 ticks: {
        //                     // color: () => filtered.map(function (v, i) {
        //                     //     return chartFilters?.includes(v?._id) ? getComputedStyle(document.body).getPropertyValue('--main-accent-colour') : '#395a85';
        //                     // }),
        //                     // font: () => filtered.map(function (v, i) {
        //                     //     return chartFilters?.includes(v?._id) ? {weight: 'bolder'} : {weight: 'normal'};
        //                     // })
        //
        //                 }
        //             },
        //             y: {
        //                 stacked: true,
        //                 ticks: {
        //                     color: textColorSecondary,
        //                     callback: function(value) {if (value % 1 === 0) {return value;}}
        //                 },
        //                 grid: {
        //                     color: surfaceBorder
        //                 }
        //             }
        //         }
        //     };
        //     setChartData(theChartData)
        //     setChartOptions(options)
        // }

        //took vehicle out of dependancies


    }, [comparatorData, darkMode])

    return (
        <React.Fragment>
            {!loading ?
                <Chart type="bar" data={chartData} options={chartOptions}  style={{maxHeight: '175px', padding:'0px 20px 0px 10px'}}/>
                :
                <div style={{height: '150px', width: '100%', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div>
                        <LoadingSpinner/>
                    </div>
                </div>
            }
        </React.Fragment>

    )
}

export default VehicleComparator