import React, {useContext, useEffect, useRef, useState} from 'react';
import {DataScroller} from "primereact/datascroller";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import fetchPois from "../../../../api/places/fetchPois";
import PlacesGridCard from "./placesGridCard";
import PlaceModalParent from "../../modals/placeModal/placeModalParent";
import {Toast} from "primereact/toast";
import postDeletePoi from "../../../../api/places/postDeletePoi";
import postAddPoi from "../../../../api/places/postAddPoi";
import {TOAST_DURATION} from "../../../../Constants";
import {addData, deleteData, getStoreData, initDB} from "../../../../functions/db";
import {Dropdown} from "primereact/dropdown";
import {deepCompare} from "../../../../functions/deepCompare";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPlus} from "@fortawesome/pro-regular-svg-icons"
import {Button} from "primereact/button";
import NoDataMessage from "../grid/noDataMessage";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {catOptions} from "./catOptions";
import {InputText} from "primereact/inputtext";
import {Tooltip} from "primereact/tooltip";

const Places = ({}) => {

    const gridItem = {
        border: 'solid 1px var(--surface-border)',
        borderRadius: '5px',
        padding: '10px 0 10px 0!important',
        // boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)'
    }

    const Title = {
        fontWeight: 'bold',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
    }

    const TitleContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '5px',
        borderBottom: 'solid 1px var(--surface-border)',
        borderLeft: 'none',
        height: '45px'
    }






    const {winWidth, fleets} = useContext(indexContext);
    const {vehicles, toast} = useContext(mainContext);

    const [fleet, setFleet] = useState(fleets?.length === 1 ? fleets[0] : null);
    const [gridData, setGridData] = useState([]);
    const [showPlaceModal, setShowPlaceModal] = useState(false);
    const [placeSelected, setPlaceSelected] = useState();
    const [refreshPois, setRefreshPois] = useState(true);
    const [placeFilter, setPlaceFilter] = useState();
    const [mounted, setMounted] = useState(false);
    const [searchString, setSearchString] = useState();

    const typingTimer = useRef();

    const handleRefreshPois = (val) => setRefreshPois(val);
    const handleShowPlaceModal = (val) => setShowPlaceModal(val);

    const debouncer = async (val) => {
        await setSearchString(val)
        clearTimeout(typingTimer?.current);
        typingTimer.current = setTimeout(async () => await getPois(), 500);
    }


    const openShowPlaceModal = async (val) => {
        setPlaceSelected(val)
        setShowPlaceModal(true);
    };


    const handleDeletePoi = async (val) => {
        await deleteData('places', val?._id)

        delete val._id;

        await postDeletePoi(val);
        toast.current.show({ severity: 'info', summary: 'All done', detail: 'POI successfully deleted', life: TOAST_DURATION });
        await getPois();
    }

    const getPois = async (fleet) => {

        let r = await getStoreData('places');
        r = await r?.filter(p => p?.fleetId !== 'deleted_from_poi')

        if (searchString?.length > 1) r = r?.filter(p => p?.name.toLowerCase()?.includes(searchString?.toLowerCase()) || p?.cat?.toLowerCase()?.includes(searchString?.toLowerCase()))
        if (fleet) r = r?.filter(p => p?.fleetIds?.includes(fleet.fleetId));
        if (placeFilter) r = r?.filter(p => p?.cat === placeFilter?.display);
        setGridData(r);
    }

    useEffect(async () => {

        if (!showPlaceModal && refreshPois && mounted){
            await getPois(fleet);
        }
    }, [showPlaceModal, refreshPois]);


    useEffect(async () => {
        if (mounted) await getPois(fleet);

    }, [fleet, placeFilter, mounted]);


    useEffect(async () => {
        setMounted(true)
        return () => setMounted(false)
    }, [])






    const GridRow = (data) => {

        return (
            <div style={{width:'100%'}} key={data?._id} id={data.name + '_' + data.fleetId} className="gridCard">
                <div style={{padding: '15px'}}>
                    <div className="surface-card" style={gridItem}>
                        <PlacesGridCard place={data} openShowPlaceModal={openShowPlaceModal} handleDeletePoi={handleDeletePoi}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div style={TitleContainer}>
                <div style={Title}>
                    <span style={{marginLeft: '10px'}}>Places of Interest</span>
                </div>
            </div>

            <div style={{height: '62px', borderBottom: '1px solid var(--surface-border)', display:'flex', justifyContent: 'space-between',
                alignItems: 'center', padding:'0px 10px'}}>



                <div style={{flex: 1, display: 'flex', alignItems: 'center', gap: '10px'}}>


                    {fleets?.length > 1 &&
                        <React.Fragment>

                            <Dropdown options={fleets} optionLabel={"fleetName"} value={fleet} placeholder="Fleet filter"
                                      onChange={(e) => setFleet(e.target.value)} filter showClear style={{width: "175px"}}/>
                        </React.Fragment>
                    }



                    <InputText value={searchString} onChange={(e) => debouncer(e.target.value)}
                    placeholder={"Type to filter..."} style={{width:"175px"}}/>




                    {/*<b>Category:&nbsp;</b>*/}
                    {/*<Dropdown options={catOptions} value={placeFilter}*/}
                    {/*          onChange={(e) => setPlaceFilter(e.target.value)}*/}
                    {/*          valueTemplate={(e) => optionTemplate(e, 'desktop', "Filter")}*/}
                    {/*          itemTemplate={(e) => optionTemplate(e, 'desktop', "Filter")}*/}
                    {/*          placeholder="Filter" optionLabel="display" filter showClear/>*/}

                    <div style={{display: 'flex'}}>
                        {catOptions?.map(cat => (
                            <React.Fragment>
                                <div className={"poiBadge_" + cat?.display.replace(" ", "")} style={{
                                    borderRadius: '50%',
                                    background: placeFilter === cat ? 'var(--v-list-button-selected)' : "",
                                    width: '28px',
                                    height: '28px',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    color: placeFilter === cat ? 'var(--v-list-button-text-selected)' : ""
                                }}
                                     onClick={() => {
                                         if (placeFilter === cat){
                                             setPlaceFilter()
                                         } else {
                                             setPlaceFilter(cat)
                                         }
                                     }} data-pr-position="bottom">
                                    <FontAwesomeIcon icon={cat.icon} style={{
                                        fontSize: "18px",
                                        position: 'absolute',
                                        top: "50%",
                                        left: "50%",
                                        transform: 'translate(-50%,-50%)'
                                    }}/>
                                </div>

                                <Tooltip target={".poiBadge_" + cat?.display.replace(" ", "")}>{cat?.display}</Tooltip>
                            </React.Fragment>
                        ))}
                    </div>


                </div>


                <Button onClick={() => openShowPlaceModal()} className="p-button-sm"
                        style={{background: 'var(--v-list-button-selected)', color: 'white'}}
                        label={<span><FontAwesomeIcon icon={faLocationPlus} className="p-button-label" style={{fontSize: '14px'}}/>&nbsp;Add place</span>}
                        tooltip="Add new place of interest" tooltipOptions={{position:"bottom"}}/>
            </div>




                <div style={winWidth > 800 ? {width: '100%', height: '100%'} : {width: '100%', height: '100%'}}>

                    {gridData?.length === 0  && <NoDataMessage showVehicleButton={false}/>}


                    {(gridData?.length > 0) &&
                    <DataScroller value={gridData} itemTemplate={GridRow} rows={12} inline
                                  scrollHeight={winWidth > 800 ? "77vh" : "84vh"} className="scrollerTest"  />

                    }
                </div>



            <PlaceModalParent showPlaceModal={showPlaceModal} placeSelected={placeSelected} handleShowPlaceModal={handleShowPlaceModal}
                               handleRefreshPois={handleRefreshPois} fleetId={fleet?.fleetId} />


        </React.Fragment>
    )
}

export default Places
