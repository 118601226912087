import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";
import mainContext from "../../contexts/mainContext";
import {findByIndexProp, findDataByUniqueKey} from "../../../functions/db";


const DrivingStatusButtons = () => {

    const {vehicleFilterParams, handleVehicleFilterParams} = useContext(mainContext);
    const [hoverCounts, setHoverCounts] = useState({});
    const [mounted, setMounted] = useState(false)

    let a = new Set(['driving', 'parked', 'idling']);
    let interval = useRef();



    const dStyle = {
        padding: '0.3rem 0.3rem',
        margin: '3px',
        background: 'var(--v-list-button-selected)',
        color: "var(--v-list-button-text-selected)",
        flex:1,
        border: 'none'
    }

    const dStyle2 = {
        padding: '0.3rem 0.3rem',
        margin: '3px',
        background: "var(--v-list-button)",
        color: "var(--v-list-button-text)",
        flex:1,
        border: 'none'
    }


    const toggleDrivingState = async (s) => {
        const params = {
            drivingStatus: new Set(s),
            search: vehicleFilterParams?.search,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: vehicleFilterParams?.fleetsToShow
        }
        handleVehicleFilterParams(params);
    }

    const refreshCounts = async () => {
        console.log('refreshed counts')
        let driving = await findByIndexProp('vehicles',"drivingStatus" ,"driving");
        let idling = await findByIndexProp('vehicles',"drivingStatus" ,"idling");
        let parked = await findByIndexProp('vehicles',"drivingStatus" ,"parked");

        const data = {
            driving: driving?.length,
            idling: idling?.length,
            parked: parked?.length
        }
        setHoverCounts(data)
    }

    const setMyTimer = () => {
        var toExactMinute = 60000 - (new Date().getTime() % 60000);

        setTimeout(async () => {
            interval.current = setInterval(async () => {
                await refreshCounts(true)
            }, 60000)
        }, toExactMinute)
    }


    useEffect(async () => {
        if (mounted){
            clearInterval(interval?.current);
            await refreshCounts();
            setMyTimer();
        }
    }, [mounted]);


    useEffect(async () => {
        setMounted(true);
        return () => {
            clearInterval(interval?.current)
            setMounted(false);
        }

    }, [])




    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '12px',
            paddingTop: '12px',
            alignItems: 'center',
            borderRight: 'solid 1px var(--surface-border)'
        }}>

            <Button
                style={(vehicleFilterParams?.drivingStatus.has('driving') && vehicleFilterParams?.drivingStatus.size === 1) ? dStyle : dStyle2}
                icon={(vehicleFilterParams?.drivingStatus.has('driving') && a.size === 1) ? 'pi pi-eye' : 'pi pi-eye-slash'}
                onClick={() => toggleDrivingState(['driving'])} label='Driving'
                className="drivingStatusTooltip" data-pr-position="bottom" tooltip={hoverCounts?.driving + " vehicles"}
            />





            <Button
                icon={(vehicleFilterParams?.drivingStatus.has('parked') && vehicleFilterParams?.drivingStatus.size === 2) ? 'pi pi-eye' : 'pi pi-eye-slash'}
                style={vehicleFilterParams?.drivingStatus.size === 2 ? dStyle : dStyle2}
                onClick={() => toggleDrivingState(['parked', 'idling'])} label='Parked'
                className="parkedStatusTooltip" data-pr-position="bottom" tooltip={hoverCounts?.idling + " vehicles"}
            />


            <Button icon={vehicleFilterParams?.drivingStatus.size === 3 ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    style={vehicleFilterParams?.drivingStatus.size === 3 ? dStyle : dStyle2}
                    onClick={() => toggleDrivingState(['parked', 'driving', 'idling'])} label='All'
                    className="allStatusTooltip" data-pr-position="bottom" tooltip={hoverCounts?.parked + " vehicles"}
            />



        </div>
    )
}
export default DrivingStatusButtons