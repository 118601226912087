import React, {useContext, useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import jStartFlag from "../../../../svgs/mapIcons/jStartFlag.png";
import jEndedFlag from "../../../../svgs/mapIcons/jEndedFlag.png";
import JinProgress from "../../../../svgs/jInProgressArrow.png";
import TrackModal from "./trackModal";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import {formatDateModalHeader} from "../../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX, faStreetView, faCircleInfo} from "@fortawesome/pro-regular-svg-icons";
import AddPlaceButton from "../../panelContent/grid/cardComponents/addPlaceButton";
import googleMapsLogo from "../../../../svgs/google-maps-old.svg";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";

const TrackModalParent = ({track, handleHyperlapseLine, handleSelectedPoint, handleTrack}) => {





    const {displayTrackClickModal, closeModal, journey, deviceDetails, vehicle, journeys, date, calendar, handleRecenterMap} = useContext(mainContext)
    const {winWidth} = useContext(indexContext)

    const [ trackModalHeaderType, setTrackModalHeaderType ] = useState();
    const [ trackModalHeaderTime, setTrackModalHeaderTime ] = useState();
    const [showDetails, setShowDetails] = useState(false);


    const handleShowDetails = val => setShowDetails(val)



    useEffect(() => {
        if (track && journey){
            const jEnd = journey?.details.completed ?
                journey?.details.completed && journey?.features[journey?.features.length - 1]?.properties?.time === track?.properties?.time
                : false;

            const jStart = journey?.features[0]?.properties?.time === track?.properties?.time;

            setTrackModalHeaderTime(formatDateModalHeader(track?.properties?.time ? track?.properties?.time :
                track?.features?.[0]?.properties?.time)   );
            setTrackModalHeaderType(jEnd ? "Journey End" : jStart ? "Journey Start" : "Tracking Point");
        }
    }, [track, journey])



    return (
        <Dialog visible={displayTrackClickModal} position={'right'} modal={false} className="eventModal"
                style={{width: winWidth >= 800 ? '30%' : '100%',
                    height: winWidth >= 800 ? '95%' : '100%', maxHeight:'100%', maxWidth: '100%'}}
                onHide={() => closeModal('displayTrackClickModal')}  resizable={false} closable={false}>

            <div style={{fontWeight: 'bold', fontSize: '16px', display:'flex', alignItems: 'center', paddingTop: '1.5rem'}}>

                {trackModalHeaderType === 'Journey Start' ?
                    <img src={jStartFlag} width="40px" height="40px"/>
                    :
                    <React.Fragment>
                        {trackModalHeaderType === 'Journey End' ?
                            <img src={jEndedFlag} width="40px" height="40px"/>
                            :
                            <img src={JinProgress} width="40px" height="40px" style={{transform: `rotate(${track?.properties?.head}deg)`}}/>
                        }
                    </React.Fragment>
                }


                <div>
                    {trackModalHeaderType}

                    <div style={{fontWeight: 'normal'}}>
                        {trackModalHeaderTime}
                    </div>
                </div>

                <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                    <div style={{marginTop: "-5px", cursor: 'pointer'}}>
                        <a target="_blank" style={{color: 'var(--text-color)'}}
                           href={`http://maps.google.com/maps?q=&layer=c&cbll=${track?.geometry?.coordinates[1]},${track?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>
                            <FontAwesomeIcon icon={faStreetView}/>
                        </a>
                    </div>


                    <div style={{marginTop: "-5px", cursor: 'pointer'}}>
                        <AddPlaceButton lng={track?.geometry?.coordinates[0]}
                                        lat={track?.geometry?.coordinates[1]}
                                        fleetId={vehicle?.properties?.fleetId}/>
                    </div>


                        {fetchUserLevel() === 'superuser' &&
                            <div style={{marginTop: "-5px", cursor: 'pointer'}}>

                                <FontAwesomeIcon icon={faCircleInfo} style={{cursor: 'pointer'}}
                                             onClick={() => handleShowDetails(!showDetails)}/>
                            </div>
                        }



                    <FontAwesomeIcon icon={faX} style={{cursor: 'pointer'}}
                                     onClick={() => closeModal('displayTrackClickModal')}/>

                </div>
            </div>

            <TrackModal feature={track?.features?.[0] ? track?.features?.[0] : track}
                        latLng={track?.lngLat ? track?.lngLat : track?.geometry.coordinates}
                        deviceDetails={deviceDetails}
                        closeModal={closeModal} journey={journey} handleHyperlapseLine={handleHyperlapseLine}
                        vehicle={vehicle} handleRecenterMap={handleRecenterMap} journeys={journeys} date={date}
                        calendar={calendar} handleSelectedPoint={handleSelectedPoint} handleTrack={handleTrack}
                        showDetails={showDetails} />

        </Dialog>
    )
}

export default TrackModalParent
