import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationArrow} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "primereact/tooltip";
import {lastTrackTime} from "../../../functions/formatting/lastTrackTime";
import {faSquareParking} from "@fortawesome/pro-regular-svg-icons";
import {faCompassSlash} from "@fortawesome/pro-light-svg-icons";


const TrackingIcon = ({drivingStatus, d, latestVehicle}) => {





    const driving = {
        color: 'green'
    }

    const idling = {
        color: 'orange'
    }

    const parked = {
        color: 'red'
    }

    const overRun = {
        color: 'red'
    }

    return (
        <React.Fragment>
            {drivingStatus &&
                <span style={eval(drivingStatus)}>
                          {(drivingStatus === 'driving') ?

                              <span className={"lastTrack" + d.properties.dn} data-pr-position="bottom"
                                    id={"lastTrack" + d.properties.dn}
                                    rotationnation={(latestVehicle?.properties?.lastLocation?.head - 45)}>
                                    <FontAwesomeIcon icon={faLocationArrow} size='lg'
                                                     transform={{rotate: (latestVehicle?.properties?.lastLocation?.head - 45)}}
                                    />
                                  <Tooltip target={".lastTrack" + d.properties.dn}>
                                  {lastTrackTime(latestVehicle, 'Last track was ', ' ago')}
                                  </Tooltip>
                              </span>


                              :
                              <React.Fragment>
                                  {latestVehicle?.properties?.lastLocation ?
                                      <span className={"parkedSince" + d.properties.dn} data-pr-position="bottom">
                                            <FontAwesomeIcon icon={faSquareParking} size="lg"
                                            />
                                            <Tooltip target={".parkedSince" + d.properties.dn}>
                                                {lastTrackTime(latestVehicle, 'Parked for ', '')}
                                            </Tooltip>
                                      </span>
                                      :
                                      <span className={"parkedSince" + d.properties.dn} data-pr-position="bottom">
                                            <FontAwesomeIcon icon={faCompassSlash} size="lg"
                                            />
                                            <Tooltip target={".parkedSince" + d.properties.dn}>
                                                Device missing
                                            </Tooltip>
                                      </span>
                                  }
                              </React.Fragment>

                          }
                      </span>
            }
        </React.Fragment>
    )
}

export default TrackingIcon