import React, {useContext, useEffect, useState} from 'react';
import ReportsDatePicker from "../../panelContent/reports/reportsDatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faPrint} from "@fortawesome/pro-regular-svg-icons";

import './reportsHeader.css';
import ReportTypeDropdown from "./reportTypeDropdown";
import {InputSwitch} from "primereact/inputswitch";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import {Dropdown} from "primereact/dropdown";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {fetchAdminFleets} from "../../../../functions/fetchAdminFleets";
import {getStoreData} from "../../../../functions/db";
import reportsContext from "../../../contexts/reportsContext";


const ReportsHeader = ({handleReportType, handleDateRange, dateRange, reportType, handlePrint, showKpi, handleShowKpi,
                       theFleet, theVehicle, reportTypeOptions, selectReport, handleTheVehicle, handleTheFleet}) => {


    const {generateReport, handleGenerateReport} = useContext(reportsContext);

    const Title = {
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        borderBottom:'solid 1px var(--surface-border)',
        height:'50px',
        gap: '10px'
    }

    const TitleContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px',
        borderBottom: 'solid 1px var(--surface-border)',
        borderLeft: 'none',
        height: '46px',
        background: 'var(--surface-ground)',

    }
    const buttonStyle = {
        width: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        background: 'var(--v-list-button)',
        height: '38.75px',
        marginRight: '10px',
        borderRadius: '2px',
        padding: '1rem 0.5rem',
        color: 'var(--v-list-button-text)'
    }

    const buttonStyleSelected = {
        width: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--v-list-button-selected)',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: 'var(--v-list-button-text-selected)',
        height: '38.75px',
        marginRight: '10px',
        borderRadius: '2px',
        padding: '1rem 0.5rem'
    }




    const {fleets} = useContext(indexContext);
    const [fleetsDropdown, setFleetsDropdown] = useState([]);
    const [vehicles, setVehicles] = useState();


    useEffect(async () => {
        if (fetchUserLevel() === 'fleet'){
            const fleetsAllowed = fetchAdminFleets();
            const filtered = await fleets.filter(fleet => fleetsAllowed?.includes(fleet?.fleetId));
            setFleetsDropdown(filtered)

            if (filtered?.length === 1){
                handleTheFleet(filtered?.[0])
            }
        } else {
            setFleetsDropdown(fleets)
        }
    }, [fleets])


    useEffect(() => {

        if (dateRange && reportType && ((reportType?.type?.includes('fleet') && theFleet) ||
            (reportType?.type?.includes('vehicle') && theVehicle))){
            handleGenerateReport(true)
        }

    }, [reportType, dateRange, theFleet, theVehicle]);




    useEffect(async () => {
        const v = await getStoreData('vehicles');
        setVehicles(v)
    }, []);




    return(
        <div style={{background: 'var(--surface-ground)', position:'relative', paddingBottom: '1px'}}>


            <div style={Title}>


               <div style={{display:'flex', alignItems:'center', gap: '10px',paddingLeft:'10px'}}>
                   {/*{reportType &&*/}
                   {/*    <div style={{background: "var(--v-list-button)", color: "var(--v-list-button-text)", cursor: 'pointer', alignItems: 'center', padding: '1rem 0.5rem',*/}
                   {/*        display: 'flex', justifyContent: 'center', height: '38.67px', borderRadius: '2px', marginRight: '10px'}}*/}
                   {/*         onClick={() => {*/}
                   {/*             handleReportType(null);*/}
                   {/*         }}>*/}
                   {/*        <FontAwesomeIcon icon={faChevronLeft} />*/}
                   {/*        &nbsp;Back*/}
                   {/*    </div>*/}
                   {/*}*/}


                   <div style={{marginRight:'10px',  fontWeight: 'bold', fontSize: '18px'}}>
                       {reportType ? reportType?.display : 'Reports'}
                   </div>


                   <ReportsDatePicker handleDateRange={handleDateRange} dateRange={dateRange} />



                   <Dropdown optionLabel={reportType?.type?.includes('fleet') ? "fleetName" : "properties.registration"}
                             options={reportType?.type?.includes('fleet') ? fleets : vehicles}
                             filterBy={reportType?.type?.includes('fleet') ? "fleetName" : "properties.registration"}
                             placeholder={'Select vehicle/fleet'} filter disabled={!reportType}
                             value={reportType?.type?.includes('fleet') ? theFleet : theVehicle}
                             onChange={(e) => reportType?.type?.includes('fleet') ? handleTheFleet(e.value) : handleTheVehicle(e.value)}
                            style={{height: '40px', alignItems:'center'}}/>


                   {reportType &&
                       <div style={{background: "var(--v-list-button)", color: "var(--v-list-button-text)", cursor: 'pointer', alignItems: 'center', padding: '1rem 0.5rem',
                           display: 'flex', justifyContent: 'center', height: '38.67px', borderRadius: '2px', marginRight: '10px'}}
                            onClick={() => {
                                handleReportType(null);
                            }}>
                           <FontAwesomeIcon icon={faChevronLeft} />
                           &nbsp;Back
                       </div>
                   }


               </div>







            </div>




            {/*<div style={{display: 'flex', alignItems: 'center', paddingLeft: '10px', height: '54px',*/}
            {/*    borderBottom:'solid 1px var(--surface-border)', gap:'10px'}}>*/}

                {/*Report:*/}
                {/*<Dropdown value={reportType} options={reportTypeOptions} optionLabel="display"*/}
                {/*          onChange={(e) => selectReport(e.target.value)}*/}
                {/*          placeholder={"select report"}/>*/}






                {/*{reportType?.type?.includes('fleet') &&*/}

                {/*    <React.Fragment>*/}
                {/*        {fleetsDropdown?.length > 1 ?*/}
                {/*            <React.Fragment>*/}
                {/*                Fleet:*/}
                {/*                <Dropdown options={fleetsDropdown} optionLabel="fleetName" filter filterBy="fleetName"*/}
                {/*                          placeholder="Select fleet" value={theFleet}*/}
                {/*                          onChange={(e) => {*/}
                {/*                              handleTheFleet(e.value)*/}
                {/*                          }}*/}
                {/*                />*/}
                {/*            </React.Fragment>*/}
                {/*            :*/}
                {/*            <React.Fragment>Fleet:{theFleet?.fleetName ? theFleet?.fleetName : theFleet?.fleetId}</React.Fragment>*/}


                {/*        }*/}
                {/*    </React.Fragment>*/}
                {/*}*/}




                {/*{reportType?.type?.includes('vehicle') &&*/}
                {/*    <React.Fragment>*/}
                {/*        Vehicle:*/}
                {/*        <Dropdown options={vehicles} optionLabel="properties.registration" filter*/}
                {/*                  filterBy="properties.registration"*/}

                {/*                  placeholder="Select vehicle" value={theVehicle}*/}
                {/*                  onChange={(e) => handleTheVehicle(e.value)}*/}
                {/*        />*/}
                {/*    </React.Fragment>*/}

                {/*}*/}


            {/*</div>*/}

        </div>
    )




}
export default ReportsHeader
