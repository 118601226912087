import React, {useContext, useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";

import indexContext from "../../../contexts/indexContext";
import getNotificationsToken from "../../../../api/getNotificationsToken";
import fetchNotifications from "../../../../api/notifications/fetchNotifications";
import HealthCard from "./deviceNotificationModalComponents/healthCard";
import fetchDeviceDetails from "../../../../api/device/fetchDeviceDetails";
import mainContext from "../../../contexts/mainContext";
import {faBell} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../../loadingSpinner";


const DeviceNotificationModalParent = ({showDeviceNotificationModal,handleShowDeviceNotificationModal, deviceForNotifications}) => {


    const {winWidth} = useContext(indexContext)
    const {handleDeviceDetails, openModal, displayDeviceDetailsModal} = useContext(mainContext)

    const [healthNotifications, setHealthNotifications] = useState([]);
    const [rawNotifications, setRawNotifications] = useState();
    const [notificationsLoading, setNotificationsLoading] = useState(true);


    useEffect(async () => {
        if (deviceForNotifications){

            await refreshNotifications()

            if (displayDeviceDetailsModal){
                await getDetails();
            }

        }

    }, [deviceForNotifications])

    const refreshNotifications = async () => {

        setNotificationsLoading(true)


        const data = {
            'notificationRead.email': { "$not": { "$all": [localStorage.getItem('email')] } },
            'notificationType': {"$in": ["health"]},
            fleetId : {"$in": [deviceForNotifications?.properties?.fleetId]},
            dn: deviceForNotifications?.properties?.dn
        }

        const notiToken = await getNotificationsToken({fleetIds: [deviceForNotifications?.properties?.fleetId]});
        data.notificationToken = notiToken?.key

        const r = await fetchNotifications(data);

        if (r?.length < 1){
            handleShowDeviceNotificationModal(false);
            return
        }

        setRawNotifications(r);

        let toShow = [];

        for (let i = r?.length-1; i >= 0; i--) {
            const exists = toShow?.filter(n => JSON.stringify(n?.notificationType) === JSON.stringify(r[i]?.notificationType));

            if (exists?.length < 1 || toShow?.length < 1){
                toShow.push(r[i])
            }
        }

        await new Promise(r => setTimeout(r, 1000));
        setNotificationsLoading(false);
        setHealthNotifications(toShow);
    }


    const getDetails = async () => {
        const details = await fetchDeviceDetails(deviceForNotifications);
        handleDeviceDetails(details[0])
        openModal('displayDeviceDetailsModal')
    }


    const footerContent = (
        <div style={{flex:1}}>
            <button className="p-button p-button-sm"  onClick={async () => {
                await getDetails();
            }} style={{width: '100%', justifyContent:'center', background: 'var(--v-list-button-selected)',
                color: 'var(--v-list-button-text-selected)'}}>
                <span className="p-button-label">Device settings</span>
            </button>
        </div>
    );

    return (
        <Dialog visible={showDeviceNotificationModal} position={'right'} modal={false}
                style={{width: winWidth >= 800 ? '25%' : '100%',
                    height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%', maxWidth: '100%'}}
                onHide={() => handleShowDeviceNotificationModal(false)}
                footer={footerContent}>

            <div style={{pointerEvents:'none',marginTop: '-50px', position: 'absolute', fontWeight: 'bold', fontSize: '16px', width: '250px', display:'flex', alignItems: 'center'}}>

                <div>
                    <FontAwesomeIcon icon={faBell} style={{color: 'red', fontSize: '18px'}}
                    />
                    &nbsp;Device alerts - {deviceForNotifications?.properties?.registration}
                </div>
            </div>

            {notificationsLoading ?
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', height: '100%'}}><LoadingSpinner/></div>
            :
                <div>
                    {healthNotifications?.map(n => (<HealthCard notification={n} rawNotifications={rawNotifications}
                                                                refreshNotifications={refreshNotifications}/>))}
                </div>

            }


        </Dialog>
    )
}

export default DeviceNotificationModalParent
