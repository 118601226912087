import React, {useContext, useEffect, useRef, useState} from 'react';
import {formatTimeOnly, getSecondsDifference, getTimeDifference} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import mainContext from "../../../../contexts/mainContext";

import {Chart} from "primereact/chart";
import jInProgressArrow from '../../../../../svgs/mapIcons/jInProgressArrow.svg';
import jEndedFlag from '../../../../../svgs/mapIcons/jEndedFlag.png';
import jStartFlag from '../../../../../svgs/mapIcons/jStartFlag.png';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faCaretLeft} from "@fortawesome/pro-solid-svg-icons";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";


const SpeedChartV3 = ({handleSelectedPoint, handleTrack}) => {



    const {journey} = useContext(mainContext);
    const [chartData, setChartData] = useState();
    const [showTooltip, setShowToolip] = useState(false);
    const [stoppedDataArr, setStoppedDataArr] = useState([]);



    const options = {
            interaction: {
                mode: 'nearest',
                intersect: false
            },
            scales: {
                x: {
                    display: false,
                    type: 'linear',
                    align: 'inner',
                    grace: '0',
                    ticks: {
                        min:1,
                        beginAtZero: true,
                        stepSize: 1
                    }
                },
                y: {
                    // display:false,
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                   min:0,
                    grid: {
                        display: journey ? true : false,
                        color: getComputedStyle(document.body).getPropertyValue('--gray-700')
                    },
                    border: {
                        display: false
                    }

                }
            },
            responsive: true,
            maintainAspectRatio: false,



            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                tooltip: {
                    // displayColors: false,
                    // callbacks: {
                    //     title: titleTooltip,
                    //     label: labelTooltip
                    // }
                    // Disable the on-canvas tooltip
                    enabled: false,

                    external: (context) => {

                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.style.zIndex = 10;
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = '0';
                            return;
                        }
                        // Set HTML & Data
                        if (tooltipModel.body) {

                            const track = journey.features?.[context?.tooltip?.dataPoints[0]?.dataIndex];
                            let innerHtml = `
                                    <div style="border-collapse: separate; overflow: hidden; border-radius: 3px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">
            
                                        <div style="background-color: var(--surface-card); padding-top: 5px; padding-bottom: 6px; padding-left: 7px; 
                                        font-size: 14px; border-bottom: solid 1px var(--surface-border);text-align:center">
                                           ${formatTimeOnly(track?.properties?.time)}
                                        </div>
                                        <div style="padding: 1.2rem; background-color: var(--surface-card)">
                                            <div style="display: flex; margin-right: 1.2rem;align-items: center;  ">
                                                <div style="display: flex;  flex-direction: column;">
                                                    <div>Speed: <span style="font-weight: 600">${track?.properties.speed} mph</span></div>
                                                </div>
                                            </div>
                                                
                                            
                                `;

                            if (track?.properties?.maxspeed){
                                innerHtml += `<div style="display: flex;align-items:center;margin-top: 5px">
                                                <div style="margin-right: 5px">Speed limit: </div>
                                                <div style="border-radius: 100%; border: solid 2px red; height: 22px;width:22px;text-align:center;padding-top: 2px; font-weight: bold">
                                                ${track?.properties?.maxspeed}
                                                </div>
                                            </div>
                                     `
                            }

                            if (track?.properties?.voltage){
                                innerHtml += `<div style="display: flex;align-items:center;margin-top: 5px">
                                                <div style="margin-right: 5px">Voltage:<span style="font-weight: 600">${(track?.properties?.voltage/100)} V</span> </div>
                                            </div>
                                     `
                            }

                            const stop = stoppedDataArr.filter(item => {
                                const curTime = new Date(track?.properties?.time);

                                if (curTime > new Date(item.start.properties.time) && curTime < new Date(item.end.properties.time)){
                                    return item
                                }
                                return
                            });

                            let stoppedDur;

                            if (stop?.length > 0){
                              stoppedDur = getTimeDifference(stop[0].start.properties.time, stop[0].end.properties.time);
                            }

                            if (track?.properties?.speed === 0 && stoppedDur){
                                innerHtml += `<div style="display: flex;align-items:center;margin-top: 5px">
                                                <div style="margin-right: 5px">Stopped for ${stoppedDur} </div>
                                            </div>
                                        </div>`
                            }


                            if (showTooltip){
                                tooltipEl.querySelector('table').innerHTML = innerHtml;
                                const position = context.chart.canvas.getBoundingClientRect();
                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = '1';
                                tooltipEl.style.position = 'absolute';

                                if (tooltipModel?.dataPoints[0].dataIndex > (journey.features?.length / 2)){
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 130 + 'px';
                                } else {
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 5 +  'px';
                                }

                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                tooltipEl.style.pointerEvents = 'none';
                            }
                        }
                    }
                }
            },
            onHover: function (e, item) {
                if (item.length) {
                    handleSelectedPoint(journey?.features[item[0].index]);
                }
                if (e){
                    const {
                        bottom,
                        top,
                        right,
                        left
                    } = e.chart.chartArea;

                    if (e.x >= left && e.x <= right && e.y <= bottom && e.y >= top) {
                        setShowToolip(true)
                    }
                }
            },
            onClick: function (e, activeEls) {
                if (activeEls.length) {
                    let dataIndex = activeEls[0].index;
                    handleTrack(journey.features[dataIndex])
                }
            },
            animation: false

        };




    useEffect(async () => {

            if (journey?.features?.length > 2){
                let stoppedData = [];
                let startOfStop = null;
                journey?.features.forEach((track, index) => {


                    if (track?.properties?.speed > 0){
                        if(index > 0 && journey.features[index - 1].properties.speed === 0){

                            if(startOfStop && new Date(journey.features[index - 1].properties.time).getTime() -
                                new Date(startOfStop.properties.time).getTime() > 180000){
                                stoppedData.push({
                                    start:startOfStop,
                                    end: journey.features[index - 1]
                                });
                                startOfStop = null;
                            }
                        }
                    } else {
                        if (index > 0 && journey.features[index - 1].properties.speed > 0){
                            startOfStop = track;
                        } else if (index === 0){
                            startOfStop = track;
                        }
                    }
                });

                setStoppedDataArr(stoppedData)








                const speedData = [];
                const speedLimitData = [];
                const colours = [];
                const labels = [];
                let index = 0;
                let curTime = 0;

                for (const track of journey?.features){
                    if (track?.properties?.speed > -1){
                        //
                        // if (index > 0){
                        //     curTime += getSecondsDifference(journey?.features[index-1].properties.time, track.properties.time)
                        // }
                        //

                        // if index > 0, x axis value = seconds diff first track and cur track




                        labels.push(getSecondsDifference(journey?.features[0].properties.time, track.properties.time));
                        speedData.push(track?.properties?.speed);
                        speedLimitData.push(track?.properties?.maxspeed)





                        if (track?.properties?.speed > track?.properties?.maxspeed * 1.1){
                            colours.push('red')
                        } else if (track?.properties?.speed > track?.properties?.maxspeed){
                            colours.push('yellow')
                        } else if (track?.properties?.speed <= 2) {
                            colours.push('grey')
                        } else {
                            colours.push(getComputedStyle(document.body).getPropertyValue('--main-accent-colour'))
                        }
                    }
                    index++;
                }

                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Speed',
                            data: speedData,
                            pointBackgroundColor: colours,
                            pointBorderColor: colours,
                            lineTension: 0.5,
                            pointRadius: 1.2,
                            //pointRadius: journey?.features?.length > 100 ? 1.2 : 3,
                            pointHoverRadius: 5,
                            pointHitRadius: 2,
                            pointHoverBackgroundColor: '#5C8459',
                            pointHoverBorderColor: '#5C8459',
                            segment:
                                {
                                    borderColor:
                                        (ctx) => {
                                            if (colours[ctx.p1DataIndex] === 'red'){
                                                if (colours[ctx.p0DataIndex] === 'red'){
                                                    return 'red'
                                                } else {
                                                    return colours[ctx.p0DataIndex]
                                                }
                                            } else if (colours[ctx.p1DataIndex] === 'yellow') {
                                                if (colours[ctx.p0DataIndex] === 'yellow'){
                                                    return 'yellow'
                                                } else {
                                                    return colours[ctx.p0DataIndex]
                                                }
                                            } else{
                                                return colours[ctx.p1DataIndex]
                                            }
                                        },
                                },

                        }
                        // {
                        //     label: 'Speed Limit',
                        //     data: speedLimitData
                        // }
                    ]
                }
                setChartData(data)

            }
    }, [journey])



    return (

        <div style={{height: '90px', width: '100%', borderTop: 'solid 1px var(--surface-border)',
            paddingTop: '10px', borderBottom: '1px solid var(--surface-border)', background: 'var(--surface-ground)'}}
             onMouseOut={() => {
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipEl){
                    tooltipEl.style.opacity = 0;
                }
             }}>


            <div style={{display: 'flex', alignItems: 'end', paddingLeft: '10px', paddingRight: '10px'}}>
                {journey && <img src={jStartFlag} style={{height: '35px'}}/>}



                <Chart type="line" data={chartData} options={options} style={{height: '53px', marginBottom: '5px', flex: 1, cursor: 'pointer'}}/>


                {journey &&
                    <React.Fragment>
                        {journey?.details?.completed ?
                            <img src={jEndedFlag} style={{height: '35px'}}/>
                            :
                            <React.Fragment>
                                {journey?.details?.drivingStatus === 'parked' ?
                                    <img src={jEndedFlag} style={{height: '35px'}}/>
                                    :
                                    <img src={jInProgressArrow} style={{height: '35px',
                                        transform: `rotate(90deg)` }}/>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
            <div style={{display: 'flex', alignItems: 'end', fontWeight: 'bold', fontSize: '15px', padding: '0px 45px 10px 45px'}}>
            {journey ?

                    <React.Fragment>
                        <div>
                            {formatTimeOnly(journey?.details?.start?.at)}
                        </div>
                        <div style={{flex:1, textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faCaretLeft} style={{marginRight: '5px'}}/>
                            {journey?.details?.driver &&
                                <span>{journey?.details?.driver}&nbsp;-&nbsp;</span>
                            }
                            {journey?.details?.distance.toFixed(0)} miles
                            - {journey?.details?.duration}
                            <FontAwesomeIcon icon={faCaretRight} style={{marginLeft: '5px'}} />
                        </div>
                        <div>
                            {formatTimeOnly(journey?.features[journey?.features?.length -1]?.properties?.time)}
                        </div>
                    </React.Fragment>

            :
                <div>&nbsp;</div>

            }
            </div>
        </div>
    )
}

export default SpeedChartV3
