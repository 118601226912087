import React, {useContext, useEffect, useRef, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {fetchActivityData} from "../activityReport/fetchActivityData";
import {formatDateOnly, formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";
import LoadingSpinner from "../../../loadingSpinner";
import VehicleCo2ReportSummary from "./legacy/vehicle/vehicleCo2ReportSummary";
import VehicleCo2ReportTable from "./legacy/vehicle/vehicleCo2ReportTable";
import FleetCo2ReportTable from "./legacy/fleet/FleetCo2ReportTable";
import FleetCo2ReportSummary from "./legacy/fleet/fleetCo2ReportSummary";
import DeviceDetailsModalParent from "../../../modals/deviceDetailsModal/deviceDetailsModalParent";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import indexContext from "../../../../contexts/indexContext";
import ReportsParameterSelection from "../reportsParameterSelection";
import reportsContext from "../../../../contexts/reportsContext";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import {fetchCo2ReportData} from "./fetchCo2ReportData";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {co2ReportPdf} from "./co2ReportPdf";
import {gToKg} from "../../../../../functions/formatting/gToKg";



const CO2Report = ({}) => {

    const {theVehicle, theFleet, dateRange, reportLoading, handleReportLoading, generateReport} = useContext(reportsContext);

    const [activityData, setActivityData] = useState(null);
    const [kpi, setKpi] = useState(null);
    const [showReport, setShowReport] = useState(false);
    const deviceDetails = useRef();


    const generateReportData = () => {

        if (theVehicle){
            fetchCo2ReportData(dateRange, theVehicle, deviceDetails?.current).then(r => {
                setActivityData(r);

                let distance = 0;
                let co2 = 0;
                let journeys = 0;


                r?.forEach(v => {
                    journeys += v.journeys;
                    distance += v?.distance;
                    co2 += (v?.co2);
                })


                let total = {
                    journeys,
                    distance,
                    co2
                }
                setKpi(total);

                const cache = {
                    actData: r,
                    totals: total,
                    dateRange: JSON.stringify(dateRange)
                }

                if (theVehicle){
                    cache.dn = theVehicle?.properties.dn
                } else {
                    cache.fleet = theFleet
                }



                localStorage.setItem('co2Report', JSON.stringify(cache));
                handleReportLoading(false);
            });
            setShowReport(true)
        } else {
            setShowReport(false)
        }

    }


    const exportPdf = () => {
        co2ReportPdf(activityData, kpi, theVehicle);
    }

    const exportCSV = () => {
        // dt.current.exportCSV({ selectionOnly });
        const exportData = activityData.map((row) => ({
            date: row?.date,
            journeys: row?.journeys,
            startTime: row.startTime,
            startPlace: row?.startPlace,
            endTime: row?.endTime,
            endPlace: row?.endPlace,
            distance: row?.totalDistance,
            co2: row?.co2Emissions
        }));

        // Convert JSON to CSV string
        const csvRows = [];
        const headers = Object.keys(exportData[0]);
        csvRows.push(headers.join(","));

        exportData.forEach((row) => {
            const values = headers.map((header) => {
                const val = row[header] !== null && row[header] !== undefined ? row[header] : "";
                return `"${val}"`;
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `co2 report ${theVehicle?.properties?.registration} ${activityData?.[0].date}-${activityData?.[activityData?.length-1].date}.csv`;
        link.click();
        window.URL.revokeObjectURL(url);

    };

    useEffect(async () => {

        if (localStorage.getItem('co2Report')){
            handleReportLoading(false);
            setShowReport(true)
            const cache = JSON.parse(localStorage.getItem('co2Report'));

            if (cache.dateRange === JSON.stringify(dateRange) && theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn){
                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setKpi(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);

                    }
                }
            } else {
                if (generateReport) {
                    let details = await fetchDeviceDetails((theVehicle))

                    if (!details[0]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions) {
                        setShowReport(false);
                    } else {
                        handleReportLoading(true);
                        deviceDetails.current = details[0]
                        generateReportData(details);
                    }
                }
            }
        } else {
            if(dateRange && theVehicle && generateReport){
                let details = await fetchDeviceDetails((theVehicle))

                deviceDetails.current = details[0]

                if (!details[0]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions) {
                    setShowReport(false);
                } else {
                    handleReportLoading(true);
                    generateReportData(details);
                }
            } else {
                setShowReport(true)
            }
        }

    }, [dateRange, theVehicle, theFleet, generateReport]);



    const header = (
        <div style={{
            color: 'var(--text-color)',
            fontSize: '1.17em',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{paddingLeft:'5px'}}>
                co2  report for {theVehicle?.properties?.registration}<br/>
                {formatDateTimesheetReport(dateRange?.[0])} - {formatDateTimesheetReport(dateRange?.[1])}
            </div>
            <div>
                <div><b>Total journeys:</b>&nbsp;{kpi?.journeys}</div>
                <div><b>Total distance:</b>&nbsp;{kpi?.distance.toFixed(0)} mi</div>
            </div>

            <div>
                <div><b>Total co2:</b>&nbsp;{gToKg(kpi?.co2)}</div>
                <div><b>Avg co2:</b>&nbsp;{gToKg(kpi?.co2/kpi?.journeys)}</div>
            </div>

            <div style={{display: 'flex', gap: '5px'}}>
                <Button type="button" icon="pi pi-file" rounded onClick={exportCSV} data-pr-tooltip="CSV"/>
                {/*<Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />*/}

                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>

        </div>
    );


    return (
        <React.Fragment>
            {showReport ?
                <React.Fragment>
                    {generateReport &&
                        <React.Fragment>
                            {reportLoading ?
                                // <ProgressSpinner />
                                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                    <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                            'translate(-50%, -50%)'}}>
                                        <LoadingSpinner />
                                    </div>
                                </div>
                            :
                                <DataTable value={activityData} header={header}
                                           scrollable scrollHeight='calc(100vh - 220px)' stripedRows>
                                    <Column field="date" header="Date" style={{paddingLeft:'18px'}} align={'left'}></Column>
                                    <Column field="startTime" header="Start time"></Column>
                                    <Column field="startPlace" header="Start place"></Column>
                                    <Column field="endTime" header="End time"></Column>
                                    <Column field="endPlace" header="End place"></Column>
                                    <Column field="journeys" header="Journeys" align={"right"}></Column>
                                    <Column field="totalDistance" header="Distance" align={"right"}></Column>
                                    <Column field="co2Emissions" header="Co2" align={"right"} style={{paddingRight:'20px'}}></Column>
                                </DataTable>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            :
                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                            'translate(-50%, -50%)', textAlign: 'center'}}>
                       There is no Co2 data currently for the vehicle selected. Please open the diagnsotics
                        and settings window for this vehicle, and this data can be entered under the vehicle tab<br/><br/>
                        {/*<button style={{marginTop: '10px'}} className="p-button p-button-sm" onClick={() => openModal('displayDeviceDetailsModal')}>Diagnostics and settings</button>*/}
                    </div>
                </div>
            }

            {/*<DeviceDetailsModalParent />*/}

        </React.Fragment>
    )
}
export default CO2Report

