import React, {useContext, useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import indexContext from "../../../contexts/indexContext";
import PlaceModal from "./placeModal";
import {deepCompare} from "../../../../functions/deepCompare";
import postDeletePoi from "../../../../api/places/postDeletePoi";
import postAddPoi from "../../../../api/places/postAddPoi";
import {TOAST_DURATION} from "../../../../Constants";
import mainContext from "../../../contexts/mainContext";
import {addData, updateData} from "../../../../functions/db";
import postUpdatePoi from "../../../../api/places/postUpdatePoi";
import {catOptions} from "../../panelContent/places/catOptions";
import fetchReverseGeocode from "../../../../api/fetchReverseGeocode";
import {applySpacing} from "../../../../functions/formatting/applySpacing";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";

const PlaceModalParent = ({placeSelected, showPlaceModal, handleShowPlaceModal, handleRefreshPois, fleetId}) => {

    const {winWidth,fleets, refreshPois} = useContext(indexContext);
    const {toast} = useContext(mainContext);
    const [location, setLocation] = useState();

    const LocationStr = () => {
        return (
            <React.Fragment>
                {location &&
                    <React.Fragment>
                        {applySpacing(location?.road)}
                        {location?.village && <React.Fragment>{applySpacing(location?.village)}</React.Fragment>}
                        {location?.town && <React.Fragment>{applySpacing(location?.town)}</React.Fragment>}
                        {location?.city && <React.Fragment>{applySpacing(location?.city)}</React.Fragment>}
                        {location?.hamlet && <React.Fragment>{applySpacing(location?.hamlet)}</React.Fragment>}
                        {location?.postcode}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }



    useEffect(async () => {
        if (placeSelected){
            const loc = await fetchReverseGeocode(placeSelected?.location?.coordinates[1], placeSelected?.location?.coordinates[0])
            setLocation(loc?.features?.[0]?.properties?.address);
        }
    }, [placeSelected])

    const saveChanges = async (val, ogVal) => {

        delete val.location;

        if (ogVal?._id){
            if (!val.fleetIds || typeof val?.fleetIds?.[0] == 'undefined') {
                toast.current.show({ severity: 'warn', summary: 'Not updated', detail: 'No fleet selected', life: TOAST_DURATION });
                return
            }


            if (!deepCompare(val, ogVal ) && ogVal?._id){
                await postUpdatePoi(val);

                val.location ={
                    type: "Point",
                    coordinates: [
                        val?.lng,
                        val?.lat
                    ]
                }

                delete val.lng
                delete val.lat

                await updateData('places', ogVal?._id, val)

                handleRefreshPois(true);
                handleShowPlaceModal(false);
                toast.current.show({ severity: 'success', summary: 'All done', detail: 'POI successfully updated',
                    life: TOAST_DURATION });
            } else {
                handleShowPlaceModal(false);
                toast.current.show({ severity: 'info', summary: 'Not updated', detail: 'No changes to be made', life: TOAST_DURATION });
            }
        } else {
            const data = await postAddPoi(val);
            await refreshPois();

            if (data?.mod === 'added'){
                toast.current.show({ severity: 'success', summary: 'All done', detail: 'POI successfully added', life: TOAST_DURATION });
            } else {
                toast.current.show({ severity: 'info', summary: 'Not updated', detail: 'No changes to be made', life: TOAST_DURATION });
            }

            handleRefreshPois(true);
            handleShowPlaceModal(false);

        }
    }


    return (
        <Dialog visible={showPlaceModal} style={{width: winWidth >= 800 ? '30vw' : '100vw',
            height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%'}} position={"right"}
                onHide={() => {
                    handleRefreshPois(false);
                    handleShowPlaceModal(false)
                }} >
            <div style={{marginTop: '-50px', position: 'absolute', fontWeight: 'bold', pointerEvents:'none',
                fontSize: '16px', width: winWidth >= 800 ? '30vw' : '95vw', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>
                {placeSelected?._id ?
                    `Editing ${placeSelected?.name}`
                :
                    `Adding place of interest`
                }
                <div style={{fontWeight:'normal', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', width:'80%'}}>
                    <LocationStr/>
                </div>
            </div>



            {/*<VideoPlayer video={videoToShow} />*/}

            <div>
                <PlaceModal placeSelected={placeSelected} saveChanges={saveChanges} handleRefreshPois={handleRefreshPois}
                            fleetId={fleetId} handleShowPlaceModal={handleShowPlaceModal}/>
            </div>
        </Dialog>
    )
}
export default PlaceModalParent
