let request;
let db;
let version = 11;


const Stores = {
    vehicles: 'vehicles',
    fleets: 'fleets',
    preferences: 'preferences',
    eventTags: 'eventTags',
    pois: 'places'
}


export const initDB = () => {
    return new Promise((resolve) => {
        request = indexedDB.open('mflAppDB', version);

        request.onupgradeneeded = (e) => {
            db = e.target.result;

            if (!db?.objectStoreNames?.contains(Stores?.vehicles)) {
                console.log('Creating vehicles store');
                const store = db.createObjectStore(Stores.vehicles, { keyPath: 'properties.dn' });
                store.createIndex("fleetId", "properties.fleetId", { unique: false });
                store.createIndex("dn", "properties.dn", { unique: true });
                store.createIndex("registration", "properties.registration", { unique: false });
                store.createIndex("drivingStatus", "properties.drivingStatus", { unique: false });
            }

            if (!db?.objectStoreNames?.contains(Stores?.eventTags)) {
                const eventTags= db.createObjectStore(Stores.eventTags, { keyPath: 'tag' });
                eventTags.createIndex('tag', 'tag', { unique: true });
            }

            if (!db?.objectStoreNames?.contains(Stores?.pois)) {
                const places = db.createObjectStore(Stores.pois, { keyPath: '_id' });
                places.createIndex('name', 'name', { unique: true });
                places.createIndex('cat', 'cat', { unique: false });
            }
        };

        request.onsuccess = (e) => {
            db = e.target.result;
            // get current version and store it
            version = db.version;
            resolve(db);
        };

        request.onerror = (e) => {
            resolve(false);
        };
    });
};

export const addData = (storeName, data) => {
    return new Promise((resolve) => {


        request = indexedDB.open('mflAppDB', version);

        request.onsuccess = (e) => {
            db = e.target.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            store.add(data);
            resolve(data);
        };

        request.onerror = () => {
            const error = request.error?.message
            if (error) {
                resolve(error);
            } else {
                resolve('Unknown error');
            }
        };
    });
};

export const deleteData = (storeName, key) => {
    return new Promise((resolve) => {
        request = indexedDB.open('mflAppDB', version);

        request.onsuccess = () => {
            console.log('request.onsuccess - deleteData', key);
            db = request.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            const res = store.delete(key);
            res.onsuccess = () => {
                resolve(true);
            };
            res.onerror = () => {
                resolve(false);
            }
        };
    });
};



export const findDataByUniqueKey = (storeName, key) => {
    return new Promise((resolve) => {
        request = indexedDB.open('mflAppDB', version);

        request.onsuccess = (e) => {
            db = e?.target?.result;
            const tx = db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            const res = store.get(key);
            res.onsuccess = (e) => {
                resolve(e?.target?.result)
            };
            res.onerror = () => {
                resolve(false);
            }
        };
    });
};

export const findByIndexProp = (storeName, propKey, value) => {
    return new Promise(resolve => {

        const output = [];

        request = indexedDB.open('mflAppDB', version);

        request.onsuccess = (e) => {
            const db = e?.target?.result;
            const tx = db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            const index = store.index(propKey);
            const range = IDBKeyRange.only(value);
            const request = index.openCursor(range);
            request.onsuccess = function(e) {
                const cursor = e?.target?.result;

                if (cursor){
                    output.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(output)
                }
            };
        };


    });




}


export const updateData = (storeName, key, data) => {
    return new Promise((resolve) => {
        request = indexedDB.open('mflAppDB', version);

        request.onsuccess = (e) => {
            db = e?.target?.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            const res = store.get(key);
            res.onsuccess = () => {
                const newData = { ...res.result, ...data };
                store.put(newData);
                resolve(newData);
            };
            res.onerror = () => {
                resolve(null);
            }
        };
    });
};

export const getStoreData = (storeName) => {
    return new Promise((resolve) => {
        request = indexedDB.open('mflAppDB');

        request.onsuccess = (e) => {
            // console.log('request.onsuccess - getAllData');
            db = e.target.result;

            const tx = db?.transaction(storeName, 'readonly');
            const store = tx?.objectStore(storeName);
            const res = store?.getAll();
            res.onsuccess = () => {
                resolve(res.result);
            };
        };
    });
};

export const dropTable = (storeName) => {
    return new Promise((resolve) => {
        request = indexedDB.open('mflAppDB');

        request.onsuccess = (e) => {
            // console.log('request.onsuccess - getAllData');
            db = e.target.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            const res = store?.clear();
            res.onsuccess = () => {
                resolve(res.result);
            };
        };
    });
};