import React, {useContext, useRef, useState} from 'react';
import {ConfirmPopup} from "primereact/confirmpopup";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPlus, faTrash} from "@fortawesome/pro-regular-svg-icons";
import postAddPoi from "../../../../../api/places/postAddPoi";
import {TOAST_DURATION} from "../../../../../Constants";
import {Dropdown} from "primereact/dropdown";
import {catOptions} from "../../places/catOptions";
import {optionTemplate} from "../../../../../functions/templates/optionTemplate";
import mainContext from "../../../../contexts/mainContext";
import PlaceModalParent from "../../../modals/placeModal/placeModalParent";

const AddPlaceButton = ({lat, lng, vehicle, fleetId}) => {

    const {toast} = useContext(mainContext)

    const [visible, setVisible] = useState(false);
    const [name, setName] = useState('');
    const [cat, setCat] = useState();
    const id = useRef(Math.random())

    const handleVisible = val => setVisible(val);

    const popupBody = () => {
        return (
            <div>
                <div style={{marginBottom: '5px',fontWeight: 'bold', fontSize: '14px'}}>Add Place of Interest</div>
                <InputText value={name} onChange={(e) => setName(e.target.value)} placeholder="Place name"/>
                <br />
                Category:
                <Dropdown id="category" options={catOptions} value={cat}
                          onChange={(e) => setCat(e.target.value)}
                          valueTemplate={(e) => optionTemplate(e, 'desktop')}
                          itemTemplate={(e) => optionTemplate(e, 'desktop')}
                          placeholder="Category" optionLabel="display"/>
            </div>
        )
    }


    return (
        <React.Fragment>
            <span id={id?.current} onClick={(e) => {
                setVisible(true)
            }}>
                 <FontAwesomeIcon icon={faLocationPlus} />
            </span>

            <PlaceModalParent placeSelected={{location: {coordinates: [lng, lat]}, radius: 200, fleetId}} showPlaceModal={visible} handleShowPlaceModal={handleVisible}
            handleRefreshPois={() => {}} fleetId={fleetId}/>



            {/*<ConfirmPopup target={document.getElementById(id?.current)} visible={visible}*/}
            {/*              onHide={() => setVisible(false)}*/}
            {/*              icon={<FontAwesomeIcon icon={faLocationPlus} style={{fontSize: "18px"}}/>}*/}
            {/*              message={popupBody}*/}
            {/*              accept={addPoi}*/}
            {/*              reject={() => {*/}
            {/*                  setName('')}*/}
            {/*              } />*/}

        </React.Fragment>
    )
}

export default AddPlaceButton
