import {
    DurationFromSeconds,
    formatDateOnly,
    formatTimeOnly, fullDurationFromSeconds,
    getDaysDifference,
    getSecondsDifference
} from "../../../../../functions/formatting/formatDate";
import fetchJourneys from "../../../../../api/journeys/fetchJourneys";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import fetchJourney from "../../../../../api/journeys/fetchJourney";
import {formatReportAddress} from "../formatReportAddress";



export const fetchIdlingReportData = async (dateRange, vehicle) => {

    if (dateRange){
        let loopIterations = getDaysDifference(dateRange[0], dateRange[1]);

        if (loopIterations === 1) loopIterations = 0

        let activity = [];

        for (let i = 0; i <= loopIterations; i++) {

            const  startDate = new Date(dateRange[0]);
            let theDay =  new Date(new Date(dateRange[0]).setDate(startDate.getDate() + i));

            const dateString = [
                theDay.getFullYear(),
                ('0' + (theDay.getMonth() + 1)).slice(-2),
                ('0' + theDay.getDate()).slice(-2)
            ].join('-');

            if (vehicle){
                const journeysRaw = await fetchJourneys(vehicle?.properties?.dn, dateString);
                const journeys = journeysRaw.filter(j => j.endJpeg)

                if(journeys?.length > 0){
                    for (let i = 0; i < journeys?.length; i++) {

                        const j = journeys?.[i];

                        if (j?.duration){
                            const arr = j?.duration?.split(':');
                            j.duration = (parseInt(arr[0])* 3600) + (parseInt(arr[1]) * 60) + (parseInt(arr[2]));
                            j.durationFull = fullDurationFromSeconds(j.duration);

                        }

                        j.date =  formatDateOnly(j.startTime);
                        j.stopTimeFull = j.stopTime > 60 ? DurationFromSeconds(j?.stopTime) : '0m';
                        j.startPlace = formatReportAddress(j,'start');
                        j.endPlace = formatReportAddress(j,'end');
                        j.date = formatDateOnly(j.startTime)
                        j.startTime = formatTimeOnly(j?.startTime)
                        j.endTime = formatTimeOnly(j?.endTime);
                        j.distanceMiles = j.distance.toFixed(0) + ' mi';
                        j.journeyNo = i+1;
                        j.idling = fullDurationFromSeconds(j?.trueIdling)
                    };

                }
                activity = activity?.concat(journeys)
            }
        }
        return activity
    }

}
