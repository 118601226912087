import React, {useContext, useEffect, useState} from 'react';
import {Button} from "primereact/button";
import DisableDateChangeNext from "./disableDateChangeNext";
import {Calendar} from "primereact/calendar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft} from '@fortawesome/pro-regular-svg-icons';
import indexContext from "../../../contexts/indexContext";
import Overlay from "../../overlay";
import {faBan} from "@fortawesome/pro-light-svg-icons";
import mainContext from "../../../contexts/mainContext";
import {isWebfleet} from "../../../../functions/isWebfleet";
import {formatDateOnly} from "../../../../functions/formatting/formatDate";

const  CalendarDatePicker = ({handleDate, date, disableDateChange, onlyInterestingEvents = false,
                                 handleLastDaysOfEvents, lastDaysOfEvents, dateSortEvents, scoreSortEvents}) => {

    const {winWidth, pageToShow} = useContext(indexContext);
    const {vehicle, calendar} = useContext(mainContext);

    const [ calendarText, setCalendarText ] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [minDate, setMindate] = useState();
    const [disabledDates, setDisabledDates] = useState();
    const [availableDates, setAvailableDates] = useState();




    const handleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleVisibleChange = (e) => {
        handleVisibility(e)
    };

    const setDate = () => {
        const today = new Date();
        const selected = new Date(date);


        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)

        let date1 = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
        let date2 = selected.getFullYear() + '-' + selected.getMonth() + '-' + selected.getDate();
        let date3 = yesterday.getFullYear() + '-' + yesterday.getMonth() + '-' + yesterday.getDate();

        if (date1 === date2){
            setCalendarText(`Today`)
        } else if (date3 === date2){
            setCalendarText(`Yesterday`)
        } else if (!date) {
            setCalendarText(`Last ${lastDaysOfEvents} Days`);
        } else {
            setCalendarText(`${('0' + selected.getDate()).slice(-2)}/${('0' + (selected.getMonth()+1)).slice(-2)}/${selected.getFullYear()}`)
        }
    }

    const handlePrevDate = () => {

        if (isWebfleet() && pageToShow === '/map'){
            if (!date){
                handleDate(availableDates[availableDates?.length -1])
            } else {
                const index = [...availableDates].indexOf(formatDateOnly(date))
                if ((index + 1) < availableDates.size){
                    handleDate(new Date([...availableDates][index + 1].split('-').reverse().join('-')))
                } else {
                    handleDate(new Date([...availableDates][[...availableDates]?.length - 1].split('-').reverse().join('-')))
                }
            }
        } else {
            if (!date){
                const yesterday = new Date()
                yesterday.setDate(yesterday.getDate() - 1)
                handleDate(yesterday)
            } else {
                handleDate(date, -1)
            }
        }
    }



    const prevDateRange = (val) => {
        handleDate(null);
        handleLastDaysOfEvents(val)
    }





    useEffect(() => {

        if (calendar && isWebfleet() && pageToShow === '/map'){
            setMindate(new Date(calendar?.modRecordingHistory[calendar?.modRecordingHistory?.length -1].st))
        } else {
            const today = new Date();
            today.setMonth(today.getMonth() -3)
            setMindate(today);
        }
        setDate();

    }, [date, onlyInterestingEvents, calendar, pageToShow, lastDaysOfEvents, dateSortEvents, scoreSortEvents]);



    useEffect(() => {

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        function getDates(startDate, stopDate) {
            var dateArray = new Array();
            var currentDate = startDate;
            while (currentDate <= stopDate) {
                dateArray.push(new Date (currentDate));
                currentDate = currentDate.addDays(1);
            }
            return dateArray;
        }

        if (calendar && vehicle && pageToShow === '/map'){

            if (isWebfleet()){
                const disabled = [];
                const toLoop = getDates(minDate, new Date());
                const datesAvail =  new Set(calendar?.modRecordingHistory.reduce((acc, curVal) => acc.concat(formatDateOnly(curVal?.st)), []));

                toLoop.forEach(date => {
                    if (!datesAvail.has(formatDateOnly(date))){
                        disabled.push(new Date(date))
                    }

                });
                setAvailableDates(datesAvail)
                setDisabledDates(disabled)
            }
        }

    }, [calendar, vehicle, pageToShow])



    const CalendarFooter = () => {

        const today = new Date();

        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)


        return (
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '5px'}}>

                {pageToShow === '/map' &&
                    <React.Fragment>
                        <button onClick={() => handleDate(yesterday)} className="p-button p-button-secondary p-button-sm">
                            Yesterday
                        </button>


                        <button onClick={() => handleDate(today)} className="p-button p-button-secondary p-button-sm">
                            Today
                        </button>
                    </React.Fragment>
                }


                {pageToShow === '/events' &&
                    <React.Fragment>
                        <button onClick={() => prevDateRange(7)}
                                className="p-button p-button-secondary p-button-sm"
                                style={{flex: 1, textAlign: 'center'}}>
                            <span className="p-button-label">Last 7 Days</span>
                        </button>
                        <button onClick={() => prevDateRange(30)}
                                className="p-button p-button-secondary p-button-sm"
                                style={{flex: 1, textAlign: 'center'}}>
                            <span className="p-button-label">Last 30 Days</span>
                        </button>
                        <button onClick={() => prevDateRange(90)}
                                className="p-button p-button-secondary p-button-sm"
                                style={{flex: 1, textAlign: 'center'}}>
                            <span className="p-button-label">Last 90 Days</span>
                        </button>
                    </React.Fragment>
                }
            </div>
        )
    }



    return (
        <React.Fragment>


            {winWidth > 800 ?
                <div className="p-inputgroup" style={{width: '275px', position: 'relative', height: '40px'}}>

                    {disableDateChange ?
                        <div style={{color: "var(--v-list-button-text)", backgroundColor: "var(--v-list-button)", border: 'none', marginLeft: '-1px',
                            width: '45px', borderRadius: '2px', position: 'relative',}}>
                            <FontAwesomeIcon icon={faBan} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
                        </div>
                    :
                        <div style={{color: "var(--v-list-button-text)", backgroundColor: "var(--v-list-button)", border: 'none', marginLeft: '-1px', width: '45px', borderRadius: '2px', position: 'relative',
                            cursor: 'pointer'}} onClick={handlePrevDate}>
                            <FontAwesomeIcon icon={faChevronLeft} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
                        </div>

                    }






                        {/*last rec/3 months for webfleet*/}
                        {/*webfleet users limited to days that are present on calendar*/}

                    <Calendar showIcon dateFormat="dd/mm/yy" value={date} onChange={(e) => handleDate(e.value)}
                              className="datePicker" disabled={disableDateChange} style={{height: '40px', border: 'none'}}
                              maxDate={new Date()} readOnlyInput visible={isVisible} onVisibleChange={handleVisibleChange}
                              minDate={minDate} id="theCalendar" footerTemplate={CalendarFooter} disabledDates={disabledDates}
                    />
                    {/*(disableDateChange || onlyInterestingEvents)*/}
                    <DisableDateChangeNext date={date} handleDate={handleDate} disableDateChange={(disableDateChange || !date)} availableDates={availableDates}/>


                    {winWidth > 800 &&

                        // <label htmlFor="theCalendar" style={{background: 'transparent', color: 'var(--text-color)'}}>
                        //     {calendarText}
                        // </label>
                        <div className={"p-inputtext"} style={{position: 'absolute', left: '45px', top: '1px', width: '53%',
                            height: '38px', lineHeight: '25px', paddingLeft: '5px', pointerEvents: 'none', border: 'none',
                            zIndex: 1100, color: 'var(--text-color)'}}
                              onClick={(e) => e.stopPropagation()}
                        >
                            {calendarText}
                        </div>
                    }
                </div>
            :
                <React.Fragment>
                    {/*hack to stop click through to cardComponents while picker is open*/}
                    {isVisible &&
                        <Overlay />
                    }
                    <Calendar showIcon dateFormat="dd/mm/yy" value={date} onChange={(e) => handleDate(e.value)}
                              className="datePicker" disabled={disableDateChange} minDate={minDate} disabledDates={disabledDates}
                              style={{marginLeft: '-1px', height: '40px'}} id="theCalendar2"
                              maxDate={new Date()} readOnlyInput visible={isVisible} onVisibleChange={handleVisibleChange}
                              touchUI />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default CalendarDatePicker
