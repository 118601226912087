import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIconForPlace} from "../panelContent/places/catOptions";
import VehicleLiveLocationV2 from "./vehicleLiveLocationV2";

const DisplayPoiOrLocation = ({latestVehicle}) => {





    return (
        <React.Fragment>
            {(latestVehicle?.properties?.poi && latestVehicle?.properties?.drivingStatus !== 'driving') ?
                <span>
                    <FontAwesomeIcon icon={getIconForPlace(latestVehicle.properties?.poi?.cat?.toLowerCase()?.split(' ')?.[0])}/>
                    &nbsp;{latestVehicle.properties?.poi?.name}
                </span>
            :
                <VehicleLiveLocationV2 d={latestVehicle?.properties} icon={false} isVisible={true}/>
            }
        </React.Fragment>
    )
}

export default DisplayPoiOrLocation