import React, {useContext, useEffect, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import ReactDOM from "react-dom";
import StoppedMarker from "./mapMarkers/stoppedMarker";
import HyperlapseMarker from "./mapMarkers/hyperlapseMarker";
import ViewTrackMarker from "./mapMarkers/viewTrackMarker";
import VehicleDetail from "../../abovePanelComponents/vehicle/VehicleDetail";
import ListJourneys from "../../abovePanelComponents/journeys/ListJourneys";
import fetchLastJourney from "../../../../api/journeys/fetchLastJourney";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {fetchBlockedEndpoints} from "../../../../functions/fetchBlockedEndpoints";
import SpeedChartV3 from "./mapComponents/speedChartV3";
import {faGaugeMax, faClockRotateLeft} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TrackModalParent from "../../modals/trackModal/trackModalParent";
import usePersistState from "../../../../functions/localStorage/persistState";
import EventModalParent from "../../modals/eventModal/eventModalParent";
import DeviceDetailsModalParent from "../../modals/deviceDetailsModal/deviceDetailsModalParent";
import {fetchEventsForJourney} from "./mapFunctions/fetchEventsForJourney";
import {Toast} from "primereact/toast";
import {filterVehicleList, preFilterVehicleList} from "../../../../functions/vListFilterFunc";
import DaySummary from "./mapComponents/daySummary";
import ReactMapControls from "./mapComponents/reactMapControls";
import {addThemeIcons, getMapIcons} from "./mapFunctions/getMapIcons";
import {getDrivingStatus} from "../../../../functions/drivingStatus";
import {logMemoryUsage} from "../../../../functions/localStorage/logMemoryUsage";
import ContextMenu from "./mapComponents/contextMenu";
import ClusterList from "./mapComponents/clusterList";
import {fetchPoisForMap} from "./mapFunctions/fetchPoisForMap";
import fetchJourney from "../../../../api/journeys/fetchJourney";


const styles = {
    flex: "1",
    position: "relative",
    marginTop: '-65px',
    // marginBottom: '-27px'
};

const el = document.createElement('div');
const marker = new mapboxgl.Marker(el);

const elStart = document.createElement('div');

const markerStart = new mapboxgl.Marker(elStart);


const elStopped = document.createElement('div');
const markerStopped = new mapboxgl.Marker(elStopped);

const hlStart = document.createElement('div');
const startHlMarker = new mapboxgl.Marker(hlStart)
const hlEnd = document.createElement('div');
// const endHlMarker = new mapboxgl.Marker(hlEnd)

ReactDOM.render(<HyperlapseMarker color="green"/>, hlStart);
ReactDOM.render(<HyperlapseMarker color="red"/>, hlEnd);


const pointSelected = document.createElement('div');
const pointSelectedMarker = new mapboxgl.Marker(pointSelected)
ReactDOM.render(<ViewTrackMarker />, pointSelected);

const Map2 = ({}) => {


    const {vehicle, journey, event, zoom, handleVehicle,
        handleEvent, sideBarWidthPx, openModal, closeModal,date, pageToShow, handleTrueLocation,
        lastJourney, displayTrackClickModal, vehicleFilterParams, journeys, dbReady, vehiclesRef} = useContext(mainContext);

    const {winWidth, darkMap} = useContext(indexContext);

    const [map, setMap] = useState(null);


    const [mounted, setMounted] = useState(false)
    const mapContainer = useRef(null);
    const [mapReopened, setmapReopened] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState();
    const toast = useRef(null);
    const [showSpeedPanel, setShowSpeedPanel] = usePersistState(false, 'showJourneyProfile');
    const [showStopPanel, setShowStopPanel] = useState(false);
    const [hyperlapseLine, setHyperlapseLine ] = useState();
    const [track, setTrack] = useState(null);
    const [liveTrack, setLiveTrack] = useState(false);
    const [clickedLngLat, setClickedLngLat] = useState();
    const [clusterLeaves, setClusterLeaves] = useState([]);
    const [showMegaJourney, setShowMegaJourney] = useState(false);
    const [megaJourney, setMegaJourney] = useState();



    const mapInstanceRef = useRef();
    const vehiclesInterval = useRef();
    const vTimeout = useRef();
    const savedParms = useRef(vehicleFilterParams);
    const clusterClickedMarker = useRef();

    const [poisToView, setPoisToView] = usePersistState([], 'poisToView');
    const [showPoiLabels, setShowPoiLabels] = usePersistState('showPoiLabels', false);


    const handleShowMegaJourney = val => {
        setShowMegaJourney(val)

        const feats = val ? megaJourney?.features : journey?.features;

        const coords = feats
            .map(f => f?.geometry?.coordinates)
            .filter(coord => Array.isArray(coord) && coord.length === 2);


        const coordsData = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: coords
            }
        };

        mapInstanceRef?.current?.getSource('journey-points').setData(val ? megaJourney : journey);
        mapInstanceRef?.current?.getSource('journeyLine').setData(coordsData);


        const bounds = coords.reduce((b, coord) => b.extend(coord), new mapboxgl.LngLatBounds(coords[0], coords[0]));

        map.fitBounds(bounds, {
            padding: { bottom: 150, left: 150, top: 150, right: 150 },
            maxZoom: 16
        });

    }


    const handleShowPoiLabels = val => {
        if (mapInstanceRef?.current){
            if (val){
                mapInstanceRef?.current?.setLayoutProperty('places-of-interest-icon', 'text-field', ['get', 'name'])
            } else {
                mapInstanceRef?.current?.setLayoutProperty('places-of-interest-icon', 'text-field', '')
            }
        }

        setShowPoiLabels(val)
    }


    const handlePoisToView = val => setPoisToView(val);


    const handleLiveTrack = (val) => setLiveTrack(val)
    const handleHyperlapseLine = (obj) => setHyperlapseLine(obj);


    const handleTrack = async (e) => {
        await setTrack(e);//asynchronous

        if (e){
            openModal('displayTrackClickModal')
        } else {
            closeModal('displayTrackClickModal')
        }
    }



    const showToast = (summary, msg) => {
        // toast.current.replace({severity:'info', summary: summary,
        //     detail: msg, life: 3000});
    }

    const handleSelectedPoint = (point) => {
        setSelectedPoint(point);
    }

    const handleStartEnd = async (flag) => {
        console.log(journey)
        if (flag === 'start') await handleTrack(journey?.features[0]);
        else await handleTrack(journey?.features[journey?.features?.length - 1])
    }





    const forEachLayer = (text, cb) => {
        mapInstanceRef?.current.getStyle().layers.forEach((layer) => {
            if (!layer.id.includes(text)) return;
            cb(layer);
        });
    }
// Changing the map base style
    const changeStyle = (style) => {
        if (mapInstanceRef?.current){
            const savedLayers = [];
            const savedSources = {};
            const layerGroups = [
                'vehicle-points-layer',
                'line-layer',
                'line-layer2',
                'journey-points-layer',
                'start-journey-layer',
                'events-layer',
                'end-journey-layer',
                'clusters',
                'point-selected-layer',
                'add-3d-buildings',
                'places-of-interest-icon'
            ];

            layerGroups.forEach((layerGroup) => {
                forEachLayer(layerGroup, (layer) => {
                    savedSources[layer.source] = mapInstanceRef?.current?.getSource(layer.source)?.serialize();
                    savedLayers.push(layer);
                });
            });




            mapInstanceRef?.current?.setStyle(`mapbox://styles/${style}`);



            setTimeout(() => {

                let custIcons = addThemeIcons(getMapIcons());

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.src = icon.src;

                    customIcon.onload = () => {
                        if (!mapInstanceRef?.current?.hasImage(icon.name)) mapInstanceRef?.current?.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    }

                });

                Object.entries(savedSources).forEach(([id, source]) => {
                    try {
                        mapInstanceRef?.current.addSource(id, source);
                    } catch (e){
                        // console.log(e)
                        // console.log(source)
                    }
                });

                savedLayers.forEach((layer) => {
                    const mapLayer = mapInstanceRef?.current.getLayer(layer.id);
                    if(typeof mapLayer === 'undefined') {
                        mapInstanceRef?.current.addLayer(layer);
                    }
                });

                mapInstanceRef?.current?.setPaintProperty('vehicle-points-layer', 'text-color', darkMap ? 'white' : 'black');


            }, 1500);
        }
    }

    //const CustomControl = new MapCustomControl([showToast, handleLiveTrack, darkMap, changeStyle]);

    useEffect(() => {
        setMounted(true);


        return async () => {
            setMounted(false);

            clearInterval(vehiclesInterval?.current);
            clearTimeout(vTimeout?.current);

            if (mapInstanceRef?.current){

                if (mapInstanceRef?.current.getLayer("clusters")) {
                    mapInstanceRef?.current.removeLayer("clusters"); // Remove the source
                }


                if (mapInstanceRef?.current?.getLayer("vehicle-points-layer")) {
                    mapInstanceRef?.current?.removeLayer("vehicle-points-layer"); // Remove the layer
                }
                if (mapInstanceRef?.current.getLayer("journey-points-layer")) {
                    mapInstanceRef?.current.removeLayer("journey-points-layer"); // Remove the layer
                }
                if (mapInstanceRef?.current.getLayer("line-layer")) {
                    mapInstanceRef?.current.removeLayer("line-layer"); // Remove the layer
                }

                if (mapInstanceRef?.current.getSource("vehicle-points-data")) {
                    mapInstanceRef?.current.removeSource("vehicle-points-data"); // Remove the layer
                }
                if (mapInstanceRef?.current.getSource("journey-points")) {
                    mapInstanceRef?.current.removeSource("journey-points"); // Remove the source
                }
                if (mapInstanceRef?.current.getSource("journeyLine")) {
                    mapInstanceRef?.current.removeSource("journeyLine"); // Remove the source
                }


                mapInstanceRef?.current.off('load', mapLoadFunc)
                mapInstanceRef?.current.off('click', clickTrackLayer);
                mapInstanceRef?.current.off('click',  clickJourneyPointsLayer);
                mapInstanceRef?.current.off('click', clickJourneyEndLayer);
                mapInstanceRef?.current.off('click', clickJourneyStartLayer);
                mapInstanceRef?.current.off('click', clickVehiclePointsLayer);
                mapInstanceRef?.current.off('mousemove', mouseMoveJourneyPointsLayer)
                mapInstanceRef?.current.off('click', clickClusters)
                mapInstanceRef?.current.off('click', clickMap)

                const gl = mapInstanceRef?.current.getCanvas().getContext("webgl");
                if (gl) {
                    const loseContext = gl.getExtension("WEBGL_lose_context");
                    if (loseContext) {
                        loseContext.loseContext();
                    }
                }
                mapInstanceRef?.current?.style?._requestManager?._cancelAllRequests();
                mapInstanceRef?.current.remove();
            }
            setMap(null)
        }
    }, [])


    useEffect(() => {
        if (!displayTrackClickModal){
            handleHyperlapseLine()
        }
    }, [displayTrackClickModal])



    const storeForwardColour = () => {
        if (fetchUserLevel() === 'superuser'){
            return '#ec1818'
        } else {
            return '#ffffff'
        }
    }


    const addVehiclesToMap = async (dbReady) => {
        let initFiltered = [];




        if (dbReady){
            initFiltered = await filterVehicleList(savedParms.current);
        } else {
            initFiltered = await preFilterVehicleList(vehiclesRef?.current?.features, savedParms.current);
        }

        const data = initFiltered?.features?.filter(item => JSON.stringify(item.geometry?.coordinates) !== JSON.stringify([0,0]))

        mapInstanceRef?.current.getSource('vehicle-points-data').setData({features: data, type: "FeatureCollection"});

        return {features: data, type: "FeatureCollection"}
    };



    useEffect(async() => {
        if (mounted){
            savedParms.current = vehicleFilterParams;
        }
    }, [vehicleFilterParams]);


    const setMyTimer = () => {
        var toExactMinute = 60000 - (new Date().getTime() % 60000);
        vTimeout.current = setTimeout(async () => {
            vehiclesInterval.current = setInterval(async() => {
                await addVehiclesToMap(true)
            }, 60000)
        }, toExactMinute)
    }


    useEffect(() => {
       if (mounted && vehiclesRef?.current){

           mapboxgl.accessToken = "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";
           const mapStyle = darkMap ? 'mapbox://styles/fleetfocus/cl74ogqly001b14n15u5x24zn'
               : 'mapbox://styles/fleetfocus/clyreqdlj00d701qr81uf1gsw';
           const initializeMap = ({setMap, mapContainer}) => {

               mapInstanceRef.current  = new mapboxgl.Map({
                   container: mapContainer.current,
                   // style: 'mapbox://styles/mapbox/streets-v11',
                   style: mapStyle,
                   center: [0.9142, 51.9037],
                   zoom: 0,
                   maxZoom: 19,
                   minZoom: 1,
                   pitchWithRotate: true,
                   dragRotate: true,
                   renderWorldCopies: true
               });

               setMyTimer();


               // disable map rotation using right click + drag
               // map.dragRotate.disable();

               // disable map rotation using touch rotation gesture
               // map.touchZoomRotate.disableRotation();

               mapInstanceRef?.current.addControl(
                   new MapboxGeocoder({
                       accessToken: mapboxgl.accessToken,
                       countries: 'gb',
                       placeholder: 'address search',
                       mapboxgl,

                   }),  'bottom-right'
               );


               // map.addControl(CustomControl, 'top-right');

               mapInstanceRef?.current.on('load', (map) => mapLoadFunc(map.target));


           };


           if (!mapInstanceRef?.current) initializeMap({setMap, mapContainer});
       }
    }, [mounted, vehiclesRef?.current]);


    const mapLoadFunc = async (map) => {
        let custIcons = addThemeIcons(getMapIcons());

        custIcons.forEach(icon => {
            let customIcon = new Image(icon.width, icon.height);
            customIcon.src = icon.src;

            customIcon.onload = () => {
                if (!mapInstanceRef?.current?.hasImage(icon.name)) mapInstanceRef?.current?.addImage(icon.name, customIcon, {sdf: icon.sdf})
            }

        });

            setMap(map);
            mapInstanceRef?.current.resize();

        mapInstanceRef?.current.scrollZoom.setWheelZoomRate(2);

            mapInstanceRef?.current?.dragRotate.disable();
            mapInstanceRef?.current?.touchZoomRotate.disableRotation();

            // add the data source for new a feature collection with no features
        mapInstanceRef?.current.addSource('vehicle-points-data', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
                cluster: true,
                clusterMaxZoom: 19, // Max zoom to match map max zoom
                clusterRadius: 60
            });

            mapInstanceRef?.current.addSource('events-data', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                }
            });


            mapInstanceRef?.current.addSource('hyperlapseLine', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    }
                }
            });

            mapInstanceRef?.current.addSource('journey-points', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    }
                },
                generateId: true
            });


        mapInstanceRef?.current.addSource('journeyLine', {
            type: 'geojson',
            lineMetrics: true,
            data: {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: []
                }
            }
        });


//////////////////////////////////////////////////////////////// S+E LAYERS
            mapInstanceRef?.current.addSource('start-journey-data', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                }
            });

            mapInstanceRef?.current.addSource('end-journey-data', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                }
            });

            mapInstanceRef?.current.addSource('point-selected-data', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                }
            });


            mapInstanceRef?.current?.addSource('poi-data', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                }
            });



            mapInstanceRef?.current?.addSource('poi-data-icon', {
                type: 'geojson',
                data:  {
                    type: 'FeatureCollection',
                    features: [],
                }
            });


        // mapInstanceRef?.current?.addLayer({
        //     "id": 'places-of-interest',
        //     "type": "fill",
        //     "source": "poi-data",
        //     "layout": {},
        //     "paint": {
        //         "fill-color": getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
        //         "fill-opacity": 0.6
        //     }
        // });



        mapInstanceRef?.current?.addLayer({
            id: 'places-of-interest-icon',
            source: 'poi-data-icon',
            type: 'symbol',
            layout: {
                'icon-image': [
                    'case',
                    ['==', 'Office', ['get', 'cat']], 'office',
                    ['==', 'Restricted', ['get', 'cat']], 'ban',
                    ['==', 'Depot', ['get', 'cat']], 'boxes',
                    ['==', 'Charging station', ['get', 'cat']], 'charging',
                    ['==', 'Customer', ['get', 'cat']], 'customer',
                    ['==', 'Residence', ['get', 'cat']], 'residence',
                    ['==', 'Fuel station', ['get', 'cat']], 'fuel',
                    ['==', 'Supplier', ['get', 'cat']], 'supplier',
                    'dashed-circle'
                ],
                'text-field': showPoiLabels ? ['get', 'name'] : '',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': 11,
                'text-anchor': 'top',
                'text-offset': [0, 1.5],
                'icon-size': 0.88,  // 0.75,
            },
            paint: {
                'text-color': getComputedStyle(document.body).getPropertyValue('--text-color'),
            }
        });





            mapInstanceRef?.current?.addLayer({
                id: 'vehicle-points-layer',
                source: 'vehicle-points-data',
                type: 'symbol',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    //  'icon-image': 'rectangle-orange-5',
                    'icon-image': [
                        'case',
                        ['all', ['has', 'fetchedAtStr'], ['has', 'HbStr'], ['>', ['-', ['to-number', ['get', 'fetchedAtStr']], ['to-number', ['get', 'HbStr']]], 120000]],
                        'vStoppedMarker',
                        ['all', ['!=', ['get', 'ac', ['get', 'lastLocation']], "0"], ['has', 'fetchedAtStr'], ['has', 'HbStr'], ['<', ['-', ['to-number', ['get', 'fetchedAtStr']], ['to-number', ['get', 'HbStr']]], 120000]],
                        'jInProgressArrow',
                        'vIdlingArrow'
                    ],
                    'icon-ignore-placement': false,
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    "text-field": [
                        "case",
                        [
                            "all",
                            ["!=", ["get", "drivingStatus"], "driving"] ,
                            ["has", "poi"],
                            ["has", "name", ["get", "poi"]]
                        ],
                        ["concat", ["get", "registration"], "\n", ["get", "name", ["get", "poi"]]],
                        ["get", "registration"]
                    ],
                    'icon-rotate': [
                        'case',
                        ['all', ['has', 'fetchedAtStr'], ['has', 'HbStr'], ['>', ['-', ['to-number', ['get', 'fetchedAtStr']], ['to-number', ['get', 'HbStr']]], 120000]],
                        0,
                        ['get', 'head']
                    ],
                    'visibility': 'visible',
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                    'text-anchor': 'top',
                    'text-offset': [0, 1.5],
                },
                paint: {
                    // Sleep Offline Driving
                    'text-color': darkMap ? 'white' : 'black' ,
                        // "text-halo-color": "#fff",
                    // "text-halo-width": 3,
                    // "text-halo-blur": 1 // Slight blur for smoothness
                }
            });



            mapInstanceRef?.current.addLayer({
                id: 'end-journey-layer',
                source: 'end-journey-data',
                type: 'symbol',
                layout: {
                    'icon-image': [
                        'case',
                        ['==', false, ['get', 'inProgress']], 'jEndedFlag',
                        // ['==', 'parked', ['get', 'drivingStatus']], 'jEndedFlag',
                        // ['==', 'driving', ['get', 'drivingStatus']], 'jInProgressArrow',
                        'jInProgressArrow'
                    ],
                    'icon-ignore-placement': false,
                    'icon-rotation-alignment': 'viewport',
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    'icon-size': 1,
                    'icon-anchor': 'center',
                    'icon-rotate':
                        [
                            'case',
                            // ['==', 'parked', ['get', 'drivingStatus']], 0,
                            ['!=', true, ['get', 'inProgress']], 0,
                            ['get', 'head']
                        ],
                }
            });

            mapInstanceRef?.current.addLayer({
                id: 'start-journey-layer',
                source: 'start-journey-data',
                type: 'symbol',
                layout: {
                    'icon-image': 'jStartFlag',
                    'icon-ignore-placement': false,
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    'icon-size': 1,
                    'icon-anchor': 'center',
                },
                paint: {
                    'icon-color': '#000000',
                    'text-color': '#ffffff'
                }
            }, 'end-journey-layer');

            mapInstanceRef?.current.addLayer({
                id: 'journey-points-layer',
                source: 'journey-points',
                type: 'symbol',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'visibility': 'visible',
                    'icon-image': 'lpArrow',
                    'icon-rotation-alignment': 'map',
                    'icon-ignore-placement': false,
                    'icon-padding': 0,
                    'icon-allow-overlap': false,
                    // 'icon-size': 0.6,

                    'icon-size': {
                        stops: [
                            [1, 0.6],
                            [14, 0.6],
                            [15, 0.9]
                        ]
                    },


                    'icon-rotate': ['get', 'head'],
                },
                paint: {
                    'icon-color': [
                        'case',
                        ['==', 'events', ['get', 'dataSource']], 'blue',
                        ['==', 'video', ['get', 'dataSource']], fetchUserLevel() === 'superuser' ? 'green' : 'white',
                        ['==', true, ['get', 'storeForward']], fetchUserLevel() === 'superuser' ? 'red' : 'white',
                        '#ffffff'
                    ],
                    "icon-halo-color": "grey",
                    "icon-halo-width": 2
                }
            }, 'start-journey-layer');

            if (!fetchBlockedEndpoints().includes('view-snapshot')) {
                mapInstanceRef?.current.addLayer({
                    id: 'events-layer',
                    source: 'events-data',
                    type: 'symbol',
                    layout: {
                        'icon-image': [
                            'case',
                            // ['==', 'severe', ['get', 'severity']], 'circle-red-svg',
                            // ['==', 'moderate', ['get', 'severity']], 'circle-svg',
                            ['==', 'user', ['get', 'eventSource']], 'userCircle',

                            // ['in', 'Collision warning', ['get', 'eventTags']], 'collisionCircle',
                            // ['in', 'cornering', ['get', 'eventTags']], 'corneringCircle',
                            // ['in', 'Distraction', ['get', 'eventTags']], 'distractionCircle',
                            // ['in', 'Inattentive', ['get', 'eventTags']], 'distractionCircle',
                            // ['in', 'Drowsiness', ['get', 'eventTags']], 'fatigueCircle',
                            // ['in', 'Fatigue', ['get', 'eventTags']], 'fatigueCircle',
                            // ['in', 'Lane Departure', ['get', 'eventTags']], 'corneringCircle',
                            // ['in', 'Pedestrian', ['get', 'eventTags']], 'pedestrianCircle',
                            // ['in', 'Mobile phone', ['get', 'eventTags']], 'phoneCircle',
                            // ['in', 'Smoking', ['get', 'eventTags']], 'smokingCircle',
                            // ['in', 'speeding', ['get', 'eventTags']], 'speedingCircle',
                            // ['in', 'Tailgating', ['get', 'eventTags']], 'tailgatingCircle',
                            'circle-svg'
                        ],
                        'icon-ignore-placement': false,
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,
                        'icon-size': 1,
                        'icon-anchor': 'center',

                    },
                    // paint: {
                    //     "icon-color": 'red',
                    //     'text-color': '#ffffff'
                    // }
                }, 'end-journey-layer');


            }





// todo change icon of this
            mapInstanceRef?.current.addLayer({
                id: 'point-selected-layer',
                source: 'point-selected-data',
                type: 'symbol',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': 'dashed-circle',
                    'icon-ignore-placement': false,
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-size': 1,
                },
                paint: {
                    'icon-color': 'white'
                }
            }, 'journey-points-layer');







            markerStopped.remove();


            mapInstanceRef?.current.on('click', clickMap);
            mapInstanceRef?.current.on('click', 'point-selected-layer', clickTrackLayer);
            mapInstanceRef?.current.on('click', 'journey-points-layer', clickJourneyPointsLayer)
            mapInstanceRef?.current.on('click', 'journey-points-layer', clickJourneyPointsLayer);
            mapInstanceRef?.current.on('click', 'end-journey-layer', clickJourneyEndLayer);
            mapInstanceRef?.current.on('click', 'start-journey-layer', clickJourneyStartLayer);
            mapInstanceRef?.current.on('click', 'vehicle-points-layer', clickVehiclePointsLayer);
            mapInstanceRef?.current.on('mousemove', 'journey-points-layer', mouseMoveJourneyPointsLayer);

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            anchor: 'top',
            offset: [0, 10] // tweak as needed
        });
        map.on('mouseenter', 'places-of-interest-icon', (e) => {
            if (!showPoiLabels){
                map.getCanvas().style.cursor = 'pointer';

                const coordinates = e.features[0].geometry.coordinates.slice();

                popup.setLngLat(coordinates).setHTML(`<div>
<div style="color:black">Name: ${e.features[0].properties.name}</div>
<div style="color:black">Category: ${e.features[0].properties.cat}</div>
</div>`).addTo(map);
            }
        });

        // Remove the popup on mouseleave
        map.on('mouseleave', 'places-of-interest-icon', () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });


        mapInstanceRef?.current.on('contextmenu', (event) => {
            const contextMenu = document.getElementById('context-menu');
            event.preventDefault();
            setClickedLngLat(event.lngLat); // Store clicked coordinates
            // Position the menu the -64 is the margin top
            contextMenu.style.top = `${event.originalEvent.offsetY-64}px`;
            contextMenu.style.left = `${event.originalEvent.offsetX}px`;
            contextMenu.style.display = 'block';
        });









        if (!fetchBlockedEndpoints().includes('view-snapshot')) {
                mapInstanceRef?.current.on('click', 'events-layer', (e) => {
                    handleEvent(e.features[0].properties);
                });
            }

            /**/


            mapInstanceRef?.current.on('click', 'clusters', clickClusters);

            mapInstanceRef?.current.addLayer({
                id: 'clusters',
                type: 'symbol',
                source: 'vehicle-points-data',
                filter: ['has', 'point_count'],

                layout: {
                    'icon-image': 'cluster-marker',
                    //'icon-image': 'rectangle-orange-5',

                    'text-field': '{point_count_abbreviated}',
                    // 'icon-ignore-placement': true,

                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,

                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                    'icon-size': 0.9,
                    // 'icon-anchor': 'bottom-left',
                    // 'icon-offset': [-20, 0],
                    'text-anchor': 'bottom',
                    'text-offset': [0, 0.4],
                }
            });

            mapInstanceRef?.current.addLayer({
                id: 'line-layer',
                type: 'line',
                source: 'journeyLine',

                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                    'visibility': 'visible'
                },
                paint: {
                    'line-color':
                        [
                            'coalesce',
                            ['get', 'segColour'],
                            getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
                        ],


                    /*'line-offset': {    // works only for MAP SNAPPED!!
                        stops: [
                            [1, 0],
                            [14, 0],
                            [18, -5],
                            [22, -15]
                        ]
                    },*/
                    'line-width': {
                        base: 10,
                        stops: [
                            [1, 5],
                            [15, 12]
                        ]
                    },
                    'line-opacity': {
                        stops: [
                            [1, 1],
                            [14, 0.5]
                        ]
                    }

                }

            }, 'journey-points-layer');      // 'eventsLayer');

            mapInstanceRef?.current.addLayer({
                id: 'line-layer2',
                type: 'line',
                source: 'hyperlapseLine',

                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                    'visibility': 'visible'
                },
                paint: {
                    'line-color': 'pink',
                    'line-width': {
                        base: 10,
                        stops: [
                            [1, 5],
                            [15, 12]
                        ]
                    },
                }
            }, 'journey-points-layer');      // 'eventsLayer');
        }


    const clickMap = () => {
        clusterClickedMarker?.current?.remove();
    }


    const clickTrackLayer = async () => {
        await handleTrack(selectedPoint)
    }

    const clickJourneyPointsLayer = async (e) => {
        const j = mapInstanceRef?.current.getSource('journey-points')._data;

        const track = j?.features.filter(item => {
            return item.properties.time === e.features[0].properties.time
        });

        if (track.length > 0) {
            await handleTrack(track[0]);
        }
    }

    const clickJourneyEndLayer = async (e) => {
        const j = mapInstanceRef?.current.getSource('journey-points')._data;

        const track = j?.features.filter(item => {
            return item.properties.time === e.features[0].properties.time
        });


        if (track.length > 0) {
            await handleTrack(track[0]);
        }
    };

    const clickJourneyStartLayer = async e => {
        const j = mapInstanceRef?.current.getSource('journey-points')._data;

        const track = j?.features.filter(item => {
            return item.properties.time === e.features[0].properties.time
        });


        if (track.length > 0) {
            await handleTrack(track[0]);
        }

    }

    const clickVehiclePointsLayer = async (e) => {
        let test = JSON.parse(JSON.stringify(e.features[0]));
        test.properties.tags = JSON.parse(test.properties?.tags ? test.properties?.tags : '[]');
        test.properties.lastLocation = JSON.parse(test.properties?.lastLocation )
        handleVehicle(test);
    }

    const mouseMoveJourneyPointsLayer = async (e) => {
        let test = JSON.parse(JSON.stringify(e.features[0]));

        if (test){
            setSelectedPoint(test);
        }
    }

    const clickClusters = async (e) => {
        // console.log('clicked cluster');
        const features = mapInstanceRef?.current.queryRenderedFeatures(e.point, {layers: ['clusters']});
        const clusterId = features[0].properties.cluster_id;
        // console.log(clusterId);

        const point_count = features[0].properties.point_count;
        // console.log(point_count);
        const clusterSource = mapInstanceRef?.current.getSource('vehicle-points-data');

        clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {

            // console.log('zoom' + map.getZoom());
            if (mapInstanceRef?.current.getZoom() === 19) {
                setClickedLngLat(e.lngLat)
                setClusterLeaves(aFeatures);
            }


        });

        mapInstanceRef?.current.getSource('vehicle-points-data').getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }

            mapInstanceRef?.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom
            });

            // console.log('zoom2: ' + map.getZoom());

        });
    }

    useEffect(async () => {
        if (mapInstanceRef?.current && vehiclesRef?.current ) {
            setTimeout(async () => {
                markerStopped.remove();

                if (!vehicle){
                    mapInstanceRef?.current.getSource('journey-points').setData({
                        type: 'FeatureCollection',
                        features: []
                    });

                    mapInstanceRef?.current.getSource('journeyLine').setData({
                        type: 'FeatureCollection',
                        features: []
                    });

                    mapInstanceRef?.current.getSource('hyperlapseLine').setData({
                        type: 'FeatureCollection',
                        features: []
                    });

                    await addVehiclesToMap(dbReady);

                    mapInstanceRef?.current?.getSource('events-data').setData({type: 'FeatureCollection', features: []});
                    mapInstanceRef?.current?.getSource('poi-data-icon').setData(await fetchPoisForMap(poisToView));


                }

                if(pageToShow === '/map'){
                    setmapReopened(!mapReopened)
                }
            }, 500)

        }
    }, [map, savedParms?.current])


    useEffect(() => {
        if (mapInstanceRef?.current) {
            if (zoom !== null) {
                mapInstanceRef?.current.easeTo({center: zoom, zoom: 0, duration: 1000});
            }
        }
    }, [mapInstanceRef?.current, zoom])


    useEffect(() => {
        let isMounted = true;

        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const processMapData = async () => {
            if (!mapInstanceRef?.current || !isMounted) return;

            const map = mapInstanceRef.current;

            // Wait 200ms to allow for vehicle and journey to be set
            await sleep(250);

            // If vehicle AND journey are both missing, bail out
            if (!vehicle && !journey) {
                console.warn("No vehicle or journey data available yet");
                return;
            }

            setSelectedPoint(null);
            markerStopped.remove();

            const emptyFeatureCollection = {
                type: 'FeatureCollection',
                features: []
            };

            const sourceIds = [
                'start-journey-data',
                'end-journey-data',
                'journey-points',
                'journeyLine',
                'events-data',
                'hyperlapseLine',
                'point-selected-data'
            ];

            sourceIds.forEach(id => {
                const source = map.getSource(id);
                if (source) source.setData(emptyFeatureCollection);
            });

            // VEHICLE ONLY VIEW
            if (!vehicle) {
                map.resize();

                const dataPre = await addVehiclesToMap(dbReady);
                const coords = dataPre.features
                    .map(f => f?.geometry?.coordinates)
                    .filter(coord => Array.isArray(coord) && coord.length === 2 && JSON.stringify(coord) !== JSON.stringify([0, 0]));

                if (coords.length > 0) {
                    const bounds = coords.reduce((b, coord) => b.extend(coord), new mapboxgl.LngLatBounds(coords[0], coords[0]));
                    map.fitBounds(bounds, {
                        padding: { bottom: 50, left: 50, top: 10, right: 150 },
                        maxZoom: 16
                    });
                }

                map.getSource('events-data')?.setData(emptyFeatureCollection);

                map.setLayoutProperty('vehicle-points-layer', 'visibility', 'visible');
                map.setLayoutProperty('clusters', 'visibility', 'visible');

                return;
            }

            // VEHICLE present but NO journey
            map.setLayoutProperty('vehicle-points-layer', 'visibility', 'none');
            map.setLayoutProperty('clusters', 'visibility', 'none');

            if (!journey) {
                const dateString = [
                    date.getFullYear(),
                    ('0' + (date.getMonth() + 1)).slice(-2),
                    ('0' + date.getDate()).slice(-2)
                ].join('-');

                marker.remove();
                markerStart.remove();

                const { lng, lat } = vehicle?.properties?.lastLocation || {};
                if (lng && lat) {
                    map.flyTo({
                        center: [lng, lat],
                        zoom: 16,
                        speed: 3
                    });

                    ReactDOM.render(<StoppedMarker openModal={openModal} />, elStopped);
                    markerStopped.setLngLat([lng, lat]).addTo(map);
                }

                const lastJourney = await fetchLastJourney(vehicle?.properties?.dn, dateString);
                if (lastJourney?.length === 1) {
                    handleTrueLocation(lastJourney[0]);
                }

                return;
            }

            // VEHICLE + JOURNEY READY
            const eventsData = await fetchEventsForJourney(journey, pageToShow);
            if (eventsData?.features?.length > 0) {
                map.getSource('events-data')?.setData(eventsData);
            }

            map.getSource('journey-points')?.setData(journey);

            if (journey.features.length > 0) {
                map.getSource('start-journey-data')?.setData({
                    type: 'FeatureCollection',
                    features: [journey.features[0]]
                });

                const jEndData = journey.features[journey.features.length - 1];
                if (jEndData) {
                    jEndData.properties = {
                        ...jEndData.properties,
                        completed: journey?.details?.completed,
                        drivingStatus: journey?.details?.drivingStatus,
                        inProgress: journey?.details?.inProgress
                    };

                    map.getSource('end-journey-data')?.setData({
                        type: 'FeatureCollection',
                        features: [jEndData]
                    });
                }

                const coords = journey.features
                    .map(f => f?.geometry?.coordinates)
                    .filter(coord => Array.isArray(coord) && coord.length === 2);

                if (coords.length > 0) {
                    try {
                        marker.setLngLat(coords[coords.length - 1]).addTo(map);
                        markerStart.setLngLat(coords[0]).addTo(map);
                    } catch (error) {
                        console.error(error);
                    }

                    const bounds = coords.reduce((b, coord) => b.extend(coord), new mapboxgl.LngLatBounds(coords[0], coords[0]));

                    map.fitBounds(bounds, {
                        padding: { bottom: 150, left: 150, top: 150, right: 150 },
                        maxZoom: 16
                    });

                    const coordsData = {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: coords
                        }
                    };

                    map.getSource('journeyLine')?.setData(coordsData);
                }
            }

            // Hyperlapse line if available
            if (hyperlapseLine) {
                const hlCoords = hyperlapseLine
                    .map(f => f?.geometry?.coordinates)
                    .filter(coord => Array.isArray(coord) && coord.length === 2);

                map.getSource('hyperlapseLine')?.setData({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: hlCoords
                    }
                });
            }

            // Cleanup markers and resize map
            markerStopped.remove();
            startHlMarker.remove();
            pointSelectedMarker.remove();

            map.resize();
        };

        processMapData();

        return () => {
            isMounted = false;
        };

    }, [vehicle, journey, mapReopened]);


    useEffect(() => {

        if (mapInstanceRef?.current) {

            //setMap(map);
            //map.resize();
            window.setTimeout(()=>mapInstanceRef?.current.resize(), 50);
            window.setTimeout(()=>mapInstanceRef?.current.resize(), 100);
            window.setTimeout(()=>mapInstanceRef?.current.resize(), 150);
            window.setTimeout(()=>mapInstanceRef?.current.resize(), 200);
            window.setTimeout(()=>mapInstanceRef?.current.resize(), 250);
            window.setTimeout(()=>mapInstanceRef?.current.resize(), 300);

            const center = mapInstanceRef?.current.getCenter();

            mapInstanceRef?.current.flyTo({
                center: center,
                speed: 2
            });
        }

    }, [sideBarWidthPx])

    // useEffect(() => {
    //     if (lastMapCenter && recenterMap && map){
    //         setRecenterMap(false);
    //
    //         const bounds = lastMapCenter.reduce(function (b, coord) {
    //             return b.extend(coord);
    //         }, new mapboxgl.LngLatBounds(lastMapCenter[0], lastMapCenter[0]));
    //
    //         // map.fitBounds(bounds, {
    //         //     padding: {bottom: 150, left: 150, top: 150, right: 150}, maxZoom: 16
    //         // });
    //     }
    //
    // }, [recenterMap]);




    useEffect(() => {
        if (mapInstanceRef?.current){
            if(hyperlapseLine){

                let hlCoords = [];
                hyperlapseLine.forEach((f) => {
                    hlCoords.push(f?.geometry?.coordinates);
                });


                mapInstanceRef?.current.getSource('hyperlapseLine').setData({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: hlCoords
                    }
                });

                // startHlMarker.setLngLat(coords[0]).addTo(mapInstanceRef?.current);

                // if(coords?.length > 1){
                // if(coords?.length > 1){
                //     endHlMarker.setLngLat(coords[coords.length - 1]).addTo(mapInstanceRef?.current);
                // }



                mapInstanceRef?.current.resize();
            } else {

                // startHlMarker.remove();
                // endHlMarker.remove();


                // startHlMarker.remove();
                // endHlMarker.remove();
                mapInstanceRef?.current.getSource('hyperlapseLine').setData({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    }
                });


            }
        }

    }, [hyperlapseLine])


    useEffect(() => {
        if (mapInstanceRef?.current){
            if (selectedPoint){
                mapInstanceRef?.current?.getSource('point-selected-data')?.setData({
                    type: 'FeatureCollection',
                    features: [selectedPoint]
                });

            } else {
                mapInstanceRef?.current?.getSource('point-selected-data')?.setData({
                    type: 'FeatureCollection',
                    features: []
                });
            }
        }
    }, [selectedPoint])





    useEffect(() => {
        if (journey && mapInstanceRef?.current){

            if(liveTrack){
                mapInstanceRef?.current.easeTo({
                    center: journey.features[journey?.features?.length - 1].geometry.coordinates,
                    zoom:16,
                    bearing: journey.features[journey?.features?.length - 1].properties.head,
                    pitch: 70
                });

                mapInstanceRef?.current.setLayoutProperty('end-journey-layer', 'icon-rotation-alignment', 'map');

            } else {
                mapInstanceRef?.current.setLayoutProperty('end-journey-layer', 'icon-rotation-alignment', 'viewport');

                let coords = [];

                journey.features.forEach((f) => {
                    coords.push(f?.geometry?.coordinates);
                });

                let bounds

                if (coords?.length > 0){
                    bounds = coords.reduce(function (b, coord) {
                        return b.extend(coord);
                    }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

                }

                if (bounds){
                    mapInstanceRef?.current.fitBounds(bounds, {
                        padding: {bottom: 150, left: 150, top: 150, right: 150}, maxZoom: 16, pitch:0
                    });
                }
            }
        }
    }, [liveTrack])



    useEffect(() => {
        if (mapInstanceRef?.current){
            mapInstanceRef?.current.resize()
        }
    }, [showSpeedPanel, showStopPanel])


    useEffect(() => {
        if (clusterLeaves && clickedLngLat){

            const clusterClickedEle = document.createElement('div');

            ReactDOM.render(<ClusterList clusterLeaves={clusterLeaves} handleVehicle={handleVehicle} clickMap={clickMap}/>, clusterClickedEle);


            clusterClickedMarker.current = new mapboxgl.Marker(clusterClickedEle)
            clusterClickedMarker.current.setLngLat(clickedLngLat).addTo(mapInstanceRef?.current);

        } else {
            clusterClickedMarker?.current?.remove();
        }
    }, [clusterLeaves]);

// mega journey code
    // useEffect(async() => {
    //     if (journeys?.length > 1){
    //         const colourArr = ['red', 'blue', 'green', 'purple', 'pink', 'orange', 'black']
    //         const featuresArr = [];
    //
    //         for (let i = 0; i < journeys?.length; i++) {
    //             const j = await fetchJourney(journeys?.[i]?._id)
    //             for (let k = 0; k < j?.features?.length ; k++) {
    //                 const feat = j.features[k]
    //                 feat.properties.segColour = colourArr[i];
    //                 feat.properties.endJ = (k === (j?.features?.length - 1))
    //                 feat.properties.startJ = (k === 0)
    //                 featuresArr.push(feat)
    //             }
    //         }
    //
    //         setMegaJourney({
    //             type:'FeatureCollection',
    //             features: featuresArr
    //         })
    //     }
    // }, [journeys]);



    return (
        <React.Fragment>
            <div style={{transition: 'opacity .5s ease', opacity : vehicle ? 1 : 0,  height : vehicle ? 'auto' : '0px'}}>

                <VehicleDetail />

                <ListJourneys  showMegaJourney={showMegaJourney} handleShowMegaJourney={handleShowMegaJourney}/>

                {winWidth > 800  && journeys?.length > 0 &&
                    <div key={journey?.details?._id} style={{
                        position: 'relative', borderBottom: '1px solid var(--surface-border)',
                        background: 'var(--surface-ground)'}}>
                                <span style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    bottom: -27,
                                    left: 10,
                                    background: showSpeedPanel ? 'var(--v-list-button-selected)' : 'var(--surface-ground)',
                                    color: showSpeedPanel ? 'var(--v-list-button-text-selected)' : 'var(--text-color)',
                                    zIndex: 1,
                                    borderRadius: '0px 0px 3px 3px',
                                    padding: '5px',
                                    border: '1px solid var(--surface-border)',
                                    borderTop: 'none'
                                }}
                                      onClick={() => {
                                          setShowSpeedPanel(!showSpeedPanel);
                                          setShowStopPanel(false)
                                      }}>
                                    <FontAwesomeIcon icon={faGaugeMax} style={{marginRight: '5px', fontSize: '16px'}}/>
                                    Journey profile
                                </span>

                        <span style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            bottom: -27,
                            left: 120,
                            background: showStopPanel ? 'var(--v-list-button-selected)' : 'var(--surface-ground)',
                            color: showStopPanel ? 'var(--v-list-button-text-selected)' : 'var(--text-color)',
                            zIndex: 1,
                            borderRadius: '0px 0px 3px 3px',
                            padding: '5px',
                            border: '1px solid var(--surface-border)',
                            borderTop: 'none'
                        }}
                              onClick={() => {
                                  setShowSpeedPanel(false);
                                  setShowStopPanel(!showStopPanel)
                              }}>
                                    <FontAwesomeIcon icon={faClockRotateLeft} style={{marginRight: '5px', fontSize: '16px'}}/>
                                    Day summary
                                </span>

                        {showSpeedPanel &&
                            <SpeedChartV3 handleSelectedPoint={handleSelectedPoint} selectedPoint={selectedPoint}
                                          handleTrack={handleTrack}/>
                        }

                        {showStopPanel &&
                            <DaySummary />
                        }
                    </div>

                }
            </div>


            <div className="mapContainer" id="mapContainer"
                 style={{marginTop: '63px', flexDirection: 'column', position: 'relative'}}>

                <ContextMenu clickedLngLat={clickedLngLat} map={mapInstanceRef?.current}
                             handlePoisToView={handlePoisToView} poisToView={poisToView}
                             handleShowPoiLabels={handleShowPoiLabels} showPoiLabels={showPoiLabels}/>




                <div ref={el => (mapContainer.current = el)} style={styles}/>

                <div style={{position: 'absolute', top: "-50px", right: 10}} className="customControls">
                    <ReactMapControls map={mapInstanceRef?.current} changeStyle={changeStyle}
                                      handleLiveTrack={handleLiveTrack} />
                </div>
                {/*{vehicle && !journey &&*/}
                {/*    <LastSnapshotPanel />*/}
                {/*}*/}
            </div>


            <TrackModalParent track={track} handleHyperlapseLine={handleHyperlapseLine}
                              handleSelectedPoint={handleSelectedPoint} handleTrack={handleTrack}/>
            <EventModalParent/>
            <DeviceDetailsModalParent/>
            <Toast ref={toast} appendTo={null} position="bottom-right"/>

        </React.Fragment>

    );
};

export default Map2;
