import React, {useContext, useEffect, useRef, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import TimesheetReportTable from "./legacy/timesheetReportTable";
import {fetchActivityData} from "../activityReport/fetchActivityData";
import LoadingSpinner from "../../../loadingSpinner";
import reportsContext from "../../../../contexts/reportsContext";
import ReportsParameterSelection from "../reportsParameterSelection";
import {fetchTimesheetData} from "./fetchTimesheetData";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {
    DurationFromSeconds,
    formatDateTimesheetReport,
    fullDurationFromSeconds
} from "../../../../../functions/formatting/formatDate";
import {Button} from "primereact/button";
import {timesheetReportPdf} from "./timesheetReportPdf";

const TimesheetReport = ({}) => {

    const {dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi, theVehicle,
        generateReport, handleGenerateReport} = useContext(reportsContext)



    const [timesheetData, setTimesheetData] = useState([]);
    const [kpi, setKpi] = useState();



    const exportCSV = (selectionOnly) => {
        // dt.current.exportCSV({ selectionOnly });
        const exportData = timesheetData.map((row) => ({
            date: row?.date,
            journeys: row?.journeys,
            startTime: row.startTime,
            startPlace: row?.startPlace,
            endTime: row?.endTime,
            endPlace: row?.endPlace,
            distance: row?.totalDistanceStr,
            driving: row?.totalDurStr,
            shift: row?.shiftDurStr
        }));

        // Convert JSON to CSV string
        const csvRows = [];
        const headers = Object.keys(exportData[0]);
        csvRows.push(headers.join(","));

        exportData.forEach((row) => {
            const values = headers.map((header) => {
                const val = row[header] !== null && row[header] !== undefined ? row[header] : "";
                return `"${val}"`;
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Timesheet Report ${theVehicle?.properties?.registration} ${timesheetData?.[0].date}-${timesheetData?.[timesheetData?.length-1].date}.csv`;
        link.click();
        window.URL.revokeObjectURL(url);

    };

    const exportPdf = () => {
        timesheetReportPdf(timesheetData, kpi, theVehicle)
    };




    useEffect(async () => {
        if(dateRange && theVehicle && generateReport){
            handleReportLoading(true);
            const raw = await fetchTimesheetData(dateRange, theVehicle);
            setTimesheetData(raw);

            const totals = {
                totalShift:0,totalDist:0,totalDur:0,totalJourneys:0
            }


            raw.forEach(r => {
                totals.totalShift += r?.shiftDur;
                totals.totalDist += r?.totalDistance;
                totals.totalDur += r?.totalDur;
                totals.totalJourneys += r?.journeys
            })

            setKpi(totals);
            handleReportLoading(false);
        }

    }, [dateRange, theVehicle, generateReport]);


    const header = (
        <div style={{
            color: 'var(--text-color)',
            fontSize: '1.17em',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{paddingLeft:'5px'}}>
                Timesheet report for {theVehicle?.properties?.registration}<br/>
                {formatDateTimesheetReport(dateRange?.[0])} - {formatDateTimesheetReport(dateRange?.[1])}
            </div>

            <div>
                <div><b>Total working time:</b>&nbsp;{fullDurationFromSeconds(kpi?.totalShift)}</div>
                <div><b>Total driving time:</b>&nbsp;{fullDurationFromSeconds(kpi?.totalDur)}</div>
            </div>
            <div>
                <div><b>Total distance:</b>&nbsp;{kpi?.totalDist.toFixed(0)} mi</div>
                <div><b>Total journeys:</b>&nbsp;{kpi?.totalJourneys}</div>
            </div>
            <div>
                <div><b>Avg daily working:</b>&nbsp;{DurationFromSeconds(kpi?.totalShift / timesheetData?.length)}</div>
                <div><b>Avg daily driving:</b>&nbsp;{DurationFromSeconds(kpi?.totalDur / timesheetData?.length)}</div>
            </div>


            {/*<div>*/}
            {/*    <div><b>Average Finish <br/>Time:</b></div>*/}
            {/*    <div>{timeFromSeconds(actTotals?.endTime / activityData?.length)}</div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <div><b>Total journeys:</b>{timesheetData?.length}</div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <div><b>Total distance:</b>{actTotals?.distance?.toFixed(0)} miles</div>*/}
            {/*    <div></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <div><b>Total driving:</b>{DurationFromSeconds(actTotals?.driving)}</div>*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <div><b>Total standstill:</b> {DurationFromSeconds(actTotals?.stopped)}</div>*/}
            {/*</div>*/}
            <div style={{display: 'flex', gap: '5px',paddingRight:'5px'}}>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV"/>
                {/*<Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />*/}

                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>

        </div>
    );


    return (
        <React.Fragment>
            {generateReport &&
                <React.Fragment>
                    {reportLoading ?
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        // <ProgressSpinner />
                        :
                            <DataTable value={timesheetData} header={header}
                                       scrollable scrollHeight='calc(100vh - 220px)' stripedRows>
                                <Column field="date" header="Date" style={{paddingLeft:'18px'}} align={'left'}></Column>
                                <Column field="startTime" header="Start time"></Column>
                                <Column field="startPlace" header="Start place"></Column>
                                <Column field="endTime" header="End time"></Column>
                                <Column field="endPlace" header="End place"></Column>
                                <Column field="journeys" header="Journeys" align={"right"}></Column>
                                <Column field="totalDistanceStr" header="Distance" align={"right"}></Column>
                                <Column field="totalDurStr" header="Driving" align={"right"}></Column>
                                <Column field="shiftDurStr" header="Shift" align={"right"}  style={{paddingRight:'20px'}}></Column>
                            </DataTable>
                    }
                </React.Fragment>


            }


        </React.Fragment>
    )


}
export default TimesheetReport
