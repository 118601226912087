import React, {useContext, useEffect, useRef, useState} from 'react';
import {API_URL} from '../../../Constants.js'
import {ProgressSpinner} from "primereact/progressspinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackward, faDownLeftAndUpRightToCenter, faDownload,
    faForward,
    faPause,
    faPlay,
    faUpRightAndDownLeftFromCenter
} from "@fortawesome/pro-regular-svg-icons";
import {Slider} from "primereact/slider";
import RequestVideoFromHyperlapse from "../requestingMedia/requestVideoFromHyperlapse";
import mainContext from "../../contexts/mainContext";
import {faArrowDownLeftAndArrowUpRightToCenter} from "@fortawesome/pro-light-svg-icons";
import {splitStrTime} from "../../../functions/formatting/formatDate";
import indexContext from "../../contexts/indexContext";
import ReactPlayer from "react-player";
import FetchMediaToken from "../../../api/media/fetchMediaToken";
import LoadingSpinner from "../loadingSpinner";
import {ConfirmPopup} from "primereact/confirmpopup";
import {findDataByUniqueKey} from "../../../functions/db";


const token = localStorage.getItem('access_token');


const ChunkedVideoPlayer = ({video, hyperlapseDetail = {}}) => {

    const {winWidth} = useContext(indexContext);
    const {vehicles, event, vehicle} = useContext(mainContext)

    const [src, setSrc] = useState();
    const videoRef = useRef(null);
    const vidContainer = useRef();
    const [isMounted, setIsMounted] = useState(true);
    const [vidLoading, setVidLoading] = useState(false);
    const [mediaDuration, setMediaDuration] = useState('');
    const [mediaCurTime, setMediaCurTime] = useState('');
    const [mediaPlaying, setMediaPlaying] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [videoHovered, setVideoHovered] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const str_pad_left = (num, size) =>{
        let s = "000000000" + num;
        return s.substr(s.length - size);
    }


    const mediaLoaded = () => {
        setMediaCurTime('00:00');
        const theDuration = Math.floor(videoRef.current.duration);
        const minutes = Math.floor(theDuration / 60);
        const seconds = theDuration - (minutes * 60);
        setMediaDuration(str_pad_left(minutes, 2) + ':' + str_pad_left(seconds, 2));
    }



    const mediaUpdated = (e) => {
        const minutes = Math.floor(e.target.currentTime / 60);
        const seconds = Math.floor(e.target.currentTime - (minutes * 60));
        setMediaCurTime(str_pad_left(minutes, 2) + ':' + str_pad_left(seconds, 2));
        setPercentage(( e.target.currentTime / videoRef.current.duration) * 100);
    }

    const mediaEnded = () => {
        setMediaPlaying(false);
    }

    const playPauseMedia = () => {
        if (mediaPlaying){
            videoRef.current.pause();
            setMediaPlaying(false);
        } else {
            videoRef.current.play();
            setMediaPlaying(true);
        }

    }


    const downloadFile = async () => {
        const splitStr = video.file.split('_');
        const times = splitStr[1].split("-");

        const vehicle = await findDataByUniqueKey('vehicles', splitStr[0].toString())
        let camPos;

        // event.vehicleDetails[0].cameras.forEach(cam => {
        //     if (cam.channel === video.ch){
        //         camPos = cam.camPosition
        //     }
        // })

        const str = vehicle.properties.registration + " " +  splitStrTime(times[0]);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.target = '_blank'
        a.href = src;
        a.download = str;
        a.click();
    }



    const nextHlFrame = () => {
        videoRef.current.currentTime = videoRef.current.currentTime + (videoRef.current.duration / hyperlapseDetail.frames);
    }

    const prevHlFrame = () => {
        videoRef.current.currentTime = videoRef.current.currentTime - (videoRef.current.duration / hyperlapseDetail.frames);

    }


    const handleScrubMouseMove = (percentageWidth) => {
        // setPercentage(( videoRef.current.getCurrentTime() / videoRef.current.getDuration() ) * 100);
        videoRef.current.currentTime = videoRef.current.duration * (percentageWidth.value/100);
    }

    const handleFsChange = (e) => {
        if (document.fullscreenElement || document.webkitFullscreenElement ||
            document.mozFullScreenElement) {
            setFullscreen(true);
        } else {
            setFullscreen(false)
        }
    }


    const toggleFullscreen = (val) => {
        setFullscreen(val);
        if (val){
            videoRef.current.height = '95vh';
            vidContainer?.current?.requestFullscreen();

        } else {
            videoRef.current.height = '45vh';
            document.exitFullscreen();

        }
    }

    

    useEffect(async() => {
        if (video){
            const file = video?.file ? video?.file : video?.filename
            const token = await FetchMediaToken(file);
            setSrc(API_URL +'/view-video/' + file + '?mt=' + token?.access_token);

            if (video?.file?.includes('hyper')){
                videoRef.current.playbackRate = 0.5;
            }
        }
    }, [video])



    useEffect(() => {

        document.addEventListener('fullscreenchange', handleFsChange);

        return () => {
            URL.revokeObjectURL(src)
            document.removeEventListener('fullscreenchange', handleFsChange);

            setSrc(null);
            setVidLoading(null);
            setIsMounted(false);
        }
    }, []);



    const ConfirmFooter = () => {
        return (
            <div style={{display: 'flex', padding: '0px 1.5rem 1.5rem 1.5rem', justifyContent: 'space-between'}}>
                <button className="p-button p-button-sm p-button-secondary" onClick={downloadFile}>Video</button>
                <button className="p-button p-button-sm p-button-secondary" onClick={() => setShowConfirm(false)}>
                    Cancel Download
                </button>
            </div>
        )
    }



    // return <audio src={contentSrc}></audio>;
    return (
        <div>

            {vidLoading &&
                <div style={{width: '100%', height: '600px', position: 'relative'}}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                            'translate(-50%, -50%)'}}>
                        <LoadingSpinner />
                    </div>
                </div>
            }


            {!vidLoading &&
                <center ref={vidContainer}>
                    <video style={{width: '100%'}} controls={true} src={src}
                           ref={videoRef} onTimeUpdate={(e) => {
                        mediaUpdated(e)
                    }}
                           onEnded={mediaEnded} onLoadedMetadata={(e) => {
                        mediaLoaded(e)
                    }}
                    ></video>


                    {/*{winWidth > 800 &&*/}
                    {/*    <div style={{display:'flex',justifyContent:'center',alignItems: 'center',width:'100%', maxWidth: '1100px', marginTop: '5px'}}>*/}
                    {/*        <button onClick={playPauseMedia} className="p-button p-button-secondary">*/}
                    {/*            {mediaPlaying &&*/}
                    {/*                <FontAwesomeIcon icon={faPause} className="p-button-label"/>*/}
                    {/*            }*/}
                    {/*            {!mediaPlaying &&*/}
                    {/*                <FontAwesomeIcon icon={faPlay} className="p-button-label"/>*/}
                    {/*            }*/}

                    {/*        </button>*/}


                    {/*        {video?.startTimeHyper &&*/}
                    {/*            <button onClick={prevHlFrame} className="p-button p-button-secondary" style={{marginLeft: '5px'}}>*/}
                    {/*                <FontAwesomeIcon icon={faBackward} className="p-button-label"/>*/}
                    {/*            </button>*/}
                    {/*        }*/}


                    {/*        <div style={{textAlign: 'center',marginLeft: '10px'}}>{mediaCurTime}</div>*/}

                    {/*        <div style={{flex:1, paddingLeft: '15px', paddingRight: '15px'}}>*/}
                    {/*            <Slider value={percentage} onChange={(e) => handleScrubMouseMove(e)} style={{width: '100%', cursor: 'pointer'}} />*/}
                    {/*        </div>*/}

                    {/*        <div style={{marginRight: '10px'}}>{mediaDuration}</div>*/}

                    {/*        {video?.startTimeHyper &&*/}
                    {/*            <button onClick={nextHlFrame} className="p-button p-button-secondary" style={{marginRight: '5px'}}>*/}
                    {/*                <FontAwesomeIcon icon={faForward} className="p-button-label"/>*/}
                    {/*            </button>*/}
                    {/*        }*/}


                    {/*        <button className="p-button p-button-secondary" onClick={() => setShowConfirm(true)}>*/}
                    {/*            <FontAwesomeIcon icon={faDownload} className="p-button-label" />*/}
                    {/*        </button>*/}

                    {/*        <ConfirmPopup visible={showConfirm} onHide={() => setShowConfirm(false)} style={{width: '35vw'}}*/}
                    {/*                      message="Your video download has started and will show in your browsers download section,*/}
                    {/*                      please do not close myfleetlive.ai until the download has completed" footer={ConfirmFooter} />*/}


                    {/*        <button className="p-button p-button-secondary" style={{marginLeft: '2px'}}*/}
                    {/*                onMouseEnter={() => setVideoHovered(true)} onMouseLeave={() => setVideoHovered(false)}*/}
                    {/*                onClick={() => toggleFullscreen(!fullscreen)}>*/}
                    {/*            <FontAwesomeIcon icon={!fullscreen ? faUpRightAndDownLeftFromCenter : faArrowDownLeftAndArrowUpRightToCenter}*/}
                    {/*                             className="p-button-label"/>*/}

                    {/*        </button>*/}
                    {/*    </div>*/}

                    {/*}*/}

                    {/*{video?.startTimeHyper && !fullscreen &&*/}
                    {/*    <RequestVideoFromHyperlapse video={video} videoRef={videoRef} />*/}
                    {/*}*/}



                    <div style={{display: 'flex', width: '100%', justifyContent:'space-between'}}>

                        {video?.file?.includes('hyper') &&
                            <div>

                                <button onClick={prevHlFrame} className="p-button p-button-secondary"
                                        style={{marginLeft: '5px'}}>
                                    <FontAwesomeIcon icon={faBackward} className="p-button-label"/>
                                </button>

                                <span>&nbsp;</span>

                                <button onClick={nextHlFrame} className="p-button p-button-secondary"
                                        style={{marginRight: '5px'}}>
                                    <FontAwesomeIcon icon={faForward} className="p-button-label"/>
                                </button>

                            </div>
                        }


                        <button className="p-button p-button-secondary" onClick={downloadFile}>
                            <FontAwesomeIcon icon={faDownload} className="p-button-label"/>
                        </button>


                    </div>
                    {video?.file?.includes('hyper') && !fullscreen &&
                        <RequestVideoFromHyperlapse video={video} videoRef={videoRef}/>
                    }


                </center>


            }
        </div>
    );
};

export default ChunkedVideoPlayer;
