import userCirclePurple from "../../../../../svgs/mapIcons/events/userCirclePurple.svg";
import dashedCircle from "../../../../../svgs/mapIcons/dashedCircle.svg";
import circleExclamationPurple from "../../../../../svgs/mapIcons/events/circleExclamationPurple.svg";
import mapMarker from "../../../../../svgs/mapIcons/mapMarker.svg";
import Arrow from "../../../../../svgs/mapIcons/mapArrow.png";
import vStoppedMarker from "../../../../../svgs/mapIcons/vStoppedMarker.svg";
import jStartFlag from "../../../../../svgs/mapIcons/jStartFlag.png";
import jEndedFlag from "../../../../../svgs/mapIcons/jEndedFlag.png";
import jInProgressArrow from "../../../../../svgs/mapIcons/jInProgressArrow.svg";
import vIdlingArrow from "../../../../../svgs/mapIcons/vIdlingArrow.svg";
import licencePlateCluster from "../../../../../svgs/mapIcons/licence-plate-cluster.png";
import licencePlate from "../../../../../svgs/mapIcons/licence-plate-1.png";
import circleExclamationTeal from "../../../../../svgs/mapIcons/events/circleExclamationTeal.svg";
import userCircleTeal from "../../../../../svgs/mapIcons/events/userCircleTeal.svg";
import userCirclePink from "../../../../../svgs/mapIcons/events/userCirclePink.svg";
import circleExclamationPink from "../../../../../svgs/mapIcons/events/circleExclamationPink.svg";
import restricted from "../../../../../svgs/poi/restricted.svg"
import depot from "../../../../../svgs/poi/depot.svg"
import charging from "../../../../../svgs/poi/charging.svg"
import customer from "../../../../../svgs/poi/customer.svg"
import fuel from "../../../../../svgs/poi/fuel.svg"
import office from "../../../../../svgs/poi/office.svg"
import home from "../../../../../svgs/poi/home.svg"
import supplier from "../../../../../svgs/poi/supplier.svg"


export const getMapIcons = () => {

    let custIcons = [
        {
            src: restricted,
            name: 'ban',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: depot,
            name: 'boxes',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: charging,
            name: 'charging',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: customer,
            name: 'customer',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: fuel,
            name: 'fuel',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: office,
            name: 'office',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: home,
            name: 'residence',
            width: 50,
            height: 50,
            sdf: false
        },
        {
            src: supplier,
            name: 'supplier',
            width: 50,
            height: 50,
            sdf: false
        },

        {
            src: dashedCircle,
            name: 'dashed-circle',
            width: 30,
            height: 30,
            sdf: false
        },
        {
            src: mapMarker,
            name: 'mapMarker',
            width: 30,
            height: 40,
            sdf: false
        },

        {
            src: Arrow,
            name: 'lpArrow',
            width: 30,
            height: 30,
            sdf: true
        },

        {
            src: vStoppedMarker,
            name: 'vStoppedMarker',
            width: 40,
            height: 40,
            sdf: false
        },
        {
            src: jStartFlag,
            name: 'jStartFlag',
            width: 40,
            height: 40,
            sdf: false
        },
        {
            src: jEndedFlag,
            name: 'jEndedFlag',
            width: 40,
            height: 40,
            sdf: false
        },
        {
            src: jInProgressArrow,
            name: 'jInProgressArrow',
            width: 40,
            height: 40,
            sdf: false
        },

        {
            src: vIdlingArrow,
            name: 'vIdlingArrow',
            width: 40,
            height: 40,
            sdf: false
        },

        {
            src: licencePlateCluster,
            name: 'cluster-marker',
            width: 47,
            height: 37,
            sdf: false
        },

        {
            src: licencePlate,
            name: 'whitePlate',
            width: 100,
            height: 26,
            sdf: false
        },
        // poi

    ];
    return custIcons

}



export const addThemeIcons = (custIcons) => {
    const theme = localStorage.getItem('darkMode');

    let res;

    if (theme.includes('Teal')){
       res = custIcons.concat([{
            src: circleExclamationTeal,
            name: 'circle-svg',
            width: 40,
            height: 40,
            sdf: false
        },
            {
                src: userCircleTeal,
                name: 'userCircle',
                width: 40,
                height: 40,
                sdf: false
            }])
    } else if (theme.includes('Pink')){
        res = custIcons.concat([  {
            src: userCirclePink,
            name: 'userCircle',
            width: 40,
            height: 40,
            sdf: false
        },
            {
                src: circleExclamationPink,
                name: 'circle-svg',
                width: 40,
                height: 40,
                sdf: false
            }])
    } else {
        res = custIcons.concat([  {
            src: userCirclePurple,
            name: 'userCircle',
            width: 40,
            height: 40,
            sdf: false
        },
            {
                src: circleExclamationPurple,
                name: 'circle-svg',
                width: 40,
                height: 40,
                sdf: false
            }
        ])
    }

    return res
}