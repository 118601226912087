import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Chart} from "primereact/chart";
import gridContext from "../../../../contexts/gridContext";
import mainContext from "../../../../contexts/mainContext";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import LoadingSpinner from "../../../loadingSpinner";
import {uCaseFirst} from "../../../../../functions/formatting/uCaseFirst";
import indexContext from "../../../../contexts/indexContext";
import {findByIndexProp, findDataByUniqueKey} from "../../../../../functions/db";
import fetchEventsTags from "../../../../../api/events/fetchEventsTags";
import fetchDrivers from "../../../../../api/drivers/fetchDrivers";

const DriverComparator = ({handleShowComparator2}) => {

    const {eventParams, tagLib, driver, handleDriver, gridDate,lastDaysOfEvents, handleGridData} = useContext(gridContext);
    const {vehicles, disableDateChange, handleVehicle, vehicle} = useContext(mainContext);
    const {darkMode, fleets} = useContext(indexContext)

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading] = useState(true);
    const [comparatorData, setComparatorData] = useState();
    const [mounted, setMounted] = useState(false);
    const [drivers, setDrivers] = useState()




    useEffect(async () => {
        setMounted(true)
        return () => setMounted(false)
    }, [])


    useEffect(async () => {
        if (mounted){
            setLoading(true);

            const data = {
                "eventTags": [
                    "-journey end",
                    "-health",
                    "-hyperlapse"

                ].concat(eventParams),
                "insightByDriver": true,
                "fleetIds": ["protech_rail"]
            }


            if (gridDate){
                const dateString = [
                    gridDate.getFullYear(),
                    ('0' + (gridDate.getMonth() + 1)).slice(-2),
                    ('0' + gridDate.getDate()).slice(-2)
                ].join('-');

                data.startTime = dateString + ' 00:00:00';

                const et = [
                    gridDate.getFullYear(),
                    ('0' + (gridDate.getMonth() + 1)).slice(-2),
                    ('0' + gridDate.getDate()).slice(-2)
                ].join('-');

                data.endTime = et + ' 23:59:59';
            } else {

                let today = new Date();
                let start = new Date();
                start.setDate(start.getDate() - lastDaysOfEvents)


                const dateString = [
                    start.getFullYear(),
                    ('0' + (start.getMonth() + 1)).slice(-2),
                    ('0' + start.getDate()).slice(-2)
                ].join('-');

                data.startTime = dateString + ' 00:00:00';

                const et = [
                    today.getFullYear(),
                    ('0' + (today.getMonth() + 1)).slice(-2),
                    ('0' + today.getDate()).slice(-2)
                ].join('-');

                data.endTime = et + ' 23:59:59';
            }


            const r = await fetchEventsTags(data)

            const drivers = await fetchDrivers({
                "fleetIds": ["protech_rail"]
            });



            const rawData = r?.countsDns?.[0]?.ids.filter(i => i?.dn !== null)
            let labelsArr = [];


            for (let i = 0; i < rawData?.length; i++) {
                let v = await drivers.filter(e => e?.driverId === rawData?.[i]?.dn);
                labelsArr.push(v?.[0]?.driverName ? v?.[0]?.driverName : rawData?.[i]?.dn)
            }

            const theChartData = {
                labels: labelsArr,
                datasets: [
                    {
                        label: 'Event counts',
                        data: rawData?.reduce((acc, curVal) => acc.concat(curVal.count), []),
                        backgroundColor: () => rawData?.map(function (v, i) {
                            return '#395a85';
                        }),
                    }
                ]
            };

            const options = {
                onClick: async (evt, element)  => {
                    if (element.length > 0) {
                        const i = element?.[0]?.index
                        const reg = drivers.filter(driver => driver?.driverName === theChartData?.labels[i]);
                        handleDriver(reg?.[0])
                        handleShowComparator2(false)
                    }
                },
                animation: {
                    duration: 0
                },
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: function(context) {

                                let label = uCaseFirst(tagLib?.[eventParams?.[0]]?.displayName) + " events: "


                                if (context.parsed.y !== null) {
                                    label += context.parsed.y
                                }
                                return label;
                            }
                        }
                    },
                    legend: {
                        labels: {
                            color: getComputedStyle(document.body).getPropertyValue('--text-color')
                        },
                        display: false
                    }
                },
                scales: {
                    x: {
                        grid: {
                            color: getComputedStyle(document.body).getPropertyValue('--surface-border')
                        },
                        ticks: {
                            color: getComputedStyle(document.body).getPropertyValue('--gray-700')
                            // color: () => filtered.map(function (v, i) {
                            //     return chartFilters?.includes(v?._id) ? getComputedStyle(document.body).getPropertyValue('--main-accent-colour') : '#395a85';
                            // }),
                            // font: () => filtered.map(function (v, i) {
                            //     return chartFilters?.includes(v?._id) ? {weight: 'bolder'} : {weight: 'normal'};
                            // })

                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: getComputedStyle(document.body).getPropertyValue('--gray-700')
                        },
                        grid: {
                            color: getComputedStyle(document.body).getPropertyValue('--surface-border')
                        }
                    }
                }
            };

            await setChartData(theChartData);
            await setChartOptions(options);

            setLoading(false)
        }
    }, [mounted, darkMode, eventParams, gridDate])



    return (
        <React.Fragment>
            {!loading ?
                <Chart type="bar" data={chartData} options={chartOptions}  style={{maxHeight: '175px', padding:'0px 20px 0px 10px'}}/>
            :
                <div style={{height: '150px', width: '100%', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div>
                        <LoadingSpinner/>
                    </div>
                </div>
            }
        </React.Fragment>

    )
}

export default DriverComparator