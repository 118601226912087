import React, {useContext, useEffect, useRef, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {fetchActivityData} from "../activityReport/fetchActivityData";
import {
    DurationFromSeconds,
    formatDateOnly,
    formatDateTimesheetReport
} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";
import LoadingSpinner from "../../../loadingSpinner";
import VehicleCo2ReportSummary from "../co2Report/legacy/vehicle/vehicleCo2ReportSummary";

import FleetCo2ReportSummary from "../co2Report/legacy/fleet/fleetCo2ReportSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faLocationDot, faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import {faCar, faSignsPost} from "@fortawesome/pro-regular-svg-icons";
import {faSmoke} from "@fortawesome/pro-regular-svg-icons/faSmoke";
import {gToKg} from "../../../../../functions/formatting/gToKg";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import ReportsParameterSelection from "../reportsParameterSelection";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import indexContext from "../../../../contexts/indexContext";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetIdling from "../../../../../api/postFleetIdling";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import {getStoreData} from "../../../../../functions/db";
import Co2ReportOverviewRow from "./legacy/co2ReportOverviewRow";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {fleetCo2ReportPdf} from "./fleetCo2ReportPdf";



const FleetCo2Report = ({}) => {

    const {fleets} = useContext(indexContext);
    const {theVehicle, theFleet, dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi,
        generateReport, handleGenerateReport} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const dt = useRef();



    const generateReportData = async () => {
        const data = {
            fleetId: theFleet?.fleetId,
            startDate: [
                dateRange?.[0].getFullYear(),
                ('0' + (dateRange?.[0].getMonth() + 1)).slice(-2),
                ('0' + dateRange?.[0].getDate()).slice(-2)
            ].join('-') + 'T00:00:00',
            endDate: [
                dateRange?.[1].getFullYear(),
                ('0' + (dateRange?.[1].getMonth() + 1)).slice(-2),
                ('0' + dateRange?.[1].getDate()).slice(-2)
            ].join('-') + 'T23:59:00'
        }

        const r = await postFleetIdling(data);


        let distance = 0;
        let journeys = 0;
        let co2 = 0;



        for (let i = 0; i < r?.length; i++) {
            const v = r[i]

            v.distanceFull = v?.distance?.toFixed(0) + ' mi'


            const details = await fetchDeviceDetails({properties: {dn: v?._id}});
            const co2Per = details?.[0]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions;
            v.registration = details?.[0]?.registration

            if (co2Per) {
                const totCo2 = ((v?.distance * 1.60934) * co2Per);
                v.co2 = totCo2;
                co2 += totCo2;
                v.avgCo2 = totCo2 / v?.journeyCount
                v.avgCo2Full = gToKg(totCo2 / v?.journeyCount)
                v.co2Full = gToKg(totCo2)
            }

            distance += v?.distance;
            journeys += v?.journeyCount

        }



        r.sort(function(a, b){return b.totCo2-a.totCo2});

        setActivityData(r);


        const total = {
            journeys,
            distance,
            co2
        }

        const cache = {
            actData: r,
            totals: total,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }

        setActTotals(total);

        localStorage.setItem('fleetCo2Report', JSON.stringify(cache));
        handleReportLoading(false);
    }


    const exportPdf = () => {
        fleetCo2ReportPdf(activityData, theFleet, actTotals, dateRange)
    }

    const exportCSV = () => {
        // dt.current.exportCSV({ selectionOnly });
        const exportData = activityData.map((row) => ({
            vehicle: row?.registration,
            journeys: row?.journeyCount,
            distance: row.distanceFull,
            co2: row?.co2Full,
            averageCo2: row?.avgCo2Full
        }));

        // Convert JSON to CSV string
        const csvRows = [];
        const headers = Object.keys(exportData[0]);
        csvRows.push(headers.join(","));

        exportData.forEach((row) => {
            const values = headers.map((header) => {
                const val = row[header] !== null && row[header] !== undefined ? row[header] : "";
                return `"${val}"`;
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;


        const range = formatDateOnly(dateRange?.[0])+'-'+formatDateOnly(dateRange?.[1]);
        link.download = `Fleet co2 Report ${theFleet?.fleetName} ${range}.csv`;
        link.click();
        window.URL.revokeObjectURL(url);
    }




    useEffect(async () => {


        if (localStorage.getItem('fleetCo2Report')){
            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('fleetCo2Report'));


            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){


                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }




            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange?.[0] && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }




    }, [dateRange, theVehicle, theFleet, generateReport]);




    const header = (
        <div style={{
            color: 'var(--text-color)',
            fontSize: '1.17em',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{paddingLeft:'5px'}}>
                Fleet co2 report for {theFleet?.fleetName}<br/>
                {formatDateTimesheetReport(dateRange?.[0])} - {formatDateTimesheetReport(dateRange?.[1])}
            </div>
            <div>
                <div><b>Journeys:</b> {actTotals?.journeys}</div>
                <div><b>Distance:</b> {actTotals?.distance?.toFixed(0)} mi</div>
            </div>
            <div>
                <div><b>co2:</b> {gToKg(actTotals?.co2)}</div>
                <div><b>Avg co2:</b> {gToKg(actTotals?.co2 / actTotals?.journeys)}</div>
            </div>

            <div style={{display: 'flex', gap: '5px'}}>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV"/>
                {/*<Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />*/}

                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>

        </div>
    );


    return (
        <React.Fragment>
            {generateReport &&
                <React.Fragment>
                {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            {activityData?.length > 0 ?
                                <DataTable ref={dt} value={activityData} scrollable scrollHeight='calc(100vh - 220px)'
                                            stripedRows header={header}>
                                    <Column field="registration" header="Vehicle" style={{paddingLeft:'18px'}} align={'left'}></Column>
                                    <Column field="journeyCount" header="Journeys"></Column>
                                    <Column field="distanceFull" header="Distance" align={"right"}></Column>
                                    <Column field="co2Full" header="co2" align={"right"}></Column>
                                    <Column field="avgCo2Full" header="Average co2" align={"right"} style={{paddingRight:'20px'}}></Column>
                                </DataTable>

                            :
                                <div style={{
                                    fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                                }}>
                                    No CO2 Data for the given fleet for the given date range
                                </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>

            }
        </React.Fragment>
    )
}
export default FleetCo2Report

