import React, {useContext, useEffect, useRef, useState} from 'react';
import {Menu} from "primereact/menu";
import indexContext from "../../../../contexts/indexContext";
import mainContext from "../../../../contexts/mainContext";
import {Dropdown} from "primereact/dropdown";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import PlaceModalParent from "../../../modals/placeModal/placeModalParent";
import {fetchPoisForMap} from "../mapFunctions/fetchPoisForMap";
import {catOptions} from "../../places/catOptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Checkbox} from "primereact/checkbox";
import usePersistState from "../../../../../functions/localStorage/persistState";

const ContextMenu = ({clickedLngLat, map, handlePoisToView, poisToView, handleShowPoiLabels, showPoiLabels}) => {

    const {fleets, winWidth} = useContext(indexContext);
    const {vehicle} = useContext(mainContext);
    const vehicleRef = useRef(vehicle);
    const [fleet, setFleet] = useState();
    const [showPlaceModal, setShowPlaceModal] = useState(false);
    const poisToShow = useRef(poisToView);


    const handleFleet = (val) => setFleet(val);
    const handleShowPlaceModal = (val) => setShowPlaceModal(val);


    const hideMenu = () => {
        const contextMenu = document?.getElementById('context-menu');
        if (contextMenu) contextMenu.style.display = 'none';
    };

    const hideOnDocumentClick = (e) => {
        const myElementToCheckIfClicksAreInsideOf = document.querySelector('#context-menu');
        if (!myElementToCheckIfClicksAreInsideOf?.contains(e.target)) {
            hideMenu();
        }
    }


    useEffect(() => {
        document.body.addEventListener('click', hideOnDocumentClick, true);

        return () => document.body.removeEventListener('click', hideOnDocumentClick)
    }, [])



    useEffect(() => {
        vehicleRef.current = vehicle
    }, [vehicle]);





    const items = [
        {
            label: 'Map',
            items: [
                {
                    label: 'Zoom in',
                    command: () => zoomIn()
                },
                {
                    label: 'Zoom out',
                    command: () => zoomOut()
                }
            ]
        },
        {
            label: 'Places',
            items: [
                {
                    label: 'Add place',
                    command: () => {
                        hideMenu();
                        checkIfFleet();
                    }
                },
                {
                    label: 'Show/hide places',
                    command: () => {
                        hideMenu();
                        showTemplate2();
                    }
                }
            ]
        }

    ];

    const zoomIn = () => {if (map) map.easeTo({center:clickedLngLat, zoom:map.getZoom()+1});}
    const zoomOut = () => {if (map) map.easeTo({center:clickedLngLat, zoom:map.getZoom()-1});}
    const accept = () => handleShowPlaceModal(true);


    const checkIfFleet = () => {
        if (vehicleRef?.current || fleets?.length === 1){
            if (vehicleRef?.current){
             setFleet(vehicleRef?.current?.properties?.fleetId)
            } else {
                setFleet(fleets?.[0]?.fleetId)
            }
            handleShowPlaceModal(true);
        } else{
            setFleet()
            handleShowPlaceModal(true);
        }
    }



    const createGeoJSONCircle = (center, radius, points) => {
        if(!points) points = 64;
        var coords = {
            latitude: center[1],
            longitude: center[0]
        };

        var km = radius/1000;
        var ret = [];
        var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
        var distanceY = km/110.574;

        var theta, x, y;
        for(var i=0; i<points; i++) {
            theta = (i/points)*(2*Math.PI);
            x = distanceX*Math.cos(theta);
            y = distanceY*Math.sin(theta);

            ret.push([coords.longitude+x, coords.latitude+y]);
        }
        ret.push(ret[0]);

        return ret;
    };





    const acceptPois = async () => {
        const poi = await fetchPoisForMap(poisToShow.current);
        handlePoisToView(poisToShow.current)
        map.getSource('poi-data-icon')?.setData(poi);
    }




    const showTemplate2 = () => {
        confirmDialog({
            group: 'contextMenu',
            header: 'Select places of interest',
            message: <PoiSelect />,
            accept: acceptPois,
            acceptLabel: 'continue',
            rejectLabel: 'cancel'
        });
    };







    const toggleArrayItem = (array, item) => {
        return array.includes(item) ? array.filter(i => i !== item) : [...array, item];
    };

    const PoiSelect = () => {

        const [checked, setChecked] = useState([]);

        useEffect(() => {
            if (poisToShow?.current){
                setChecked(poisToShow.current)
            }
        }, [poisToShow?.current])

        const addChecked = (val) => {
            const fresh = toggleArrayItem(checked, val)
            setChecked(fresh);
            poisToShow.current = fresh
        }

        const showAll = () => {
            const all = catOptions.reduce((accumulator, currentValue) => accumulator.concat([currentValue.display]) , []);
            poisToShow.current = all;
            setChecked(all)
        }

        const hideAll = () => {
            setChecked([]);
            poisToShow.current = [];
        }


        return (
            <div style={{width: winWidth > 800 ? '20vw' : '50vw'}}>
                <ul style={{listStyle: 'none', textAlign: 'left', paddingLeft: '0px', width: '100%'}}>
                    {catOptions?.map(cat => (
                        <li key={cat?.display}
                            style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>

                            <div>
                                <FontAwesomeIcon icon={cat?.icon}/>&nbsp;{cat?.display}
                            </div>


                            <div>
                                <Checkbox onChange={e => addChecked(cat?.display)}
                                          checked={checked?.includes(cat?.display)}></Checkbox>
                            </div>
                        </li>
                    ))}
                </ul>

                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <span onClick={() => handleShowPoiLabels(true)} style={{cursor: 'pointer'}}>Show labels</span>
                    &nbsp;|&nbsp;
                    <span onClick={() => handleShowPoiLabels(false)} style={{cursor: 'pointer'}}>Hide labels</span>
                </div>


                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <span onClick={showAll} style={{cursor: 'pointer'}}>Show all</span>
                    &nbsp;|&nbsp;
                    <span onClick={hideAll} style={{cursor: 'pointer'}}>Hide all</span>
                </div>
            </div>
        )
    }


    return (
        <div id="context-menu" className="custom-menu" style={{zIndex: 1000, position: 'absolute', display: 'none'}}>

            <Menu model={items} breakpoint="767px" style={{padding: '0px!important'}}/>


            <ConfirmDialog group="contextMenu"/>

            <PlaceModalParent showPlaceModal={showPlaceModal} placeSelected={{location: {coordinates: [clickedLngLat?.lng, clickedLngLat?.lat]}, radius: 200}} handleShowPlaceModal={handleShowPlaceModal}
                              handleRefreshPois={() => {}} fleetId={fleet} />
        </div>
    )
}

export default ContextMenu