import React, {useContext, useEffect, useState} from 'react';
import '../index.css';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {MflLogo} from "../svgs/logo-myfleetlive";

import {API_URL} from '../Constants.js'
import {Colours} from "../colours";


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock} from "@fortawesome/pro-light-svg-icons";

import useToken from './useToken.js';
import Fleetfocus from "../svgs/fleetfocus";
import loginPoster from '../svgs/loginPoster.png';
import {dropTable, initDB} from "../functions/db";
import {Message} from "primereact/message";
import appInfo from "../../package.json";



async function loginUser(credentials) {
    return fetch(API_URL + '/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

const getUser = async (token) => {
    return fetch(API_URL + '/f-user', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
        .then(data => data.json())
}




const Login = ({}) => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loginError, setLoginError] = useState(null);

    const { token, setToken } = useToken();

    const handleSubmit = async e => {

        e.preventDefault();

        const user = await loginUser({
            email,
            password
        });




        if (user?.status === 401){
            console.log('failed')
            setLoginError('Incorrect username/password');
        } else {


            const userObj = await getUser(user.access_token);
            user.result = userObj;






            if(typeof user.access_token !== 'undefined' ) {

                const blockedArr = ['RAM_CA', 'RAM_US', 'RAM', 'QTX']

                if (blockedArr.includes(user?.result?.mflGroup)){
                    setLoginError('Incorrect username/password');
                } else {
                    setToken(user);
                    window.location.replace('/');
                }

            } else {
                console.log('failed')
                setLoginError('Incorrect username/password');
            }
        }

        setTimeout(() => setLoginError(null), 3000)
    }



    useEffect(async () => {
        await dropTable('vehicles');
        await dropTable('eventTags');
        await dropTable('places');
    }, [])



    return (

        <div className="grid loginContainer" style={{background: Colours.loginContainer}}>



            <div className="col-12 md:col-7 lg:col-6 xl:col-6"
                 style={{background: 'var(--surface-ground)', color: 'var(--text-color)', display: 'flex', flexDirection: 'column'}}>

                <div style={{flex:1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <form onSubmit={handleSubmit}  style={{ display: 'block',textAlign: 'center' }}>
                            <div style={{marginBottom: '30px', textAlign: 'center'}}>
                                <MflLogo size='275px' textColor={'var(--text-color)'} iconColor={'var(--main-accent-colour)'}/>
                            </div>

                            <div style={{width: '300px', margin: '0 auto', textAlign: 'left'}}>

                                <FontAwesomeIcon icon={faEnvelope} style={{fontSize: '1.4rem'}} />
                                &nbsp;&nbsp;
                                <InputText style={{width: '20rem'}} onChange={(e) => setEmail(e.target.value)}
                                           placeholder="Email"  type="text"/>

                                <br/><br/>



                                <FontAwesomeIcon icon={faLock} style={{fontSize: '1.4rem'}} />
                                &nbsp;&nbsp;

                                <Password value={password} onChange={(e) => setPassword(e.target.value)} toggleMask
                                          feedback={false} placeholder="Password" className="loginPassword"/>

                                <button style={{width: '260px', marginLeft:'22px', textAlign: 'center', marginTop: '30px', background: 'var(--v-list-button-selected)',
                                    color: 'var(--v-list-button-text-selected)'}} className="p-button" type="submit">
                                    <span className="p-button-label">Login</span>
                                </button>
                            </div>

                            <br/><br/>

                            {loginError && <Message severity={'error'} text={loginError} style={{width: '260px'}}/> }
                        </form>
                </div>

                <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '150px', height: '65px', paddingLeft: '20px'}}>
                        <Fleetfocus  />
                    </div>
                    <div style={{textAlign: 'right', paddingRight: '20px'}}>
                        &#169; {new Date().getFullYear()} Fleet Focus<br/>
                        myfleetlive.ai v{appInfo?.version}
                    </div>
                </div>


                <div></div>



            </div>
            <div className="hidden-sm-down md:col-5 lg:col-6 xl:col-6 loginPosterContainer"
                 style={{background: `center / cover no-repeat url(${loginPoster})`}}>

            </div>
        </div>
    )
}

export default Login;





// https://jooinn.com/img/get
