import {getStoreData} from "../../../../../functions/db";


export const fetchPoisForMap = async (cats) => {
    let pois = await getStoreData('places');
    let filtered = pois.filter(place => cats.includes(place?.cat));

    let data = []

    filtered.forEach(poi => {
        const obj = {
            type: 'Feature',
            geometry: poi.location,
            properties: {
                name: poi.name,
                cat: poi.cat,
                radius: poi.radius
            },
        };
        data.push(obj)
    });

    return {
        type: 'FeatureCollection',
        features: data
    }

}