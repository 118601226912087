import React, {useContext, useEffect, useRef, useState} from 'react';
import fetchStaticMapImage from "../../../../api/media/fetchStaticMapImage";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";
import EventGridItemSkeleton from "../grid/events/eventGridItemSkeleton";
import indexContext from "../../../contexts/indexContext";
import {faEdit, faTrash} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "primereact/tooltip";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import {catOptions, getIconForPlace} from "./catOptions";

import VehicleLiveLocationV2 from "../../vehicleList/vehicleLiveLocationV2";
import fetchStaticMapPoiImage from "../../../../api/media/fetchStaticMapPoiImage";
import {findDataByUniqueKey} from "../../../../functions/db";


const PlacesGridCard = ({place, openShowPlaceModal, handleDeletePoi}) => {

    const {darkMap, fleets} = useContext(indexContext);
    const [img, setImg] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [fleet, setFleet] = useState();

    const confirmDeletePoi = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to delete this POI?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // call parent function to delete poi and remove element
                handleDeletePoi(place)
            },
            reject: () => {
                return
            }
        });
    };



    useEffect(async () => {
        if (place){

            const imgRaw = await fetchStaticMapPoiImage(place, darkMap)
            setImg(URL.createObjectURL(imgRaw));

            const fleetRaw = fleets?.filter(f => f?.fleetId === place?.fleetId);
            if (fleetRaw?.length > 0) setFleet(fleetRaw?.[0]?.fleetName);

            setTimeout(() => {setIsLoaded(true)}, 500)
        }
    }, [darkMap])


    return (
        <React.Fragment>
            <ConfirmPopup />

            {isLoaded ?
                <div style={{padding: '10px 0px 5px 0px',borderRadius: 'inherit',width: '100%',height: '100%',cursor: 'pointer'}}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '0px 10px 0px 10px'
                    }}>


                        {place?.cat &&
                            <div>
                                <FontAwesomeIcon icon={getIconForPlace(place?.cat?.toLowerCase()?.split(' ')?.[0])} style={{fontSize: '20px'}}/>
                            </div>
                        }

                        <div style={{flex: 1, maxWidth: '90%',fontSize: '15px', fontWeight: 'bold', overflow:'hidden', whiteSpace:'nowrap',
                            textOverflow:'ellipsis'}}>

                            <span>{place?.name}</span>

                            <br/>
                            <div style={{
                                textAlign: 'left', position: 'relative', margin: '0 auto', borderRadius: '2px',
                                fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'
                            }}>
                             <i>{place?.cat}</i>

                            </div>
                        </div>

                        <div>
                            <Tooltip target={".delete" + place?._id} id={'delete' + place?._id}>Delete</Tooltip>

                            <span className={"delete" + place._id} data-pr-position="bottom"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      confirmDeletePoi(e);
                                  }}>

                                     <FontAwesomeIcon icon={faTrash} style={{
                                         cursor: 'pointer',
                                         fontSize: "18px",
                                         marginRight: '5px'
                                     }}/>

                                </span>
                        </div>
                    </div>

                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}}>

                        <img src={img} style={{width: '100%', borderRadius: '3px', aspectRatio: '16/9'}}
                             onClick={(e) => {
                                 e.stopPropagation();
                                 openShowPlaceModal(place);
                             }}/>
                        <div style={{
                            background: 'var(--surface-card)',
                            width: '100%',
                            height: '8.5%',
                            marginTop: '-5.5%',
                            position: 'absolute'
                        }}/>
                    </div>


                    <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px', padding: '0px 10px 0px 10px'}}>
                        <VehicleLiveLocationV2
                            d={{lat: place?.location?.coordinates?.[1], lng: place?.location?.coordinates?.[0]}}
                            icon={false}/>
                    </div>


                    <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px', padding: '2px 10px 10px 10px'}}>
                        {friendlyFleetName(place?.fleetIds?.[0], fleets)}

                        {place?.fleetIds?.length > 1 &&
                            <React.Fragment>
                                <div style={{display: 'inline-block', marginLeft: '5px', height: '17px', width: '17px', background: 'var(--surface-border)', borderRadius: '50%'}}
                                     className={"_moreFleets"+place?._id} data-pr-position="bottom">
                                    +{place?.fleetIds?.length - 1}
                                </div>
                                <Tooltip target={"._moreFleets"+place?._id}>
                                    {place?.fleetIds.map((fleet,i) => (
                                        <React.Fragment>
                                            {i > 0 && <div>{friendlyFleetName(fleet, fleets)}</div>}
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </React.Fragment>
                        }


                    </div>


                </div>
                :
                <EventGridItemSkeleton/>
            }

        </React.Fragment>
    )
}
export default PlacesGridCard
