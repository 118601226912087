import React, {useContext, useRef, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import {ConfirmPopup} from "primereact/confirmpopup";
import {Dropdown} from "primereact/dropdown";
import postRequestRoadSpeedChange from "../../../../../api/events/postRequestRoadSpeedChange";
import {TOAST_DURATION} from "../../../../../Constants";
import {Toast} from "primereact/toast";

const UpdateRoadSpeed = ({speedLimit}) => {

    const {event, toast, closeModal} = useContext(mainContext);
    const [visible, setVisible] = useState(false);
    const [newLimit, setNewLimit] = useState();

    const saveSpeedLimit = async () => {
        const data = {
            event_id: event?._id,
            modifiedSpeed: newLimit
        }
        await postRequestRoadSpeedChange(data);
        toast?.current?.show({ severity: 'success', summary: 'All done', detail: 'Speed limit change request sent for review',
            life: TOAST_DURATION });


        const ele = document.getElementById(event._id);
        ele.style.display = 'none';
        closeModal('displayEventClickModal')

    }



    const popupBody = () => {
        return (
            <div>
                The speed limit for the road is currently <br/>
                {speedLimit} mph. If this is incorrect you can<br />
                request for this to be changed using the <br/>
                dropdown below.
                <br/><br/>
                <div>
                    <div className="p-inputgroup">
                        <div className="p-inputgroup-addon" style={{padding: '0px 2px'}}>Correct speed:</div>
                        <Dropdown value={newLimit} onChange={(e) => setNewLimit(e.value)}
                                  options={[30, 40, 50, 60, 70]} placeholder="Road speed" style={{textAlign: 'right'}}/>
                        <div className="p-inputgroup-addon" style={{padding: '0px'}}>mph</div>
                    </div>
                </div>


            </div>
        )
    }


    return (
        <React.Fragment>
            <div style={{fontStyle: 'italic'}}>
                Speeding event lasting {event?.alarmData?.alarmJson?.speedingData?.duration} seconds
                at up to {event?.alarmData?.alarmJson?.speedingData?.maxPercentOver?.toFixed(0)}% over the speed
                limit.
                (*BETA feature, road speed limit data may not be accurate,&nbsp;
                <span style={{color: 'blue', textDecoration: 'underline'}}
                      id={'speed_' + event?._id}
                      onClick={(e) => {
                          e.stopPropagation()
                          setVisible(true)
                      }}>
                click here
            </span>
                &nbsp;to report incorrect speed limit)
            </div>

            <ConfirmPopup target={document.getElementById('speed_' + event?._id)} visible={visible}
                          onHide={() => setVisible(false)}
                // icon={<FontAwesomeIcon icon={faLocationPlus} style={{fontSize: "18px"}}/>}
                          message={popupBody}
                          acceptLabel={"Save Changes"} rejectLabel={"Cancel"}
                          accept={saveSpeedLimit}
                          rejectClassName="p-button-danger"
                          acceptClassName="p-button-success"
            />

        </React.Fragment>
    )
}
export default UpdateRoadSpeed