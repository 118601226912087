import React, {useContext, useEffect, useState} from 'react'
import postVehiclePoi from "../../../../../api/places/postVehiclePoi";
import reportsContext from "../../../../contexts/reportsContext";
import {findDataByUniqueKey} from "../../../../../functions/db";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {
    formatDateOnly,
    formatDateTimesheetReport,
    formatTimeOnly, fullDurationFromSeconds
} from "../../../../../functions/formatting/formatDate";
import {fetchPoiDataForDate} from "./fetchPoiDataForDate";


const PoiReportRow = ({data}) => {


    const {theFleet, dateRange} = useContext(reportsContext);

    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState(false);
    const [drillDownData, setDrillDownData] = useState();



    useEffect(async () => {
        if (data){
            setLoading(true)
            setDrillDownData(await fetchPoiDataForDate(data, theFleet, dateRange));
            setLoading(false)
        }
    }, [data]);


    const RowExpansionTemplateCaller = (data) => {
        return <PoiVisits data={data} />
    };


    const statusTemplate = (rowData) => {
        return fullDurationFromSeconds(rowData.totalSeconds)
    };
    const statusTemplate2 = (rowData) => {
        return fullDurationFromSeconds(rowData.stopTime)
    };


    const PoiVisits = ({data}) => {

        return (
            <DataTable value={data?.poi} scrollable >
                <Column field="date" header="Date"></Column>
                <Column field="start" header="Time in"></Column>
                <Column field="end" header="Time out"></Column>
                <Column body={statusTemplate2} header="Duration"></Column>
                <Column field="acc" header="Acc"></Column>
            </DataTable>
        )
    }



    return (
        <div className="p-3">
            {loading ?
                <div>loading</div>
                :
                <DataTable value={drillDownData}
                           expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                           dataKey="dn" rowExpansionTemplate={RowExpansionTemplateCaller}>
                    <Column expander />
                    <Column field="dn" header="Registration"></Column>
                    <Column field="visits" header="Total visits"></Column>
                    <Column body={statusTemplate} header="Total Time"></Column>
                </DataTable>
            }

        </div>
    );

}


export default PoiReportRow