import postVehiclePoi from "../../../../../api/places/postVehiclePoi";
import {formatDateOnly, formatTimeOnly, getSecondsDifference} from "../../../../../functions/formatting/formatDate";

export const fetchPoiDataForDate = async (poi, theFleet, dateRange) => {

    const data2 = {
        poiId: poi.poiId,
        fleetId: theFleet?.fleetId,
        startTime: [
            dateRange[0].getFullYear(),
            ('0' + (dateRange[0].getMonth() + 1)).slice(-2),
            ('0' + dateRange[0].getDate()).slice(-2)
        ].join('-') + 'T00:00:00+00:00',
        endTime: [
            dateRange[1].getFullYear(),
            ('0' + (dateRange[1].getMonth() + 1)).slice(-2),
            ('0' + dateRange[1].getDate()).slice(-2)
        ].join('-')  + 'T23:59:00+00:00'
    }

    const drill = await postVehiclePoi(data2);

    const dataObj = [];

    drill?.journeys?.forEach(j => {

        j.totalSeconds = 0;

        j.poi.forEach(p => {
            p.acc = 0;
            p.date = formatDateOnly(p?.startTime)
            p.start = formatTimeOnly(p?.startTime)
            p.end = formatTimeOnly(p?.endTime)
            p.stopTime = getSecondsDifference(p?.startTime, p?.endTime)
            j.totalSeconds += getSecondsDifference(p?.startTime, p?.endTime);
        });


        const index = dataObj.findIndex(item => item.dn === j.dn);

        if (index > -1){
            j.visits += dataObj[index].poi.length;
            dataObj[index].poi  = dataObj[index].poi.concat(j.poi)
        } else {
            j.visits = j?.poi?.length;
            dataObj.push(j)
        }
    });


    drill?.stops?.forEach(s => {
        let duration = 0;
        s.poi.forEach(p => {
            p.acc = 1;
            p.date = formatDateOnly(p?.startTime)
            p.start = formatTimeOnly(p?.startTime)
            p.end = formatTimeOnly(p?.endTime)
            p.stopTime = getSecondsDifference(p?.startTime, p?.endTime)
            duration += getSecondsDifference(p?.startTime, p?.endTime);
        });



        const index = dataObj.findIndex(item => item.dn === s.dn);
        if (index > -1){
            s.visits += dataObj[index].poi.length;
            dataObj[index].poi  = dataObj[index].poi.concat(s.poi)
            dataObj[index].totalSeconds += duration || 0;
        } else {
            s.visits = s?.poi?.length;
            s.totalSeconds = duration;
            dataObj.push(s)
        }
    });


    return dataObj

}