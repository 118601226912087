import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIconForPlace} from "../../components/mainComponents/panelContent/places/catOptions";

const FormatAddress = ({data, type}) => {
    let add;
    

    if (type === 'start'){
        add = data?.startAddress;
    } else {
        add = data?.endAddress;
    }

    let str = '';

    let icon;

    if (add?.poi){
        str = add?.poi?.name;
        icon = add?.poi?.cat
    } else {
        add = add?.addressData;


        str += add?.road ? add?.road : '';

        if (add?.village){
            str += str?.length > 0 ? ', ' : '';
            str += add?.village;

        } else {
            if (add?.town) {
                str += str?.length > 0 ? ', ' : '';
                str += add?.town
            } else {
                str += str?.length > 0 ? ', ' : '';
                str += add?.city;
            }
        }

        if (add?.postcode){
            str += str?.length > 0 ? ', ' : '';
            str += add?.postcode
        }
    }




    return (
        <div>
            {icon &&
                <React.Fragment>
                    <FontAwesomeIcon icon={getIconForPlace(icon?.toLowerCase()?.split(' ')?.[0])}/>&nbsp;
                </React.Fragment>
            }


            {str}
        </div>
    )
}

export default FormatAddress
