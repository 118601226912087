import {API_URL} from '../../Constants.js'



const fetchStaticMapPoiImage = (place, darkMap) => {

    const mapStyle = darkMap ? 'fleetfocus/cl74ogqly001b14n15u5x24zn'
        : 'fleetfocus/clfbkl32z000h01qln1hmq2b2';



    //varible zoom based on radius

    const zoom = (radius) => {
        return 13
    }

    const base_url = `https://api.mapbox.com/styles/v1/${mapStyle}/static`
    const dimensions = "500x300"
    const access_token = "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw"

    const createGeoJSONCircle = (center, radius, points) => {
        if(!points) points = 20;
        var coords = {
            latitude: center[1],
            longitude: center[0]
        };

        var km = radius/1000;
        var ret = [];
        var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
        var distanceY = km/110.574;

        var theta, x, y;
        for(var i=0; i<points; i++) {
            theta = (i/points)*(2*Math.PI);
            x = distanceX*Math.cos(theta);
            y = distanceY*Math.sin(theta);

            ret.push([coords.longitude+x, coords.latitude+y]);
        }
        ret.push(ret[0]);


        let obj = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [ret]

                    },
                    "properties": {
                        "stroke": getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
                        "stroke-width": 2,
                        "fill": getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
                        "fill-opacity": 0.6
                    }
                }
            ]
        }
        return encodeURIComponent(JSON.stringify(obj));
    };


    // let url = `https://api.mapbox.com/styles/v1/${mapStyle}/static/url-https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fen%2Fa%2Faa%2FBart_Simpson_200px.png(${lng},${lat})/${lng},${lat},`;
    // url +=`8/352x240?access_token=pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw`;


    return fetch(`${base_url}/geojson(${createGeoJSONCircle(place?.location?.coordinates, place?.radius)})/${place?.location?.coordinates?.[0]}, ${place?.location?.coordinates?.[1]},${zoom(place?.radius)}/500x300?access_token=${access_token}`)
        .then((response) => response.blob())
        .then((data) => {
                // console.log(data);
                return Promise.resolve(
                    data
                );
            }
        ).catch(err => {
            // console.log(err)
        });

}


export default fetchStaticMapPoiImage;

// https://myfleetlive.org:8000/angFetchVidThumb/1000040_20210927112234-20210927112254_1_20

// https://myfleetlive.org:8000/view-snapshot/1000040_20211105084345_1_sm


// https://myfleetlive.org:8000/view-video/1000234_20211028110052-20211028110110_1_20_h265
