import React, {useContext, useEffect, useState} from 'react';
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
import gridContext from "../../../../contexts/gridContext";
import {findDataByUniqueKey, getStoreData} from "../../../../../functions/db";
import mainContext from "../../../../contexts/mainContext";
import VehicleComparator from "./vehicleComparator";
import DriverComparator from "./driverComparator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple} from "@fortawesome/pro-regular-svg-icons";
import usePersistState from "../../../../../functions/localStorage/persistState";


const EventParamChips = ({}) => {

const {eventParams, removeParam, handleEventParams, tagLib, comparatorData, handleDriver} = useContext(gridContext);
const {disableDateChange, vehicle} = useContext(mainContext)



    const [showComparator, setShowComparator] = usePersistState(false, 'showComparator')
    const [showComparator2, setShowComparator2] = useState(false)


    const handleShowComparator2 = val => setShowComparator2(val)

    const handleShowComparator = (v) => setShowComparator(v)

    const compartorLabel = () => {
        return <div><FontAwesomeIcon icon={faChartSimple} style={{marginRight: '5px', fontSize: '16px'}}/>&nbsp;Vehicle insight chart</div>
    }

    const compartorLabel2 = () => {
        return <div><FontAwesomeIcon icon={faChartSimple} style={{marginRight: '5px', fontSize: '16px'}}/>&nbsp;Driver insight chart</div>
    }



    return (
        <div>

            {eventParams?.length > 0 &&
                <div style={{marginTop: '100px', padding: '10px 10px', height: '50px'}}>

                    {(eventParams?.length > 0 && !vehicle) &&
                        <Button label={compartorLabel()} disabled={!comparatorData}
                                onClick={() => {
                                    handleDriver(false)
                                    setShowComparator(!showComparator)
                                    setShowComparator2(false)
                                }}
                                style={{
                                    cursor: 'pointer',
                                    height: '28px',
                                    padding: '0px 10px',
                                    borderRadius: '2px',
                                    color: showComparator ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                                    margin: '3px',
                                    background: showComparator ? 'var(--v-list-button-selected)' : 'var(--v-list-button)'
                                }} className={'activeChips'}/>

                    }

                    {(eventParams?.length > 0 && !vehicle) &&
                        <Button label={compartorLabel2()} disabled={!comparatorData}
                                onClick={() => {
                                    setShowComparator2(!showComparator2)
                                    setShowComparator(false)
                                }}
                                style={{
                                    cursor: 'pointer',
                                    height: '28px',
                                    padding: '0px 10px',
                                    borderRadius: '2px',
                                    color: showComparator2 ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                                    margin: '3px',
                                    background: showComparator2 ? 'var(--v-list-button-selected)' : 'var(--v-list-button)'
                                }} className={'activeChips'}/>

                    }

                    {eventParams?.map(param => (
                        <Chip key={param} label={tagLib?.[param]?.displayName} className="activeChips p-chip" removable
                              onRemove={() => removeParam(param)} disabled={disableDateChange}/>
                    ))}

                    {eventParams?.length > 0 &&
                        <Button label={'Reset'} disabled={disableDateChange}
                                onClick={() => {
                                    handleEventParams([]);
                                    handleDriver(null)
                                }}
                                style={{
                                    cursor: 'pointer',
                                    height: '28px',
                                    padding: '0px 10px',
                                    borderRadius: '2px', color: 'var(--v-list-button-text)',
                                    margin: '3px', background: 'var(--v-list-button)'
                                }} className={'activeChips'}/>


                    }
                </div>

            }

            {showComparator && eventParams?.length > 0 && !vehicle &&
                <VehicleComparator handleShowComparator={handleShowComparator}/>
            }
            {showComparator2 && eventParams?.length > 0 && !vehicle &&
                <DriverComparator handleShowComparator2={handleShowComparator2}/>
            }

        </div>
    )

}

export default EventParamChips