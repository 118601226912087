import React, {useContext, useEffect, useRef, useState} from 'react'
import {fetchActivityData} from "./fetchActivityData";
import {
    DurationFromSeconds,
    formatDateTimesheetReport, fullDurationFromSeconds,
    getSecondsDifference, timeFromSeconds
} from "../../../../../functions/formatting/formatDate";
import LoadingSpinner from "../../../loadingSpinner";
import reportsContext from "../../../../contexts/reportsContext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {activityReportPdf} from "./activityReportPdf";



const ActivityReport = ({}) => {

    const {dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi, theVehicle,
        generateReport, handleGenerateReport} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const dt = useRef(null);


    const accumulator = (data, prop) => {
        return data.reduce((totalValue, item) => {
            switch (prop) {
                case 'startTime':
                    const st = new Date(item?.start?.at);
                    const stMins = (st.getHours() * 60) + st.getMinutes();
                    return  totalValue +=  stMins;
                case 'endTime':
                    const et = new Date(item?.end?.at);
                    const etMins = (et.getHours() * 60) + et.getMinutes();
                    return  totalValue +=  etMins;


                case 'duration':
                    return totalValue += item?.duration
                default : return totalValue + item?.[prop];
            }
        }, 0);
    }

    const generateReportData = () => {
        fetchActivityData(dateRange, theVehicle).then(r => {
            setActivityData(r);

            const total = {
                distance : accumulator(r, 'distance'),
                driving: accumulator(r, 'duration'),
                stopped: accumulator(r, 'stopTime'),
                startTime : accumulator(r, 'startTime') * 60,
                endTime : accumulator(r, 'endTime') * 60
            }

            setActTotals(total);

            const cache = {
                actData: r,
                totals: total,
                dn: theVehicle?.properties.dn,
                dateRange: JSON.stringify(dateRange)
            }

            localStorage.setItem('activityReport', JSON.stringify(cache));
            handleReportLoading(false);
        });
    }

    useEffect(async () => {

        if (localStorage.getItem('activityReport')){
            handleReportLoading(false);

            const cache = JSON.parse(localStorage.getItem('activityReport'));

            if (cache.dateRange === JSON.stringify(dateRange) && cache.dn === theVehicle?.properties?.dn){
                setActivityData(cache.actData);
                setActTotals(cache.totals);
            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && theVehicle && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }


    }, [dateRange, theVehicle, generateReport]);

    const exportCSV = (selectionOnly) => {
        // dt.current.exportCSV({ selectionOnly });
        const exportData = activityData.map((row) => ({
            date: row?.date,
            journey: row?.journeyNo,
            startTime: row.startTime,
            startPlace: row?.startPlace,
            endTime: row?.endTime,
            endPlace: row?.endPlace,
            distance: row?.distanceMiles,
            duration: row?.duration,
            stopped: row?.stopTimeFull
        }));

        // Convert JSON to CSV string
        const csvRows = [];
        const headers = Object.keys(exportData[0]);
        csvRows.push(headers.join(","));

        exportData.forEach((row) => {
            const values = headers.map((header) => {
                const val = row[header] !== null && row[header] !== undefined ? row[header] : "";
                return `"${val}"`;
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Activity Report ${theVehicle?.properties?.registration} ${activityData?.[0].date}-${activityData?.[activityData?.length-1].date}.csv`;
        link.click();
        window.URL.revokeObjectURL(url);

    };

    const exportPdf = () => {
        activityReportPdf(activityData, theVehicle);
    };

    const totalDist = (date) => {
        let total = 0;
        activityData?.forEach(act => {
            if (date === act.date) total += act?.distance
        })
        return total.toFixed(0) + ' miles'

    }

    const totalDur = (date) => {
        let total = 0;
        activityData?.forEach(act => {
            if (date === act.date) total += getSecondsDifference(act.start.at, act.finish.at)
        })
        return total
    }


    const totalStopped = (date) => {
        let total = 0;
        activityData?.forEach(act => {
            if (date === act.date) total += act?.stopTime;
        })
        return total
    }



    const header = (
        <div style={{
            color: 'var(--text-color)',
            fontSize: '1.17em',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{paddingLeft: '5px'}}>
                Activity report for {theVehicle?.properties?.registration}<br/>
                {formatDateTimesheetReport(dateRange?.[0])} - {formatDateTimesheetReport(dateRange?.[1])}
            </div>
            <div>
            <div><b>Total journeys:</b> {activityData?.length}</div>
            </div>
            <div>
                <div><b>Total distance:</b> {actTotals?.distance?.toFixed(0)} mi</div>
                <div></div>
            </div>
            <div>
                <div><b>Total driving:</b> {DurationFromSeconds(actTotals?.driving)}</div>
            </div>

            <div>
                <div><b>Total standstill:</b> {DurationFromSeconds(actTotals?.stopped)}</div>
            </div>
            <div style={{display:'flex', gap:'5px'}}>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                {/*<Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />*/}

                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>

        </div>
    );


    const headerTemplate = (data) => {
        return (
            <div className="flex align-items-center gap-2 justify-content-start" style={{paddingLeft:'5px'}}>
                <h3>{formatDateTimesheetReport(data.start.at)}</h3>
            </div>
        );
    };

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan="5" style={{textAlign: 'right', fontWeight: 'bold'}}>
                    Totals
                </td>
                <td style={{fontWeight: 'bold', textAlign:'right'}}>{totalDist(data.date)}</td>
                <td style={{fontWeight: 'bold', textAlign:'right'}}>{fullDurationFromSeconds(totalDur(data.date))}</td>
                <td style={{fontWeight: 'bold', textAlign:'right'}}>{fullDurationFromSeconds(totalStopped(data.date))}</td>

            </React.Fragment>
        );
    };


    return (
        <React.Fragment>
            {generateReport &&
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                    :
                        <React.Fragment>
                            <DataTable ref={dt} value={activityData} header={header} scrollable scrollHeight='calc(100vh - 220px)'
                                       rowGroupMode="subheader" groupRowsBy="date" rowGroupHeaderTemplate={headerTemplate}
                                       rowGroupFooterTemplate={footerTemplate} stripedRows>
                                <Column field="journeyNo" header="Journey" style={{paddingLeft:'18px'}} align={'left'}></Column>
                                <Column field="startTime" header="Start"></Column>
                                <Column field="startPlace" header="Start place"></Column>
                                <Column field="endTime" header="End"></Column>
                                <Column field="endPlace" header="End place"></Column>
                                <Column field="distanceMiles" header="Distance"  align={"right"}></Column>
                                <Column field="durationFull" header="Duration"  align={"right"}></Column>
                                <Column field="stopTimeFull" header="Standstill" align={"right"} style={{paddingRight:'20px'}}></Column>
                            </DataTable>
                        </React.Fragment>
                    }
                </React.Fragment>

            }
        </React.Fragment>
    )
}
export default ActivityReport

