import React, {useContext, useEffect, useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpWideShort, faEllipsisVertical} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Chip} from "primereact/chip";
import {friendlyFleetName} from "../../../functions/formatting/friendlyFleetName";
import {faCircleXmark} from "@fortawesome/pro-light-svg-icons";
import mainContext from "../../contexts/mainContext";
import indexContext from "../../contexts/indexContext";
import {Tooltip} from "primereact/tooltip";
import {Chart} from "primereact/chart";
import {findDataByUniqueKey, getStoreData} from "../../../functions/db";
import DrivingStatusButtons from "./drivingStatusButtons";

const VehicleListFilters = ({addChip, orderVehicleList, handleRemoveChip, showFilters, vListOrder,vListTypeOrder,
                                handleShowFilters, handleVListOrder, handleVListTypeOrder, }) => {

    const {vehicleFilterParams, handleVehicleFilterParams, vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext);

    const [inputVal, setInputVal] = useState('');



    const sortOptions = ['Registration', 'Last update', 'Health']
    let a = new Set(['driving', 'parked', 'idling']);




    const dStyle = {
        padding: '0.3rem 0.3rem',
        margin: '3px',
        background: 'var(--v-list-button-selected)',
        color: "var(--v-list-button-text-selected)",
        flex:1,
        border: 'none'
    }

    const dStyle2 = {
        padding: '0.3rem 0.3rem',
        margin: '3px',
        background: "var(--v-list-button)",
        color: "var(--v-list-button-text)",
        flex:1,
        border: 'none'
    }



    const handleKeyPressFilter = async (e) => {
        if(e.key === 'Enter' && e.target.value?.trim()?.length >1){
            const params = {
                drivingStatus: vehicleFilterParams?.drivingStatus,
                search: "",
                chips: new Set([...vehicleFilterParams?.chips, e?.target?.value]),
                fleetsToShow: vehicleFilterParams?.fleetsToShow
            }
            handleVehicleFilterParams(params);
            setInputVal("");
            clearTimeout(typingTimer?.current);
        }
    }



    const addFleetToShow = (v) => {
        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: new Set([v.value.fleetId])
        }
        handleVehicleFilterParams(params);
    }

    const removeFleetToShow = (v) => {
        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: new Set([])
        }
        handleVehicleFilterParams(params);
    }






    const typingTimer = useRef();
    const doneTyping = (v) => {

        if (v?.trim()?.length > 1 || !v){
            const params = {
                drivingStatus: vehicleFilterParams?.drivingStatus,
                search: v,
                chips: vehicleFilterParams?.chips,
                fleetsToShow: vehicleFilterParams?.fleetsToShow
            }
            handleVehicleFilterParams(params)
        }

    }

    const testButton2 = async (val) => {
        clearTimeout(typingTimer?.current);
        typingTimer.current = setTimeout(() => doneTyping(val), 500);

        setInputVal(val);
    }




    const FleetDropdownOption = (option) => {
        return (
            <div className="country-item">
                {option.country &&
                    <img alt={option.country} src="images/flag/flag_placeholder.png"  className={`flag flag-${option.country.toLowerCase()}`} />
                }
                <div>{option?.fleetName}</div>
            </div>
        );
    }





    return (
        <div>
            <div style={{width: '100%', display: 'flex', height: '45px', gap:"1px", paddingLeft:"1px", borderBottom: '1px solid var(--surface-border)'}}>
                <div style={{flex: 1, paddingTop: '3px'}}>

                    <InputText value={inputVal} placeholder="Type to filter..."
                               onChange={(e) => testButton2(e.target.value)}
                               onKeyPress={(e) => {
                                   handleKeyPressFilter(e)
                               }}
                               style={{
                                   height: '100%', width: '100%', borderRadius: '2px', fontSize: '16px', background: 'transparent',
                                   border:'1px solid transparent'
                               }}
                               className="vListFilterInput"/>
                </div>
                <div style={{
                    color: "var(--v-list-button-text)",
                    background: "var(--v-list-button)",
                    cursor: 'pointer',
                    width: '45px',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    borderRadius: '0px 2px 2px 0px'
                }}
                     onClick={() => handleShowFilters(!showFilters)}>

                    {/*<FontAwesomeIcon icon={faFilterList} style={{fontSize: '22px'}}/>*/}
                    <FontAwesomeIcon icon={faEllipsisVertical} style={{fontSize: '22px'}}/>

                </div>
            </div>

            {showFilters &&
                <div style={{padding: '5px 5px 0px 5px'}}>

                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>

                        {fleets?.length > 1 &&
                            <Dropdown options={fleets} onChange={(e) => addFleetToShow(e)} optionLabel="fleetName" filter
                                      showClear
                                      filterBy="fleetName" placeholder="Fleet" itemTemplate={FleetDropdownOption}/>
                        }


                        <Dropdown options={sortOptions} value={vListTypeOrder} style={{flex: 0}}
                                  placeholder="Order" onChange={async (e) => {
                            await handleVListTypeOrder(e?.value);
                            await orderVehicleList(e?.value, vListOrder)
                        }}/>

                        <Button icon={<FontAwesomeIcon icon={faArrowDownWideShort}/>}
                                style={vListOrder === 'Descending' ? dStyle : dStyle2}
                                onClick={async () => {
                                    await orderVehicleList(vListTypeOrder, 'Descending');
                                    await handleVListOrder('Descending');

                                }}/>
                        <Button icon={<FontAwesomeIcon icon={faArrowUpWideShort}/>}
                                style={vListOrder === 'Ascending' ? dStyle : dStyle2}
                                onClick={async () => {

                                    await orderVehicleList(vListTypeOrder, 'Ascending');
                                    await handleVListOrder('Ascending');
                                }}/>

                    </div>

                    {/*<div style={{margin: '0 auto', display: 'flex', justifyContent: 'space-between', paddingBottom: '5px'}}>*/}
                    {/*    <Dropdown  options={sortOptions} value={vListTypeOrder}*/}
                    {/*               placeholder="Vehicle order" onChange={(e) => setVListTypeOrder(e?.value)}/>*/}
                    {/*    <Dropdown  options={['Ascending', 'Descending']} value={vListOrder}*/}
                    {/*               placeholder="Asc/Desc" onChange={(e) => setVListOrder(e?.value)}/>*/}
                    {/*</div>*/}


                    {/*<div style={{margin: '0 auto', textAlign: 'center'}}>*/}
                    {/*    <Dropdown  options={fleets} onChange={(e) => addFleetToShow(e)} optionLabel="fleetName" filter showClear*/}
                    {/*               filterBy="fleetName" placeholder="Select Fleet(s)" itemTemplate={FleetDropdownOption} style={{width: '98%'}}/>*/}
                    {/*</div>*/}
                </div>
            }


            <DrivingStatusButtons />




            <div style={(vehicleFilterParams?.chips?.size > 0 || vehicleFilterParams?.fleetsToShow?.size > 0) ? {height: '53px'} : {}}>
                <div>
                    {[...vehicleFilterParams?.chips].map((c, index) => (
                        <Chip key={c} label={c} className="activeChips" removable onRemove={() => handleRemoveChip(c)}/>
                    ))}
                </div>

                <div>
                    {[...vehicleFilterParams?.fleetsToShow].map((c, index) => (
                        // <Chip key={index} label={getFriendlyFleetName(c)} className="activeChips" removable onRemove={() => removeFleetToShow(c)}/>
                        // <Button key={index} style={dStyle} label={getFriendlyFleetName(c)} onClick={() => removeFleetToShow(c)} />
                        <button key={index} className="fleetFilterButton" onClick={() => removeFleetToShow(c)}>
                            {friendlyFleetName(c, fleets)}

                            &nbsp;<FontAwesomeIcon icon={faCircleXmark}/>
                        </button>
                    ))}
                </div>
            </div>


            {/*<button onClick={() => handleDisplayType(displayType === 'drivers' ? 'vehicles' : 'drivers')}>*/}
            {/*    driver/vehicle toggle*/}
            {/*</button>*/}
        </div>
    )
}

export default VehicleListFilters